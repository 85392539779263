import React from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import trashIcon from 'src/assets/trash.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  btnText?: string
  description?: string
  onRemove: () => void
}

export default function ButtonRemoveWithPrompt({ btnText, onRemove, description }: Props) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'delete_with_prompt' })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleConfirm = () => {
    setOpen(false)
    onRemove()
  }

  const handleCancel = () => {
    setOpen(false)
  }

  return (
    <div>
      <Grid
        onClick={handleClickOpen}
        container
        alignItems='center'
        justifyContent='center'
        sx={{
          cursor: 'pointer'
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Share',
            fontWeight: 400,
            fontSize: '16px',
            color: '#EA4235'
          }}
          mr={2}
        >
          {btnText}
        </Typography>
        <img alt='Trash icon' src={trashIcon} />
      </Grid>
      <Dialog
        open={open}
        onClose={handleCancel}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t('prompt_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>{t('cancel')}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
