import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import React, { useState, FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { enabledLocales, setUserLocaleOverride } from '../locales/languages'
import { SxProps, Theme } from '@mui/material'

interface LanguageSelectionProps {
  // className: string,
  sx?: SxProps<Theme>
}
/**
 * This component allows the user to change the site's language.
 * The current languages available are english and french (default is english).
 */
const LanguageSelection: FC<LanguageSelectionProps> = props => {
  const [t, i18n] = useTranslation()
  const [language, setLanguage] = useState(i18n.language)
  const localeList = enabledLocales()

  const [width, setWidth] = useState<number>(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  const handleChange = (event: SelectChangeEvent<any>) => {
    setLanguage(event.target.value)
    setUserLocaleOverride(event.target.value)
    i18n.changeLanguage(event.target.value)
  }

  return (
    <Select
      labelId='language-select-label'
      id='language-select'
      defaultValue={i18n.language}
      value={i18n.language}
      sx={{
        height: '40px',
        ...props.sx
      }}
      onChange={handleChange}
    >
      {localeList.map((item: any) => (
        <MenuItem value={item.value} key={item.value}>
          {isMobile ? item.value.toUpperCase() : item.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export default LanguageSelection
