import { State } from 'src/models'

export const list = (state: State, locationId: string | undefined) =>
  Object.values(locationId ? state.locationAdmins.list[locationId] || {} : {})
export const drafts = (state: State) => Object.values(state.locationAdmins.draft)

export const isRemoving = (state: State) => state.locationAdmins.isRemoving
export const isFetching = (state: State) => state.locationAdmins.isFetching
export const isCreating = (state: State) => state.locationAdmins.isCreating
export const error = (state: State) => state.locationAdmins.error
