import { State } from 'src/models'

export const isFetching = (state: State) => state.adminPreview.isFetching
export const location = (state: State) => state.adminPreview.location
export const promotion = (state: State) => state.adminPreview.promotion
export const providerInfo = (state: State) => state.adminPreview.providerInfo
export const providerInfosList = (state: State) => state.adminPreview.providerInfosList
export const error = (state: State) => state.adminPreview.error
export const providerInfosListFilter = (state: State) => state.adminPreview.providerInfosListFilter
export const providerStats = (state: State) => state.adminPreview.providerStats
export const providerStatsFilters = (state: State) => state.adminPreview.providerStatsFilter
