import React, { useState, useCallback } from 'react'
import type { FC } from 'react'
import clsx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { Box, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import type { Theme } from 'src/theme'
import uploadImg from 'src/assets/upload.svg'
import ImageCropModal from './ImageCropModal'
import MediaList from './MediaList'
import { useDispatch } from 'react-redux'
import mediaUploads from 'src/store/mediaUploads'
import { UploadType } from 'src/models/uploads'
import { MediaUpload } from 'src/store/mediaUploads/types'

interface FilesDropzoneProps {
  uploadType: UploadType
  className?: string
  handleUpload?: (files: File[]) => void
  showDropzone?: boolean
  showTitle?: boolean
  isSubmitting?: boolean
  progress?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  dropZone: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  dragActive: {
    backgroundColor: theme.palette.action.active,
    opacity: 0.5
  },
  image: {
    width: 36,
    paddingBottom: theme.spacing(3)
  },
  text: {
    textAlign: 'center'
  },
  info: {
    marginTop: theme.spacing(1)
  },
  zone: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      opacity: 0.5,
      cursor: 'pointer'
    },
    height: '180px',
    width: '180px',
    border: `2px dashed ${theme.palette.divider}`,
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  btn: {
    alignSelf: 'center',
    marginTop: '12px',
    borderRadius: '100px',
    paddingRight: '32px',
    paddingLeft: '32px'
  },
  list: {
    maxHeight: 320
  },
  actions: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  preview: {
    height: '150px',
    width: '150px',
    objectFit: 'cover',
    aspectRatio: '1/1',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '350px',
      height: 'auto'
    }
  },
  previewContainer: {
    justifyContent: 'center',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      width: '100%'
    }
  },
  files: {
    '& .MuiCardActionArea-root': {
      '& .MuiCardMedia-root': {
        height: '150px',
        width: '150px',
        objectFit: 'cover'
      }
    }
  }
}))

const FilesDropzone: FC<FilesDropzoneProps> = ({
  className,
  handleUpload,
  showDropzone,
  showTitle,
  isSubmitting,
  progress,
  uploadType,
  ...rest
}) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const [cropModalOpen, setCropModalOpen] = useState(false)
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })

  const onDrop = async (newAcceptedFiles: File[]) => {
    if (newAcceptedFiles && newAcceptedFiles.length > 0) {
      const newFiles: File[] = []
      const readFilePromiseFns: (() => Promise<string>)[] = []

      newAcceptedFiles.forEach(acceptedFile => {
        readFilePromiseFns.push(() => readFile(acceptedFile))
        newFiles.push(acceptedFile)
      })

      const data = newAcceptedFiles.map((item, idx) => ({
        uid: Date.now() + idx,
        order: idx + 1,
        file: item,
        uploadType,
        state: 'queued'
      })) as MediaUpload[]
      dispatch(mediaUploads.actions.addMediaUploads(data))
    }
  }

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    multiple: true,
    onDrop: onDrop,
    maxFiles: 10,
    accept: 'image/jpeg, image/png'
  })

  const handleCropModalClose = (): void => {
    setCropModalOpen(false)
  }

  const onCropModalSubmit = (file: File) => {
    // setFiles([file])
  }

  const readFile = (file: File) =>
    new Promise<string>(resolve => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result as string), false)
      reader.readAsDataURL(file)
    })

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div
        className={clsx({
          [classes.dropZone]: true,
          [classes.dragActive]: isDragActive
        })}
        {...getRootProps()}
      >
        <Box flexDirection={'column'} sx={{ width: { xs: '100%', md: 'auto' } }}>
          <Typography
            component='h6'
            align='left'
            color={'rgba(41, 56, 69, 0.7)'}
            sx={{
              fontWeight: 500,
              mb: 2
            }}
          >
            {t('add_image_title') + ' *'}
          </Typography>
        </Box>

        <input {...getInputProps()} multiple accept='image/*' />
        <div onClick={open} className={classes.zone}>
          <div>
            <img alt='Select file' className={classes.image} src={uploadImg} />
          </div>
          <div>
            <Box
              flexDirection='column'
              display={'flex'}
              alignItems='center'
              justifyContent='center'
            >
              <Typography className={classes.text} align={'center'} color='textSecondary'>
                {t('add_image_text')}
              </Typography>
              <Button className={classes.btn} variant='contained' sx={{ mt: 2, mb: 2 }}>
                {t('add_image_btn')}
              </Button>
            </Box>
          </div>
        </div>
        {progress ? (
          <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            <LinearProgress color='primary' value={progress} variant='determinate' />
          </div>
        ) : null}
      </div>

      <MediaList />
      {/*
      <ImageCropModal
        key={fileURL}
        image={fileURL}
        open={cropModalOpen}
        onClose={handleCropModalClose}
        onCancel={handleCropModalClose}
        onSave={onCropModalSubmit}
      />
        */}
    </div>
  )
}

export default FilesDropzone
