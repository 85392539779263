import React, { FC, ReactNode } from 'react'
import Typography from '@mui/material/Typography'

export const SectionTitle: FC<{ children: ReactNode }> = ({ children }) => (
  <Typography
    component='h6'
    align='left'
    color={'text.secondary'}
    sx={{
      fontWeight: 400,
      mb: 1
    }}
  >
    {children}
  </Typography>
)
