import clsx from 'clsx'
import { CircularProgress, Theme } from '@mui/material'
import Link from '@mui/material/Link'
import React, { FC } from 'react'
import { makeStyles } from '@mui/styles'
import { OTPMethods } from 'src/models/auth'

export type ResendOTP = (
  event: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement>,
  messenger: OTPMethods
) => void

interface ResendOTPLinkProps {
  resendOTPHandler: ResendOTP
  messenger: OTPMethods
  isSendLimitReached?: boolean | undefined
  isSendingPhoneVerification: boolean
  activeMessenger: OTPMethods | undefined
  linkText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  resendOTPLink: {
    cursor: 'pointer',
    color: theme.palette.text.secondary
  },
  resendOTPLinkLoading: {
    cursor: 'default',
    color: '#bebebe !important',
    '&:hover': {
      textDecoration: 'none !important'
    }
  }
}))

export const ResendOTPLink: FC<ResendOTPLinkProps> = ({
  resendOTPHandler,
  isSendLimitReached,
  isSendingPhoneVerification,
  activeMessenger,
  linkText,
  messenger
}) => {
  const classes = useStyles()

  return (
    <Link
      component='span'
      paragraph
      underline='hover'
      fontWeight='500'
      onClick={event => resendOTPHandler(event, messenger)}
      className={
        isSendLimitReached || isSendingPhoneVerification
          ? clsx(classes.resendOTPLink, classes.resendOTPLinkLoading)
          : classes.resendOTPLink
      }
    >
      {linkText}
      {isSendingPhoneVerification && activeMessenger === messenger && (
        <CircularProgress
          size={15}
          sx={{
            display: 'inline-block',
            ml: 1
          }}
        />
      )}
    </Link>
  )
}
