import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import authStore from 'src/store/auth'
import { Navigate, useLocation } from 'react-router-dom'

export const CheckProviderOrNavigateToCreate: React.FC<{ children: JSX.Element }> = ({
  children
}) => {
  const location = useLocation()
  const [currentPath, setCurrentPath] = useState<string>('')

  const providerAdmins = useSelector(authStore.selectors.providerAdmins)

  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  return !providerAdmins?.length && currentPath !== '/providers/create' ? (
    <Navigate to='/providers/create' />
  ) : (
    children
  )
}
