import actionCreatorFactory from 'typescript-fsa'
import { MediaUpload, MediaUploadList } from './types'
import { MediaFile, MediaUploads } from 'src/models/uploads'

// Actions
const actionCreator = actionCreatorFactory('mediaUploads')

export const addMediaUploads = actionCreator<MediaUpload[]>('ADD_MEDIA_UPLOADS')
export const setImages = actionCreator<MediaFile[]>('SET_IMAGES')

export const mediaUploadUpdate = actionCreator<
  (Partial<MediaUpload> & { uid: number }) | (Partial<MediaUpload> & { uid: number })[]
>('MEDIA_UPLOAD_UPDATE')

export const changeImagesOrder = actionCreator<MediaUploadList>('CHANGE_IMAGES_ORDER')
export const deleteImage = actionCreator<MediaFile | MediaUpload>('DELETE_IMAGE')
export const clearDraft = actionCreator<void>('CLEAR')
