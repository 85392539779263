import { error } from './../providerLocations/selectors'
import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  addMediaUploads,
  clearDraft,
  deleteImage,
  mediaUploadUpdate,
  changeImagesOrder,
  setImages
} from './actions'
import { edit as editLocation } from '../createLocation/actions'
import { edit as editPromotion } from '../createPromotion/actions'

// Types imports
import { StateMediaUploads, MediaUpload, MediaUploadList } from './types'
import { MediaFile } from 'src/models/uploads'

const initialState: StateMediaUploads = {
  mediaUploads: []
}

export function mediaUploads(
  state: StateMediaUploads = initialState,
  action: Action
): StateMediaUploads {
  if (isType(action, addMediaUploads)) {
    return {
      ...state,
      mediaUploads: [...state.mediaUploads, ...action.payload]
    }
  }

  if (isType(action, mediaUploadUpdate)) {
    const { payload } = action
    // TODO: (perf) redundant copies
    return {
      ...state,
      mediaUploads: Array.isArray(payload)
        ? state.mediaUploads.map(upload => ({
            ...upload,
            ...payload.find(uploadUpdate => (upload as MediaUpload).uid === uploadUpdate.uid)
          }))
        : state.mediaUploads.map(upload => ({
            ...upload,
            ...((upload as MediaUpload).uid === payload.uid ? payload : undefined)
          }))
    }
  }

  if (isType(action, editLocation) || isType(action, editPromotion)) {
    const { payload } = action
    const mediaUploads = payload.images!
    return {
      ...state,
      mediaUploads: [...mediaUploads]
    }
  }

  if (isType(action, setImages)) {
    return {
      ...state,
      mediaUploads: action.payload
    }
  }

  if (isType(action, changeImagesOrder)) {
    const { payload } = action
    return {
      ...state,
      mediaUploads: payload
    }
  }

  if (isType(action, deleteImage)) {
    const { payload } = action
    const mediaUploads = state.mediaUploads.filter(
      item =>
        (item as MediaUpload).uid !== (payload as MediaUpload).uid ||
        (item as MediaFile).id !== (payload as MediaFile).id
    )

    return {
      ...state,
      mediaUploads
    }
  }
  if (isType(action, clearDraft)) {
    return initialState
  }

  return state
}
