import axios from 'axios'

export default class FileUploader {
  async upload(file: File, toUrl: string, headers: { [key: string]: string } = {}): Promise<void> {
    if (typeof file !== 'string') {
      await fetch(toUrl, {
        method: 'PUT',
        headers: new Headers(headers),
        body: file
      })
    } else {
      throw new Error('Not supported')
    }
  }
}
