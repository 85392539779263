import { PlaceInfo, GeoLocation } from './types'

export default function parseFormattedAddress(
  fullAddress: string,
  location: GeoLocation
): PlaceInfo {
  const components = fullAddress.split(',').map(comp => comp.trim())
  if (components.length >= 4) {
    // Assume it is a full address
    if (components[components.length - 1].match(/[0-9]+/)) {
      // The last component looks like post code, drop it
      components.pop()
    }

    return {
      title: components
        .slice(0, components.length - 3)
        .join(', ')
        .trim(),
      area: components
        .slice(components.length - 3)
        .join(', ')
        .trim(),
      fullAddress,
      location
    }
  } else {
    return {
      title: fullAddress,
      fullAddress,
      location
    }
  }
}
