import React, { useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import { ProviderLocationStatus } from 'src/models/providerLocations'
import { PromotionStatus } from 'src/models/promotions'
import { DialogTitle } from '@mui/material'

export type TStatus = ProviderLocationStatus | PromotionStatus

interface Props {
  currentStatus: TStatus
  open: boolean
  onClose: () => void
  onCancel?: () => void
  onConfirm: (status: PromotionStatus) => void
}

const SelectStatusDialog = ({ currentStatus, open, onClose, onConfirm, onCancel }: Props) => {
  const [value, setValue] = useState(currentStatus)
  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  const handleSubmit = () => {
    onConfirm(value)
  }

  const handleCancel = () => {
    onClose()
  }

  const change = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    event.preventDefault()
    setValue((event.target as HTMLInputElement).value as TStatus)
  }

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth='xs' fullWidth scroll='body'>
      <DialogTitle>{t('select_status_dialog_title')}</DialogTitle>
      {/* This hack with onClick stopPropagation allows us to correctly handle radio button select */}
      <DialogContent onClick={e => e.stopPropagation()}>
        <FormControl>
          <RadioGroup
            defaultValue={currentStatus}
            value={value}
            onChange={change}
            name='radio-buttons-group'
          >
            <FormControlLabel value='draft' control={<Radio />} label={t('draft')} />
            <FormControlLabel value='review' control={<Radio />} label={t('review')} />
            {/*<FormControlLabel disabled value='live' control={<Radio />} label='Live' />*/}
            {/*<FormControlLabel disabled value='declined' control={<Radio />} label='Declined' />*/}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, py: 2 }}>
        <Stack direction='row' spacing={1}>
          <LoadingButton
            loading={false}
            color='info'
            onClick={handleCancel}
          >
            {t('change_status_cancel')}
          </LoadingButton>
          <LoadingButton
            loading={false}
            color='info'
            onClick={handleSubmit}
          >
            {t('change_status_confirm')}
          </LoadingButton>
        </Stack>
      </Box>
    </Dialog>
  )
}

export default SelectStatusDialog
