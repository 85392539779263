import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { parsePhoneNumberFromString, CountryCode } from 'libphonenumber-js'
import authStore from 'src/store/auth'
import axios from 'axios'

export default function useCountryCode() {
  const [code, setCountryCode] = useState<string | undefined>(undefined)
  const user = useSelector(authStore.selectors.user)

  useEffect(() => {
    if (!user) {
      const fetchData = async () => {
        await axios
          .get('https://api.ipstack.com/check?access_key=9f4cbe5d92324ea73b41da93c4c10de3')
          .then((data: any) => setCountryCode(data.data.country_code))
      }
      fetchData()
    }
  }, [])

  useEffect(() => {
    if (user) {
      const parsedPhoneNumber = parsePhoneNumberFromString("+"+user?.phoneNumber)
      setCountryCode(parsedPhoneNumber?.country)
    }
  }, [user])

  return code
}
