import React from 'react'
import { ReactNode } from 'react'
import { experimentalStyled as styled } from '@mui/material/styles'
import MuiButton, { ButtonProps } from '@mui/material/Button'

const ButtonRoot = styled(MuiButton)(({ theme, size }) => ({
  borderRadius: 0,
  fontWeight: '600',
  fontFamily: 'Montserrat',
  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(14),
  boxShadow: 'none',
  '&:active, &:focus': {
    boxShadow: 'none'
  },
  ...(size === 'small' && {
    padding: theme.spacing(1, 3),
    fontSize: theme.typography.pxToRem(18)
  }),
  ...(size === 'large' && {
    padding: theme.spacing(2, 5),
    fontSize: theme.typography.pxToRem(16)
  }),
  height: '50px',
  textTransform: 'none'
}))

interface PrimaryButtonProps extends ButtonProps {
  children?: ReactNode
  label?: string
  type?: 'button' | 'submit' | 'reset' | undefined
}

export const PrimaryButton = ({ children, label, type, ...rest }: PrimaryButtonProps) => {
  return (
    <ButtonRoot
      fullWidth
      variant={'contained'}
      color={'primary'}
      {...rest}
      type={type || 'submit'}
      className={'btn'}
    >
      {children ? children : null}
      {label ? label : null}
    </ButtonRoot>
  )
}

export default React.memo(PrimaryButton)
