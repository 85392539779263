import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import authStore from 'src/store/auth'

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(authStore.selectors.user)
  const redirectUrlAfterLogin = useSelector(authStore.selectors.redirectUrlAfterLogin)

  !user &&
    !redirectUrlAfterLogin &&
    dispatch(authStore.actions.setRedirectURL(window.location.pathname + window.location.search))

  useEffect(() => {
    if (user && redirectUrlAfterLogin) {
      dispatch(authStore.actions.setRedirectURL(''))
      navigate(redirectUrlAfterLogin)
    }
  }, [user])

  return !!user ? children : <Navigate to='/login' />
}

export const IsLogged: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const user = useSelector(authStore.selectors.user)

  return !!!user ? children : <Navigate to='/locations' />
}
