import React, { useEffect, useState } from 'react'
import { Container, Typography, Card, Grid, CardContent, CardMedia, Link } from '@mui/material'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PhoneIcon from '@mui/icons-material/Phone'
import EmailIcon from '@mui/icons-material/Email'
import WebIcon from '@mui/icons-material/Web'
import SliderContainer from '../components/ImagesSlider'
import MapContainer from '../components/MapContainer'
import InfoItem from '../components/InfoItem'
import Status from '../components/Status'
import { ProviderLocation } from 'src/models/providerLocations'

const LocationView = ({ data }: { data: ProviderLocation }) => {
  return (
    <Container sx={{ mt: 3 }} component='main' maxWidth='sm'>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h5'>Location Details</Typography>
            </Grid>

            <Grid item xs={12}>
              <SliderContainer images={data.images} />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight='bold' variant='h4'>
                {data.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Status status={data.status} />
            </Grid>
            <Grid item xs={12}>
              <InfoItem icon={<LocationOnIcon />} label='Address' value={data.address!} />
            </Grid>
            <Grid item xs={12}>
              <InfoItem
                icon={<PhoneIcon />}
                label='Phone'
                value={data.contactPhoneNumber!}
                phoneNumber={data.contactPhoneNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoItem
                icon={<EmailIcon />}
                label='Email'
                value={data.contactEmail!}
                email={data.contactEmail}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoItem
                icon={<WebIcon />}
                label='Website'
                value={data.websiteUrl!}
                link={data.websiteUrl!}
              />
            </Grid>
            <Grid item xs={12}>
              <InfoItem
                icon={<WebIcon />}
                label='Social Media'
                value={data.socialMediaUrl!}
                link={data.socialMediaUrl!}
              />
            </Grid>

            <Grid item xs={12}>
              <MapContainer location={data.location!} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  )
}

export default React.memo(LocationView)
