import React from 'react'
import { Box, Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material'
import { ProviderLocation, ProviderLocationId } from 'src/models/providerLocations'
import ButtonEdit from 'src/components/ButtonEdit'
import { useTranslation } from 'react-i18next'
import placeholderImage from 'src/assets/placeholder-image.svg'
import ProviderLocationStatus from 'src/components/Statuses/ProviderLocationStatus'

interface Props extends ProviderLocation {
  navigateToCreate?: (id: ProviderLocationId) => void
  navigateToPreview?: (id: ProviderLocationId) => void
}

const LocationItem = (props: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'locations_page' })

  const { navigateToPreview, navigateToCreate } = props
  const onPressEdit = () =>
    navigateToPreview ? navigateToPreview?.(props.id!) : navigateToCreate?.(props.id!)

  const handleImageError = (e: any) => {
    e.target.onerror = null
    e.target.src = placeholderImage
  }

  return (
    <Card
      key={props.id}
      elevation={0}
      sx={{
        width: 220,
        m: { xs: 1, sm: 2 },
        position: 'relative',
        backgroundColor: 'transparent'
      }}
    >
      <CardActionArea onClick={onPressEdit}>
        <CardMedia
          component='img'
          sx={{
            aspectRatio: '1',
            borderRadius: 2
          }}
          image={props.image?.url || placeholderImage}
          alt='Location Image'
          onError={handleImageError}
        />
        <CardContent
          sx={{
            pt: 3,
            pb: 1
          }}
        >
          <Typography
            color='#293845'
            fontStyle='normal'
            fontWeight='600'
            fontSize='16px'
            lineHeight='20px'
          >
            {props.name}
          </Typography>
          <Typography
            paragraph
            color='#293845'
            fontStyle='normal'
            fontWeight='600'
            fontSize='14px'
            noWrap
            mt={2}
            lineHeight='17px'
          >
            {props.address}
          </Typography>

          {props.navigateToCreate ? (
            <ButtonEdit onClick={onPressEdit} text={t('location_item_edit')} />
          ) : null}
        </CardContent>
      </CardActionArea>
      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
        <ProviderLocationStatus location={props} type={'list'} />
      </Box>
    </Card>
  )
}

export default LocationItem
