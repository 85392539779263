import actionCreatorFactory from 'typescript-fsa'

import { PromotionId, PromotionsResponse, Promotion } from 'src/models/promotions'
import { ProviderLocationId } from 'src/models/providerLocations'
import { UpdateStatusPayload } from './types'

// Actions
const actionCreator = actionCreatorFactory('promotions')

export const fetch = actionCreator.async<ProviderLocationId, PromotionsResponse, Error>('FETCH')
export const fetchAll = actionCreator<PromotionsResponse>('FETCH_ALL')
export const selectLocation = actionCreator<ProviderLocationId>('SELECT_LOCATION')
export const remove = actionCreator.async<
  { locationId: ProviderLocationId; promotionId: PromotionId },
  void,
  Error
>('REMOVE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const updateStatus = actionCreator.async<UpdateStatusPayload, Promotion, Error>(
  'UPDATE_STATUS'
)
