import { ProviderLocationId, ProviderLocation } from './../../models/providerLocations'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action } from 'typescript-fsa'

import { fetch, remove, updateStatus } from './actions'
import { UpdateStatusPayload } from './types'
import { ProviderLocationResponse } from 'src/models/providerLocations'
import { API } from 'src/data'
import { selectProvider } from '../auth/actions'

export default function createRootSaga() {
  function* fetchLocationSaga(): SagaIterator {
    try {
      const result: ProviderLocationResponse = yield call([API, API.readLocations])
      yield put(fetch.done({ result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ error }))
    }
  }

  function* updateStatusSaga({ payload }: Action<UpdateStatusPayload>): SagaIterator {
    try {
      const result: ProviderLocation = yield call(
        //@ts-ignores
        [API, API.updateLocation],
        {
          status: payload.status
        },
        payload.id
      )
      yield put(updateStatus.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(updateStatus.failed({ error, params: payload }))
    }
  }

  function* removeLocationSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      yield call([API, API.deleteLocation], payload)
      yield put(remove.done({ params: payload }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ error, params: payload }))
    }
  }

  return [
    takeLatest(fetch.started, fetchLocationSaga),
    takeLatest(selectProvider, fetchLocationSaga),

    takeLatest(remove.started, removeLocationSaga),
    takeLatest(updateStatus.started, updateStatusSaga)
  ]
}
