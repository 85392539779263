import React from 'react'
import Chip from '@mui/material/Chip'

type StatusChipProps = {
  status: string
}

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'active':
        return 'rgb(76, 175, 80)'
      case 'past_due':
        return 'rgb(156, 39, 176)'
      case 'unpaid':
        return 'rgb(244, 67, 54)'
      case 'canceled':
        return 'rgb(0, 0, 0)'
      case 'incomplete':
        return 'rgb(255, 152, 0)'
      case 'incomplete_expired':
        return 'rgb(255, 152, 0)'
      case 'trialing':
        return 'rgb(33, 150, 243)'
      case 'paused':
        return 'rgb(255,255,0)'
      default:
        return 'rgb(0, 0, 0)' // Default color as RGB
    }
  }

  const getStatusLabel = (status: string): string => {
    // Modify the labels as per your requirements
    switch (status) {
      case 'active':
        return 'Active'
      case 'past_due':
        return 'Past Due'
      case 'unpaid':
        return 'Unpaid'
      case 'canceled':
        return 'Canceled'
      case 'incomplete':
        return 'Incomplete'
      case 'incomplete_expired':
        return 'Incomplete Expired'
      case 'trialing':
        return 'Trialing'
      case 'paused':
        return 'Paused'
      default:
        return ''
    }
  }

  const color = getStatusColor(status)
  const label = getStatusLabel(status)

  return (
    <div>
      <Chip
        label={label}
        sx={{
          border: `2px solid ${color}`,
          padding: '8px',

          marginLeft: '20px',
          color: color
        }}
      />
    </div>
  )
}

export default StatusChip
