import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Spinner } from 'src/components'
import LocationForm from './LocationForm'
import createLocation from 'src/store/createLocation'
import locationsStore from 'src/store/providerLocations'
import { useSnackbar } from 'notistack'
import usePrevious from 'src/hooks/usePrevious'
import { State } from 'src/models'
import locationAdminsStore from 'src/store/locationAdmins'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import ButtonRemoveWithPrompt from 'src/components/ButtonRemoveWithPrompt'
import SubscriptionsStatusAlert from './SubscriptionsStatusAlert'

import Button from 'src/components/RoundedButton'
import { useTranslation } from 'react-i18next'
import { API } from 'src/data/index'
import authStore from 'src/store/auth'
import mediaUploadsStore from 'src/store/mediaUploads'
import subscriptionsStore from 'src/store/subscriptions'

import { Alert, AlertTitle } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { ReviewStatusAlert } from 'src/components/Statuses/ReviewStatusAlert'
import { styled } from '@mui/material/styles'

const Overlay = styled('div')`
  position: relative;

  ${({ visible }: { visible: boolean }) =>
    visible &&
    `
    pointer-events: none;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255,255,255, 0.3); // Adjust the alpha value (0.5) to set the desired transparency
      z-index: 1;
    }
  `}
`

export default function CreateLocation() {
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })

  const user = useSelector(authStore.selectors.user)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const params = useParams()
  const locationId = params?.id || (location?.state as any)?.id

  const isRemoving = useSelector(locationsStore.selectors.isRemoving)
  const isCreating = useSelector(createLocation.selectors.isCreating)
  const draft = useSelector((state: any) =>
    createLocation.selectors.currentDraft(state, locationId || 'new')
  )
  const error = useSelector(locationsStore.selectors.error)
  const createError = useSelector(createLocation.selectors.error)
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  const providerLocationFromCache = useSelector((state: State) =>
    locationsStore.selectors.locationById(state, locationId)
  )

  const prevIsRemoving = usePrevious(isRemoving)
  const prevIsCreating = usePrevious(isCreating)

  const fetchLocationFromAPI = async () => {
    try {
      const location = await API.getLocationById(locationId)

      dispatch(createLocation.actions.edit(location))
    } catch (error) {
      console.error('failed to get location from api', error)

      enqueueSnackbar('Failed to get location from API', {
        variant: 'error',
        onExited: () => dispatch(createLocation.actions.dismissError())
      })

      navigate('/locations')
    }
  }

  useEffect(() => {
    if (locationId) {
      if (providerLocationFromCache) {
        dispatch(createLocation.actions.edit(providerLocationFromCache))
      } else {
        fetchLocationFromAPI()
      }
    }
  }, [providerLocationFromCache])

  useEffect(
    () => () => {
      dispatch(mediaUploadsStore.actions.clearDraft())
    },
    []
  )

  const save = () => {
    dispatch(createLocation.actions.createLocation.started(locationId))
  }

  const remove = () => {
    dispatch(locationsStore.actions.remove.started(locationId))
  }

  const handleBack = () => {
    dispatch(locationAdminsStore.actions.clearDraft())
    navigate(-1)
  }

  useEffect(() => {
    if (!!isRemoving !== !!prevIsRemoving && !isRemoving && !error) {
      navigate('/locations')
    }
  }, [isRemoving])

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !createError) {
      dispatch(locationAdminsStore.actions.clearDraft())
      // Navigate to locations screen after successfully updating or creating location
      navigate('/locations')
      // Previously done with
      // handleBack()
      // But it's not working correctly
    }
  }, [isCreating])

  useEffect(() => {
    if (createError || error) {
      enqueueSnackbar(createError?.message || error?.message, {
        variant: 'error',
        onExited: () => dispatch(createLocation.actions.dismissError())
      })
    }
  }, [error, createError])

  if (locationId && !draft) {
    return <Spinner loading={true} />
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth='sm'
        sx={{ mt: 0, pt: { xs: 0, sm: 4 }, pb: 4, backgroundColor: '#f2f2f2' }}
      >
        {providerLocationFromCache?.ownerId !== user?.id && user?.roles?.find(role => role.isRoot) && (
          <Alert
            icon={<AdminPanelSettingsIcon fontSize='inherit' />}
            sx={{ mb: -2 }}
            variant='filled'
            severity='info'
          >
            <AlertTitle>Admin Zone</AlertTitle>
            You are editing location with admin rights.
          </Alert>
        )}
        <ReviewStatusAlert providerLocation={providerLocationFromCache} />
        <SubscriptionsStatusAlert />
        <Overlay visible={!subscriptionStatus?.locationCreationAllowed}>
          <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Typography
                component='h3'
                variant='h4'
                align='left'
                color={'text.secondary'}
                fontWeight={'500'}
              >
                {locationId ? t('title_edit') : t('title_create')}
              </Typography>
              <IconButton
                sx={{
                  position: 'relative',
                  top: 4,
                  left: -4
                }}
                onClick={handleBack}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ mt: 2, mb: 2 }} />
            <React.Fragment>
              <LocationForm locationId={locationId} providerLocation={providerLocationFromCache} />
              <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                <Button
                  type='submit'
                  fullWidth
                  disabled={!subscriptionStatus?.locationCreationAllowed}
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  onClick={save}
                  label={t('btn_save')}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='outlined'
                  sx={{ mb: 2 }}
                  onClick={handleBack}
                  label={t('btn_cancel')}
                />
              </Box>
            </React.Fragment>
            {locationId ? (
              <ButtonRemoveWithPrompt
                btnText={t('btn_remove')}
                description={t('popup_removal_text')}
                onRemove={remove}
              />
            ) : null}
          </Paper>
        </Overlay>
      </Container>
      <Spinner loading={isCreating} />
    </div>
  )
}
