import React, { FC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Box, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'

import { AppBar, Container, Hidden, IconButton, Button } from '@mui/material'
import { useSelector } from 'react-redux'

import { SideBarNav } from 'src/components/Header/SideBarNav'
import { ProviderMenu } from './ProviderMenu'
import LanguagePicker from 'src/components/LanguagePicker'
import type { Theme } from 'src/theme'
import authStore from 'src/store/auth'
import subscriptionsStore from 'src/store/subscriptions'

import horizontalLogo from 'src/assets/logo-thehubapp-horizontal.svg'
import profile from './../../assets/profile.svg'

import MenuIcon from '@mui/icons-material/Menu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

interface TopBarProps {
  className?: string
}

export const useHeaderStyles = makeStyles((theme: Theme) => ({
  toolbar: {
    height: 88,
    [theme.breakpoints.down('sm')]: {
      height: 44
    }
  },
  burgerMenuIconMobile: {
    flexGrow: 0,
    display: 'flex'
  },
  logoContainer: {
    flexGrow: 1,
    [theme.breakpoints.up('md')]: {
      flexGrow: 0
    },
    justifyContent: 'center',
    display: 'flex'
  },
  logoImage: {
    height: 48,
    [theme.breakpoints.down('sm')]: {
      height: 32
    }
  },
  desktopMenuContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  navLink: {
    '& + &': {
      marginLeft: theme.spacing(1)
    },
    marginRight: theme.spacing(1),
    textDecoration: 'none',
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.text.primary,
    padding: '20px 16px 20px 16px',
    '&:hover': {
      color: '#38A2AB !important'
    }
  },
  navLinkActive: {
    color: theme.palette.text.secondary
  },
  rightNavContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  sideNavContainer: {
    width: 250,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  mobileNavLink: {
    textDecoration: 'none',
    '&:hover': {
      color: '#38A2AB !important'
    }
  },
  mobileNavLinkActive: {
    '& span': {
      color: '#38A2AB !important'
    },
    '& svg': {
      color: '#38A2AB !important'
    }
  },
  mobileNavLinkIcon: {
    minWidth: '0 !important'
  },
  mobileNavLinkText: {
    '&>span': {
      fontWeight: '500',
      color: theme.palette.text.primary
    }
  }
}))

const TopBar: FC<TopBarProps> = ({ className, ...rest }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'header' })
  const classes = useHeaderStyles()

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const [providerMenuAnchorEl, setProviderMenuAnchorEl] = React.useState<null | HTMLElement>(null)

  const currentProvider = useSelector(authStore.selectors.currentProvider)
  const user = useSelector(authStore.selectors.user)
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleCloseMenu = () => {
    setMenuAnchorEl(null)
  }

  const handleOpenProviderMenu = (event: React.MouseEvent<HTMLElement>) => {
    setProviderMenuAnchorEl(event.currentTarget)
  }

  return (
    <>
      <AppBar
        position='static'
        sx={{
          bgcolor: 'white'
        }}
      >
        <Container maxWidth='xl'>
          <Toolbar className={classes.toolbar} disableGutters>
            <Hidden mdUp>
              <IconButton
                size='large'
                edge='start'
                color='primary'
                aria-label='menu'
                className={classes.burgerMenuIconMobile}
                onClick={handleOpenMenu}
              >
                <MenuIcon fontSize='large' />
              </IconButton>
            </Hidden>

            <Box className={classes.logoContainer}>
              <NavLink to='/locations'>
                <img alt='HubApp Logo' src={horizontalLogo} className={classes.logoImage} />
              </NavLink>
            </Box>

            <Hidden mdDown>
              <Box className={classes.desktopMenuContainer}>
                {user?.roles?.[0]?.name === 'Admin' ||
                user?.roles?.[1]?.name === 'Admin' ||
                user?.roles?.[0]?.name === 'Root' ? (
                  <NavLink
                    className={({ isActive }) =>
                      !isActive ? clsx(classes.navLinkActive, classes.navLink) : classes.navLink
                    }
                    to={'/admin/providers-list'}
                  >
                    Admin
                  </NavLink>
                ) : null}

                {subscriptionStatus?.locationCreationIsFree ? null : (
                  <NavLink
                    className={({ isActive }) =>
                      !isActive ? clsx(classes.navLinkActive, classes.navLink) : classes.navLink
                    }
                    to={'/subscriptions'}
                  >
                    {t('tab_subscriptions')}
                  </NavLink>
                )}
                <NavLink
                  className={({ isActive }) =>
                    !isActive ? clsx(classes.navLinkActive, classes.navLink) : classes.navLink
                  }
                  to={'/locations'}
                >
                  {t('tab_locations')}
                </NavLink>
                <NavLink
                  className={({ isActive }) =>
                    !isActive ? clsx(classes.navLinkActive, classes.navLink) : classes.navLink
                  }
                  to={'/promotions'}
                >
                  {t('tab_promotions')}
                </NavLink>
              </Box>
            </Hidden>

            <Box className={classes.rightNavContainer}>
              <Hidden mdDown>
                <Button
                  sx={{ textTransform: 'none', px: 1, py: -1, fontSize: 16, fontWeight: 600 }}
                  endIcon={
                    providerMenuAnchorEl ? (
                      <ArrowDropUpIcon sx={{ ml: -1 }} />
                    ) : (
                      <ArrowDropDownIcon sx={{ ml: -1 }} />
                    )
                  }
                  onClick={handleOpenProviderMenu}
                >
                  {currentProvider?.name
                    ? currentProvider.name
                    : currentProvider?.id
                    ? 'Provider #' + currentProvider?.id
                    : 'Provider not selected'}
                  <IconButton
                    sx={{ p: 0, ml: 1 }}
                    aria-label='User menu popup'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    color='inherit'
                  >
                    <img alt='User profile' src={profile} />
                  </IconButton>
                </Button>
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  size='large'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  color='inherit'
                  onClick={handleOpenProviderMenu}
                >
                  <img alt='User profile' src={profile} />
                </IconButton>
                <IconButton
                  sx={{ p: 0, ml: -1 }}
                  size='small'
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  color='inherit'
                  onClick={handleOpenProviderMenu}
                >
                  {providerMenuAnchorEl ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </IconButton>
              </Hidden>

              <ProviderMenu anchorEl={providerMenuAnchorEl} setAnchorEl={setProviderMenuAnchorEl} />

              <Hidden mdDown>
                <Box sx={{ ml: 2 }}>
                  <LanguagePicker />
                </Box>
              </Hidden>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <SideBarNav classes={classes} anchorEl={menuAnchorEl} handleCloseMenu={handleCloseMenu} />
    </>
  )
}

export default TopBar
