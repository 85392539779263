// ** React Imports
import { useState, ChangeEvent } from 'react'

// ** MUI Imports
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LinearProgress from '@mui/material/LinearProgress'
import WarningIcon from '@mui/icons-material/Warning'
import Chip from '@mui/material/Chip'
import CircularProgress from '@mui/material/CircularProgress'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { ProviderSubscription } from 'src/models/subscriptions'
import subscriptionsStore from 'src/store/subscriptions'
import SubscriptionStatus from './SubscriptionStatus'

import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const { format, differenceInDays, addDays } = require('date-fns')

// ** Icon Imports

function formatDate(dateString: string) {
  const date = new Date(dateString)
  const formattedDate = format(date, 'MMMM d, yyyy')

  return formattedDate
}

function getDaysRemainingUntilUpdate(updateDate: Date): string {
  const currentDate = new Date()
  const remainingDays = Math.ceil(
    (addDays(updateDate, 1) - (currentDate as any)) / (1000 * 60 * 60 * 24)
  )

  return `${remainingDays} days remaining until your plan requires an update`
}

function getProgressPercentage(startDate: Date, endDate: Date) {
  const totalDays = differenceInDays(endDate, startDate)
  const passedDays = differenceInDays(new Date(), startDate)
  return Math.floor((passedDays / totalDays) * 100)
}

const CurrentPlanCard = ({ subscription }: { subscription: ProviderSubscription }) => {
  // ** State
  const [open, setOpen] = useState<boolean>(false)
  const [userInput, setUserInput] = useState<boolean>(true)
  const [secondDialogOpen, setSecondDialogOpen] = useState<boolean>(false)

  const isUpdating = useSelector(subscriptionsStore.selectors.isUpdating)

  const dispatch = useDispatch()
  const { t } = useTranslation('translation')

  const { currentPeriodEndDate, cancelAt, plan, status, currentPeriodStartDate } = subscription
  const handleClose = () => setOpen(false)

  const handleSecondDialogClose = () => setSecondDialogOpen(false)

  const handleConfirmation = (value: boolean) => {
    handleClose()
    if (value) {
      setSecondDialogOpen(true)
      updateStatus()
    }
    setUserInput(value)
  }

  const updateStatus = () => {
    dispatch(
      subscriptionsStore.actions.updateSubscription.started({
        id: subscription.id,
        params: {
          status: 'canceled'
        }
      })
    )
  }

  if (!plan) {
    return null
  }

  const strings = plan?.i18n?.[i18next?.language] || plan?.i18n?.['en'] || {}

  function getDaysProgress(startDate: Date, endDate: Date) {
    const totalDays = differenceInDays(endDate, startDate)
    const passedDays = differenceInDays(new Date(), startDate)
    const progressString = t('subscriptions.item_progress_value', { passedDays, totalDays })

    return progressString
  }

  return (
    <>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 6 }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography sx={{ mb: 1, fontWeight: 600 }}>
                    {t('subscriptions.item_current_plan', {
                      plan: t(strings?.title)
                    })}
                  </Typography>
                  <SubscriptionStatus status={status} />
                </div>

                <Typography sx={{ color: 'text.secondary' }}>{t(strings.description)}</Typography>
              </Box>
              <Box sx={{ mb: 6 }}>
                <Typography sx={{ mb: 1, fontWeight: 600 }}>
                  {t('subscriptions.item_next_payment', {
                    date: formatDate(subscription.currentPeriodEndDate)
                  })}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  {t('subscriptions.item_next_payment_desc')}
                </Typography>
              </Box>
              <div>
                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ mr: 2, fontWeight: 600 }}>
                    {t('subscriptions.item_total_price', {
                      price: `$${(+plan.price * subscription.quantity).toFixed(2)}`,
                      quantity: subscription.quantity
                    })}
                  </Typography>
                </Box>
                <Typography sx={{ color: 'text.secondary' }}>
                  {t('subscriptions.item_price_for_location', {
                    price: `${plan.price} ${plan.currency}`
                  })}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Alert severity='warning' sx={{ mb: 6 }}>
                <AlertTitle>{t('subscriptions.item_alert_title')}</AlertTitle>
                {t('subscriptions.item_alert_subtitle')}
              </Alert>

              <div>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                    {t('subscriptions.item_progress_days')}
                  </Typography>
                  <Typography sx={{ fontWeight: 600, fontSize: '0.875rem' }}>
                    {getDaysProgress(
                      new Date(currentPeriodStartDate),
                      new Date(currentPeriodEndDate)
                    )}
                  </Typography>
                </Box>
                <LinearProgress
                  value={getProgressPercentage(
                    new Date(currentPeriodStartDate),
                    new Date(currentPeriodEndDate)
                  )}
                  variant='determinate'
                  sx={{
                    my: 1.5,
                    height: 8,
                    borderRadius: 6,
                    '& .MuiLinearProgress-bar': { borderRadius: 6 }
                  }}
                />
                <Typography variant='caption'>
                  {getDaysRemainingUntilUpdate(new Date(currentPeriodEndDate))}
                </Typography>
              </div>
              {!cancelAt ? (
                <Grid item xs={12}>
                  <Box sx={{ mt: 3, gap: 4, display: 'flex', flexWrap: 'wrap' }}>
                    <Button variant='outlined' color='secondary' onClick={() => setOpen(true)}>
                      {isUpdating ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        t('subscriptions.btn_cancel_subscription')
                      )}
                    </Button>
                  </Box>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Dialog fullWidth maxWidth='xs' open={open} onClose={handleClose}>
        <DialogContent>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                maxWidth: '85%',
                textAlign: 'center',
                '& svg': { mb: 4, color: 'warning.main' }
              }}
            >
              <WarningIcon fontSize='large' />
              <Typography>{t('subscriptions.alert_submit_cancellation')}</Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='contained' onClick={() => handleConfirmation(true)}>
            {t('subscriptions.alert_submit_cancellation_yes')}
          </Button>
          <Button variant='outlined' color='secondary' onClick={() => handleConfirmation(false)}>
            {t('subscriptions.alert_submit_cancellation_no')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog fullWidth maxWidth='xs' open={secondDialogOpen} onClose={handleSecondDialogClose}>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              '& svg': {
                mb: 14,
                color: 'success.main'
              }
            }}
          >
            <CheckCircleOutlineIcon fontSize='large' />
            <Typography variant='h4' sx={{ mb: 8 }}>
              {t('subscriptions.alert_cancel_success_title')}
            </Typography>
            <Typography> {t('subscriptions.alert_cancel_success_subtitle')}</Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button variant='contained' color='success' onClick={handleSecondDialogClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CurrentPlanCard
