import { PlaceInfo } from './types'

import parseFormattedAddress from './parseFormattedAddress'

export default async function reverseGeocode(
  apiKey: string,
  latitude: number,
  longitude: number,
  language?: string
): Promise<PlaceInfo | null> {
  const res = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${latitude},${longitude}` +
      (language ? `&language=${language}` : '')
  )

  if (res.ok) {
    try {
      const data = await res.json()
      console.log(data)
      if (data && data.results && data.results.length) {
        const place = data.results[0]
        return parseFormattedAddress(place.formatted_address, {
          latitude: place.geometry.location.lat,
          longitude: place.geometry.location.lng
        })
      }

      return null
    } catch (error) {
      throw new Error('Invalid response from server')
    }
  } else {
    let errorData
    try {
      errorData = await res.json()
    } catch (error) {
      /**/
    }

    throw new Error(
      errorData && errorData.error_message
        ? errorData.error_message
        : `Network problem (code: ${res.status})`
    )
  }
}
