import { Typography, Card, Grid } from '@mui/material'
import React from 'react'
import { StatusInline, StatusGroup } from 'src/components/Statuses/StatusView'

const Item = ({ status }: { status: any }) => {
  return (
    <Grid container spacing={1} alignItems='center'>
      <Grid item>
        <Typography mr={2} variant='body1'>
          {'Status:'}:
        </Typography>
      </Grid>
      <StatusGroup status={status} />
    </Grid>
  )
}

export default React.memo(Item)
