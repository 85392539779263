import 'firebase/remote-config'
import { firebaseInstance } from './Instance'
import { getRemoteConfig, fetchAndActivate, getAll } from 'firebase/remote-config'
import _ from 'lodash'

import once from 'lodash/once'

const ConfigKeys = ['providerWebsiteTranslationsPackageUrl_2_1'] as const
type ConfigKeysType = typeof ConfigKeys[number]

export const firebaseRemoteConfig = once(async () => {
  const remoteConfig = getRemoteConfig(firebaseInstance())
  remoteConfig.settings = {
    minimumFetchIntervalMillis: 180000,
    fetchTimeoutMillis: 60000
  }

  await fetchAndActivate(remoteConfig)

  const values = getAll(remoteConfig)

  const data = ConfigKeys.reduce(
    (c, k) =>
      Object.assign(c, {
        [k]: _.isBoolean(values[k]) ? values[k].asBoolean() : values[k].asString()
      }),
    {}
  ) as { [key in ConfigKeysType]: any }

  return data
})
