import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { add, remove, fetch, dismissError } from './actions'
import omitBy from 'lodash/omitBy'
import keyBy from 'lodash/keyBy'

// Types imports
import { StateAdmins } from './types'

const initialState: StateAdmins = {
  list: {}
}

export function admins(state: StateAdmins = initialState, action: Action): StateAdmins {
  if (isType(action, add.started)) {
    return {
      ...state,
      isCreating: true,
      createError: undefined
    }
  }

  if (isType(action, add.done)) {
    const data = action.payload.result
    return {
      ...state,
      list: {
        ...state.list,
        [data.providerId]: data
      },
      isCreating: false
    }
  }

  if (isType(action, add.failed)) {
    return {
      ...state,
      createError: action.payload.error,
      isCreating: false
    }
  }

  if (isType(action, fetch.started)) {
    return {
      ...state,
      isFetching: true
    }
  }

  if (isType(action, fetch.done)) {
    const data = keyBy(action.payload.result, 'id')
    return {
      ...state,
      isFetching: false,
      list: data
    }
  }

  if (isType(action, fetch.failed)) {
    return {
      ...state,
      isFetching: false,
      createError: action.payload.error
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      isRemoving: true
    }
  }

  if (isType(action, remove.done)) {
    const admin = action.payload.params
    return {
      ...state,
      isRemoving: false,
      list: omitBy(state.list, ({ id }) => id === admin.id)
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,
      isRemoving: false,
      createError: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      createError: undefined
    }
  }

  return state
}
