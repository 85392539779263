import React from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckIcon from '@mui/icons-material/Check'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { visuallyHidden } from '@mui/utils'
import { ProviderLocationStatus } from 'src/models/providerLocations'
import { PromotionStatus } from 'src/models/promotions'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTranslation } from 'react-i18next'

export type TStatus = ProviderLocationStatus | PromotionStatus

type IconSize = 'small' | 'inherit' | 'large' | 'medium'

export const statusMapping: Partial<
  Record<
    TStatus,
    { icon: (size?: IconSize) => React.ReactNode; translationKey: string; bgColor: string }
  >
> = {
  draft: {
    icon: function DraftIcon(size) {
      return (
        <NoteAltOutlinedIcon
          fontSize={size}
          sx={{
            color: 'gray'
          }}
        />
      )
    },
    //@ts-ignore
    bgColor: (theme: any) => theme.palette.grey[500],
    translationKey: 'draft'
  },
  declined: {
    icon: function FailureIcon(size) {
      return <CloseOutlinedIcon sx={{ color: 'error.light' }} fontSize={size} />
    },
    bgColor: 'red',
    translationKey: 'declined'
  },
  review: {
    icon: function PendingIcon(size) {
      return <AccessTimeIcon sx={{ color: 'warning.light' }} fontSize={size} />
    },
    bgColor: 'orange',
    translationKey: 'review'
  },
  /*
    Paused: {
        icon: function PausedIcon (size) {
            return (
                <AccessTimeIcon
                    sx={{ color: (theme) => theme.palette.grey[500] }}
                    fontSize={size}
                />
            );
        },
        text: "Paused",
    },
   
    pending: {
        icon: function PendingIcon (size) {
            return <AccessTimeIcon sx={{ color: "warning.light" }} fontSize={size} />;
        },
        text: "Pending…",
    },
   

    STARTED: {
        icon: function StartedIcon (size) {
            return <AccessTimeIcon sx={{ color: "warning.light" }} fontSize={size} />;
        },
        text: "Running…",
    },
    */
  live: {
    icon: function SuccessIcon(size) {
      return <CheckIcon sx={{ color: 'success.light' }} fontSize={size} />
    },
    bgColor: 'green',
    translationKey: 'live'
  }
}

export const StatusInline: React.FC<{
  status: TStatus
  size?: IconSize
  onClick: () => void
}> = ({ status, onClick, size = 'medium' }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  //@ts-ignore
  const { bgColor } = statusMapping[status]
  return (
    <Tooltip title={t(statusMapping[status]!.translationKey) as string}>
      <Stack component='span' direction='row' alignItems='center' justifyContent='center'>
        <Box
          onTouchStart={event => event.stopPropagation()}
          onMouseDown={event => event.stopPropagation()}
          onClick={event => {
            event.stopPropagation()
            event.preventDefault()
            onClick()
          }}
          sx={{
            width: 16,
            height: 16,
            borderRadius: 8,
            border: 1,
            alignItems: 'center',
            justifyContent: 'center',
            borderColor: 'white',
            backgroundColor: bgColor
          }}
        />
        <Typography component='span' sx={visuallyHidden}>
          {t(statusMapping[status]!.translationKey)}
        </Typography>
      </Stack>
    </Tooltip>
  )
}

export type StatusProps = {
  status: TStatus
  icon?: React.ReactNode
  title?: string
  description?: string
  onClick?: () => void
  style?: React.CSSProperties
}

export const StatusGroup: React.FC<StatusProps> = ({
  title,
  description,
  icon,
  onClick,
  status,
  style
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
      component='dl'
      style={style}
      onClick={onClick}
    >
      <Box
        component='dt'
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          cursor: 'pointer'
        }}
      >
        <Box
          component='span'
          sx={{
            width: theme => theme.spacing(3),
            height: theme => theme.spacing(3)
          }}
        >
          {icon || statusMapping[status]?.icon()}
        </Box>
        <Typography
          component='span'
          sx={{
            fontWeight: theme => theme.typography.fontWeightRegular,
            flex: 1,
            marginLeft: theme => theme.spacing(1),
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {title || (t(statusMapping[status]!.translationKey) as string)}{' '}
          {onClick ? <ArrowDropDownIcon /> : null}
        </Typography>
      </Box>
      {description && (
        <Typography
          component='dd'
          sx={{ color: 'secondary', marginLeft: theme => theme.spacing(4) }}
          variant='body2'
        >
          {description}
        </Typography>
      )}
    </Box>
  )
}
