import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { add } from 'date-fns'

import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'
import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import MuiTableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import Typography from '@mui/material/Typography'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import TimeInput from 'src/lib/TimePicker'
import '../../lib/TimePicker/components/TimeInput.css'
import { useTranslation } from 'react-i18next'

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DayOfTheWeek } from 'src/models/promotions'
import createPromotionStore from 'src/store/createPromotion'
import { ProviderLocationId } from 'src/models/providerLocations'
import * as dateLocale from 'date-fns/locale'

const days: DayOfTheWeek[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

interface Props {
  promotionId: ProviderLocationId
}

const ScheduleEditor = ({ promotionId }: Props) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'create_promotion' })

  const currentDraft = useSelector((state: any) =>
    createPromotionStore.selectors.currentDraft(state, promotionId || 'new')
  )

  useEffect(() => {
    if (!promotionId && !currentDraft?.discountSchedule) {
      const discountSchedule = days.reduce(
        (acc, item) =>
          Object.assign(acc, {
            [item]: {
              dayOfTheWeek: item,
              startTime: '00:00',
              endTime: '23:59'
            }
          }),
        {}
      )
      dispatch(
        createPromotionStore.actions.updateDraft({
          id: promotionId,
          discountSchedule,
          startDate: new Date()
        })
      )
    }
  }, [])

  const schedule = currentDraft?.discountSchedule || {}
  const isAnyTime = !!currentDraft?.discountAvailableAtAnyTime

  const updateTime = (day: DayOfTheWeek, fieldName: string) => (value: Date | string | null) => {
    updateDraft('discountSchedule', {
      ...schedule,
      [day]: {
        ...(schedule?.[day] || {}),
        isSelected: true,
        dayOfTheWeek: day,
        [fieldName]: value
      }
    })
  }

  const updateDraft = (fieldName: string, data: any) =>
    dispatch(
      createPromotionStore.actions.updateDraft({
        id: promotionId,
        [fieldName]: data
      })
    )

  const updateDate = (fieldName: string) => (value: Date | string | null) =>
    updateDraft(fieldName, value)

  const selectAnyTime = (e: any) => updateDraft('discountAvailableAtAnyTime', e.target.checked)

  const selectStartDate = (e: any) => {
    updateDraft('startDate', currentDraft?.startDate ? null : new Date())
  }

  const selectDay = (day: DayOfTheWeek) => (e: any) =>
    updateDraft('discountSchedule', {
      ...schedule,
      [day]: {
        ...(schedule?.[day] || {
          dayOfTheWeek: day,
          startTime: '00:00',
          endTime: '23:59'
        }),
        isSelected: e.target.checked
      }
    })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControlLabel
        onChange={selectAnyTime}
        checked={isAnyTime}
        control={<Checkbox />}
        label={t('checkbox_any_time')}
      />

      {!isAnyTime && (
        <Stack direction='column' spacing={2} sx={{ width: '100%' }}>
          <Table
            size='small'
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none'
              }
            }}
          >
            <TableBody>
              <TableRow key={'title'}>
                <MuiTableCell align='left'>
                  <Typography align='center'>{t('picker_day')}</Typography>
                </MuiTableCell>
                <MuiTableCell sx={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                  <Box flex={1}>
                    <Typography align='center'>{t('picker_start_time')}</Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography align='center'>{t('picker_end_time')}</Typography>
                  </Box>
                </MuiTableCell>
              </TableRow>

              {days?.map((row, index) => (
                <TableRow key={row}>
                  <MuiTableCell
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                    align='left'
                  >
                    <Checkbox
                      checked={!!schedule?.[row]?.isSelected}
                      onChange={selectDay(row)}
                      disabled={isAnyTime}
                    />
                    <Typography textTransform={'capitalize'}>
                      {(dateLocale as any)?.[i18n.language]?.localize.day(index) || row}
                    </Typography>
                  </MuiTableCell>
                  <MuiTableCell sx={{ padding: 0 }} align='right'>
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      <TimeInput
                        key={row + 'start'}
                        hour12Format
                        value={schedule?.[row]?.startTime || '00:00'}
                        allowDelete
                        eachInputDropdown
                        onChange={updateTime(row, 'startTime')}
                      />
                      <Box ml={2}>
                        <TimeInput
                          key={row + 'end'}
                          hour12Format
                          value={schedule?.[row]?.endTime || '23:59'}
                          allowDelete
                          eachInputDropdown
                          onChange={updateTime(row, 'endTime')}
                        />
                      </Box>
                    </Box>
                  </MuiTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      )}

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { xs: 'flex-start', md: 'space-between' },
          mt: 1
        }}
      >
        <Box>
          <FormControlLabel
            onChange={selectStartDate}
            checked={!!currentDraft?.startDate}
            control={<Checkbox />}
            label={t('picker_start_promotion')}
          />
        </Box>
        <Box sx={{ mt: { xs: 1, md: 0 }, display: !currentDraft?.startDate ? 'none' : 'flex' }}>
          <DesktopDatePicker
            value={currentDraft?.startDate}
            onChange={updateDate('startDate')}
            label={t('picker_start_date')}
            renderInput={params => (
              <TextField
                sx={{ width: 170 }}
                size='small'
                {...params}
                error={params.error && !promotionId}
              />
            )}
            disablePast
          />
        </Box>
      </Box>
    </LocalizationProvider>
  )
}

export default ScheduleEditor
