import React, { useEffect, useState } from 'react'
import { Button, Typography, TextField, Box, Container } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import subscriptionsStore from 'src/store/subscriptions'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'

const PromoCodeComponent = (): JSX.Element => {
  const subscriptionPromoCode = useSelector(subscriptionsStore.selectors.subscriptionPromoCode)
  const [promoCode, setPromoCode] = useState<string>(subscriptionPromoCode || '')
  const [showPromoView, setShowPromoView] = useState<boolean>(true)

  const [searchParams] = useSearchParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const dispatch = useDispatch()

  useEffect(() => {
    if (searchParams.get('promoCode')?.length) {
      setPromoCode(searchParams.get('promoCode')!)
      setShowPromoView(true)
    }
  }, [searchParams])

  const togglePromoView = () => {
    setShowPromoView(!showPromoView)
  }

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(event.target.value)
  }

  const applyPromoCode = () => {
    dispatch(subscriptionsStore.actions.fetchPlans.started(promoCode))
  }

  return (
    <div>
      {!showPromoView ? (
        <Typography
          variant='body1'
          textAlign={'center'}
          onClick={togglePromoView}
          color={'primary'}
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          Click here to enter a promotional code to receive a discount on your subscription
        </Typography>
      ) : null}

      {showPromoView && (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant='h6'>Enter Promo Code</Typography>
          <Box
            mt={1}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '48px'
            }}
          >
            <TextField
              label='Promo Code'
              variant='outlined'
              fullWidth
              value={promoCode}
              onChange={handlePromoCodeChange}
              style={{ flex: 1, marginRight: '8px', background: 'white' }}
            />
            <Button
              variant='contained'
              color='primary'
              //     disabled={!promoCode}
              onClick={applyPromoCode}
              style={{ flex: 1, marginLeft: '8px', height: 50 }}
            >
              Apply Promo Code
            </Button>
          </Box>
          
        </Container>
      )}
    </div>
  )
}

export default PromoCodeComponent
