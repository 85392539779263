import React from 'react'
import type { FC } from 'react'
import type { Theme } from 'src/theme'
import { Box, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3)
  }
}))

const CustomLoadingOverlay: FC = () => {
  const classes = useStyles()

  return (
    <Box sx={{ position: 'absolute', top: { xs: 44, md: 88 }, width: '100%' }}>
      <LinearProgress color='primary' />
    </Box>
  )
}

export default CustomLoadingOverlay
