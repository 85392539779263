import { Box, CardHeader, Grid, Card, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import SubscriptionCard from './SubscriptionCard'
import { useTranslation } from 'react-i18next'

export default function ActiveSubscriptions() {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })

  const activeSubscriptions = useSelector(subscriptionsStore.selectors.subscriptions)
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  if (!activeSubscriptions.length || !subscriptionStatus) {
    return null
  }

  const { locationsCountUsed, locationsCountAvailable } = subscriptionStatus!
  return (
    <Box
      sx={{
        borderRadius: '4px',
        marginTop: '2rem',
        padding: '1rem',
        boxShadow: 'rgb(2 2 40 / 8%) 0px 4px 6px',
        background: 'white'
      }}
    >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={6}>
          <CardHeader title={t('list_title')} />
        </Grid>
        <Grid item xs={6} container justifyContent={'flex-end'}>
          <Typography
            sx={{
              marginRight: '16px',
              fontWeight: 600
            }}
            variant='body1'
          >
            {t('list_available_locations', {
              locationsCountUsed,
              locationsCountAvailable
            })}
          </Typography>
        </Grid>
      </Grid>
      {activeSubscriptions.map((subscription, index) => (
        <SubscriptionCard key={subscription.id} subscription={subscription} />
      ))}
    </Box>
  )
}
