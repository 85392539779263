import React from 'react'
import Typography from '@mui/material/Typography'
import InboxIcon from '@mui/icons-material/Inbox' // Import the icon
import Box from '@mui/material/Box'

export function TotalCountDisplay({ totalCount }: { totalCount: number }) {
  return <Typography variant='h6'>Total Count: {totalCount}</Typography>
}

export function ListEmpty() {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' p={2}>
      <InboxIcon fontSize='large' color='action' />
      <Typography variant='subtitle1' color='textSecondary'>
        No items found
      </Typography>
    </Box>
  )
}
