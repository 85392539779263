import React, { ChangeEvent, useEffect, useState } from 'react'
import { Container, Card, Grid, CardContent, Box, Paper, Divider, Typography } from '@mui/material'
import store from 'src/store/adminPreview'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ProvidersStats, ProvidersStatsList } from 'src/models/providerStats'
import { Progress } from '../components/Progress'
import DateRangePicker from '../components/DateRangePicker'
import IconButton from '@mui/material/IconButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import LineChart from './LineChart'
import LinesChart from './LinesChart'

import GraphViewSwitch from './GraphViewSwitch'
import ChartType from './ChartType'
import { chartData, chartColors, TypeStatus, GraphViewType } from './constants'

import formatDate from 'date-fns/format'

interface StatCardProps {
  title: string
  value: number | string
  color?: string
}

interface ChartProps {
  label: string
  field: string
}

const StatCard: React.FC<StatCardProps> = ({ title, value, color }) => {
  return (
    <Paper
      elevation={3}
      style={{
        padding: 16,
        margin: 8,
        maxWidth: 300,
        textAlign: 'center',
        background: color || '#39a1ab'
      }}
    >
      <Typography color={'white'}>{title}</Typography>
      <Typography variant='h5' component='h5' color={'white'}>
        {value}
      </Typography>
    </Paper>
  )
}

const StatsSection = ({ data }: { data: ProvidersStats }) => {
  const { type } = data

  return (
    <Box mb={4} sx={{ maxWidth: 'lg', margin: 'auto' }}>
      <Typography variant='h6' gutterBottom>
        Summary
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['providersWithActiveSubscriptionsCount']}
            title='Provider with Active Subscriptions'
            value={data.providersWithActiveSubscriptionsCount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['providersWithActivePromotionsCount']}
            title='Provider with Active Promotions'
            value={data.providersWithActivePromotionsCount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['providersWithFoodicsCount']}
            title='Providers with Foodics'
            value={data.providersWithFoodicsCount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['activeLocationsCount']}
            title='Active Locations'
            value={data.activeLocationsCount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['activePromotionsCount']}
            title='Active Promotions'
            value={data.activePromotionsCount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard
            color={chartColors['activePromotionsWithFoodicsCount']}
            title='Active Promotions With Foodics'
            value={data.activePromotionsWithFoodicsCount}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

const App: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [status, setStatus] = useState<TypeStatus>('daily')
  const [graphViewType, setGraphViewType] = useState<GraphViewType>('single')

  const providerStats = useSelector(store.selectors.providerStats)!
  const providerStatsFilters = useSelector(store.selectors.providerStatsFilters)

  useEffect(() => {
    dispatch(store.actions.fetchProvidersStats.started())
  }, [])

  if (!providerStats) {
    return <Progress />
  }

  const ChartSection = ({ data }: { data: ChartProps }) => {
    const chart = {
      dates: providerStats.data.map(item => formatDate(new Date(item.date), 'dd MMM yy')),
      //@ts-ignore
      quantities: providerStats.data.map(item => item[data.field])
    }

    return (
      <>
        <Typography variant='h5' mt={2} mb={2}>
          {data.label}
        </Typography>
        <LineChart
          dates={chart.dates}
          quantities={chart.quantities}
          //@ts-ignore
          color={chartColors[data.field]}
        />
      </>
    )
  }

  const ChartsSection = () => {
    return (
      <>
        <Typography variant='h5' mt={2} mb={2}>
          Statistics graph
        </Typography>
        <LinesChart data={providerStats.data} />
      </>
    )
  }

  const handleStatusChange = (event: React.MouseEvent<HTMLElement>, newStatus: TypeStatus) => {
    dispatch(store.actions.setProviderStatsFilter({ type: newStatus }))
    dispatch(store.actions.fetchProvidersStats.started())
  }

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newStatus: GraphViewType) => {
    if (newStatus !== null) {
      setGraphViewType(newStatus)
    }
  }

  const onBackClick = () => navigate(-1)

  return (
    <div className='full-page full-page-gray-bg'>
      <Grid container spacing={1}>
        <Container sx={{ mt: 3, mb: 5 }} component='main' maxWidth='lg'>
          <Card>
            <CardContent>
              <Box display='flex' alignItems='center' mt={2} mb={3}>
                <IconButton onClick={onBackClick} aria-label='back'>
                  <ArrowBackIcon />
                </IconButton>
                <Typography ml={5} variant='h5' sx={{ fontWeight: '500' }}>
                  Provider statistics
                </Typography>
                {/* If there's additional content to the right, add it here */}
              </Box>

              <Box display='flex' mt={2} mb={2} justifyContent='space-between'>
                <ChartType
                  status={providerStatsFilters.type || 'daily'}
                  onChange={handleStatusChange}
                />
                <DateRangePicker />
              </Box>

              <StatsSection data={providerStats.data[0]} />
              <GraphViewSwitch currentView={graphViewType} onChange={handleViewChange} />

              {graphViewType === 'single' ? (
                chartData.map(item => <ChartSection data={item} />)
              ) : (
                <ChartsSection />
              )}
            </CardContent>
          </Card>
        </Container>
      </Grid>
    </div>
  )
}

export default App
