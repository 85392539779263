import rtl from 'jss-rtl'
import { create } from 'jss'
import i18next from 'i18next'
import * as React from 'react'
import { useEffect } from 'react'
import createCache from '@emotion/cache'
import rtlPlugin from 'stylis-plugin-rtl'
import { useSelector } from 'react-redux'
import { CacheProvider } from '@emotion/react'
import { StylesProvider, jssPreset } from '@mui/styles'
import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider, responsiveFontSizes } from '@mui/material/styles'

import { RequireAuth, IsLogged } from 'src/components/RequireAuth'
import Header from './components/Header'
import remoteConfigStore from 'src/store/remoteConfig'
import { GoogleRecaptchaWrapper } from 'src/components/GoogleRecaptchaWrapper'
import { CheckProviderOrNavigateToCreate } from 'src/components/CheckProviderOrNavigateToCreate'
import SubscriptionRedirect from 'src/components/SubscriptionRedirect'

import Welcome from './pages/Welcome'
import Login from './pages/Login'
import OneTimePassword from './pages/OneTimePassword'
import CreateLocation from './pages/CreateLocation'
import CreatePromotion from './pages/CreatePromotion'
import Locations from './pages/Locations'
import Promotions from './pages/Promotions'
import MyProfile from './pages/MyProfile'
import CreateProvider from './pages/CreateProvider'
import ProviderSettings from './pages/ProviderSettings'
import AddAdmin from './pages/AddAdmin'
import PromotionPreview from './pages/PromotionPreview'
import AddLocationAdmin from './pages/AddLocationAdmin'
import { FoodicsSuccess } from './pages/Foodics/FoodicsSuccess'
import Subscriptions from './pages/Subscriptions'
import AdminLocationPreview from './pages/AdminPreview/ProviderLocation'
import AdminPromotionPreview from './pages/AdminPreview/Promotion'
import AdminProviderInfoPreview from './pages/AdminPreview/ProviderInfo'
import AdminProvidersList from './pages/AdminPreview/ProvidersList'
import AdminProviderStats from './pages/AdminPreview/ProviderStats'

const cacheLtr = createCache({
  key: 'muiltr'
})

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [rtlPlugin]
})

const themeRTL = createTheme({
  direction: 'rtl',
  palette: {
    primary: { main: '#39a1ab' }
  },
  typography: {
    fontFamily: ['Montserrat', 'Share'].join(',')
  }
})

const themeLTR = createTheme({
  direction: 'ltr',
  palette: {
    primary: { main: '#39a1ab' }
  },
  typography: {
    fontFamily: ['Montserrat', 'Share'].join(',')
  }
})

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

function RTL(props: { children: any }) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>
}

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default function App() {
  const isReady = useSelector(remoteConfigStore.selectors.isReady)
  const [isRtl, setIsRtl] = React.useState(false)

  React.useEffect(() => {
    i18next.on('languageChanged', onLanguageChange)
    return () => i18next.off('languageChanged', onLanguageChange)
  }, [isReady])

  const onLanguageChange = (newLang: any) => {
    if (isReady) {
      const rtl = i18next.dir(newLang)
      setIsRtl(rtl === 'rtl')
    }
  }

  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', isRtl ? 'rtl' : 'ltr')
  }, [isRtl])

  let theme = isRtl ? themeRTL : themeLTR
  theme = responsiveFontSizes(theme)

  theme.typography.h1 = {
    fontSize: '1.1rem',
    '@media (min-width:600px)': {
      fontSize: '1.3rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.625rem'
    }
  }

  const UnkownRouteHandler = ({ to }: { to: string }) => {
    const prevRoute = useLocation()
    return <Navigate to={to} state={{ prevRoute }} replace />
  }

  return isReady ? (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={theme}>
        <RTL>
          <ScrollToTop />
          <Routes>
            <Route
              path='/'
              element={
                <IsLogged>
                  <Welcome />
                </IsLogged>
              }
            />
            <Route
              path='login'
              element={
                <IsLogged>
                  <GoogleRecaptchaWrapper>
                    <Login />
                  </GoogleRecaptchaWrapper>
                </IsLogged>
              }
            />
            <Route
              path='password'
              element={
                <IsLogged>
                  <GoogleRecaptchaWrapper>
                    <OneTimePassword />
                  </GoogleRecaptchaWrapper>
                </IsLogged>
              }
            />
            <Route path='' element={<Layout />}>
              <Route path='locations'>
                <Route path='create' element={<CreateLocation />} />
                <Route path=':id' element={<CreateLocation />} />
                <Route path='admins/create' element={<AddLocationAdmin />} />
                <Route index element={<Locations />} />
              </Route>
              <Route path='subscriptions' element={<Subscriptions />} />
              <Route path='promotions'>
                <Route path='preview/:id' element={<PromotionPreview />} />
                <Route path='preview' element={<PromotionPreview />} />
                <Route path='create' element={<CreatePromotion />} />
                <Route path=':id' element={<CreatePromotion />} />
                <Route index element={<Promotions />} />
              </Route>
              <Route path='profile' element={<MyProfile />} />
              <Route path='providers'>
                <Route path='create' element={<CreateProvider />} />
                <Route path=':id' element={<ProviderSettings />} />
              </Route>
              <Route path='create_admin' element={<AddAdmin />} />
              <Route path='admin/location/:id' element={<AdminLocationPreview />} />
              <Route path='admin/promotion/:id' element={<AdminPromotionPreview />} />
              <Route path='admin/provider-stats/:id' element={<AdminProviderInfoPreview />} />
              <Route path='admin/providers-list' element={<AdminProvidersList />} />
              <Route path='admin/providers-stats' element={<AdminProviderStats />} />
              <Route path='home/foodics-success' element={<FoodicsSuccess />} />
              <Route path='foodics-success' element={<FoodicsSuccess />} />
              <Route index element={<Navigate to='/locations' replace />} />
            </Route>
            <Route
              path='subscriptions/subscriptionCancelled'
              element={<UnkownRouteHandler to='/locations' />}
            />
            <Route
              path='subscriptions/subscriptionCreated'
              element={<UnkownRouteHandler to='/locations' />}
            />
          </Routes>
        </RTL>
      </ThemeProvider>
    </CacheProvider>
  ) : null
}

function Layout() {
  return (
    <div>
      <RequireAuth>
        <SubscriptionRedirect>
          <CheckProviderOrNavigateToCreate>
            <>
              <Header />
              <Outlet />
            </>
          </CheckProviderOrNavigateToCreate>
        </SubscriptionRedirect>
      </RequireAuth>
    </div>
  )
}
