import Box from '@mui/material/Box'
import { Avatar, Card, CardActions, CardContent, IconButton } from '@mui/material'
import { deepPurple } from '@mui/material/colors'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import React, { FC } from 'react'
import { FoodicsUser } from 'src/models/foodics'
import LinkOffIcon from '@mui/icons-material/LinkOff'

interface FoodicsUserCardProps {
  foodicsUserInfo?: FoodicsUser
}

export const FoodicsUserCard: FC<FoodicsUserCardProps> = ({ foodicsUserInfo }) => {
  return (
    <Card variant='outlined'>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <Avatar sx={{ bgcolor: deepPurple[500], color: '#fff' }}>F</Avatar>

            <Box sx={{ display: 'flex', alignItems: 'start', flexDirection: 'column', ml: 2 }}>
              <Typography>
                {foodicsUserInfo?.name} {foodicsUserInfo?.email && `(${foodicsUserInfo?.email})`}
              </Typography>
              {foodicsUserInfo?.businessName && (
                <Tooltip
                  title={
                    foodicsUserInfo?.businessReference
                      ? 'Business Reference #' + foodicsUserInfo?.businessReference
                      : ''
                  }
                >
                  <Typography>Business: {foodicsUserInfo?.businessName}</Typography>
                </Tooltip>
              )}
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          {/*<IconButton aria-label='Unlink foodics account'>
            <LinkOffIcon />
          </IconButton>*/}
        </CardActions>
      </Box>
    </Card>
  )
}
