export type Locale = 'en' | 'ar'

export const toIETFLanguageTag = (locale: Locale): string => {
  return locale
}

export const fromString = (l: string): Locale => {
  switch (l) {
    case 'en': {
      return 'en'
    }
    case 'ar': {
      return 'ar'
    }
    default: {
      return 'en'
    }
  }
}
