import React from 'react'
import { makeStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { Theme } from '@mui/material/styles'
import { createStyles } from '@mui/styles'
import Lottie from 'react-lottie'
import successAnimation from './successAnimation.json'
import cancelAnimation from './cancelAnimation.json'

import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const animationOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 320, // Adjust the width as needed
      padding: theme.spacing(4) // Adjust the padding as needed
    },
    animation: {
      width: '80%',
      marginBottom: theme.spacing(2)
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%', // Make the container full width
      marginTop: theme.spacing(2)
    },
    button: {
      marginRight: theme.spacing(5)
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '1.2rem' // Adjust the font size as needed
    }
  })
)

interface SubscriptionSuccessModalProps {
  type: 'success' | 'cancel' | false
  onClose: () => void
}

const contentData = {
  success: {
    title: 'modal_success_title',
    subtitle: 'modal_success_subtitle',
    animation: {
      animationData: successAnimation,
      ...animationOptions
    }
  },
  cancel: {
    title: 'modal_cancel_title',
    subtitle: 'modal_cancel_subtitle',
    animation: {
      animationData: cancelAnimation,
      ...animationOptions
    }
  }
}

const SubscriptionSuccessModal: React.FC<SubscriptionSuccessModalProps> = ({ type, onClose }) => {
  const classes = useStyles()
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions_modal' })

  //@ts-ignore
  const data = contentData[type]
  return (
    <Dialog open={!!type} onClose={onClose}>
      <DialogTitle align='center'>{t(data?.title)}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {data?.animation ? <Lottie options={data?.animation} height={200} width={200} /> : null}
        <Typography variant='subtitle1' className={classes.subtitle}>
          {t(data?.subtitle)}
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            variant='contained'
            color='primary'
            onClick={onClose}
            className={classes.button}
            component={RouterLink}
            to='/subscriptions'
          >
            {t('btn_go_to_subscriptions')}
          </Button>
          <Button variant='outlined' color='primary' className={classes.button} onClick={onClose}>
            {t('btn_cancel')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SubscriptionSuccessModal
