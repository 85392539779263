import axios, { AxiosInstance } from 'axios'

export type SessionAuthentication = {
  token?: string
}

export interface APIClientConfig {
  disableLogging?: boolean
}

export default class AxiosClient {
  private baseURL: string
  private config: APIClientConfig
  private _onAuthenticationLost?: Function
  axios: AxiosInstance
  authentication?: SessionAuthentication

  constructor(baseURL: string, config: APIClientConfig) {
    this.baseURL = baseURL
    if (this.baseURL[this.baseURL.length - 1] !== '/') {
      this.baseURL += '/'
    }

    this.config = config

    this.axios = axios.create({
      baseURL: this.baseURL
    })

    this.axios.interceptors.request.use(config => {
      if (this.authentication) {
        if (this.authentication.token) {
          //@ts-ignore
          config.headers['Authorization'] = 'Bearer ' + this.authentication.token
        }
      }
      config.responseType = 'json'

      if (!this.config.disableLogging) {
        console.log(`(REQUEST) ${config.method} ${config.url}`, config.headers, config.data)
      }

      return config
    })

    this.axios.interceptors.response.use(
      response => {
        if (!this.config.disableLogging) {
          console.log(
            `(RESPONSE)  ${response.config.method} ${this.baseURL}${response.config.url}`,
            response.headers,
            response.data
          )
        }

        const handleAPIVersion = (headerName: string) => {
          if (response.headers?.[headerName]) {
            const apiVersion = response.headers[headerName]
            const apiVersionFromLocalStorage = localStorage.getItem(headerName)

            if (apiVersionFromLocalStorage) {
              if (apiVersion !== apiVersionFromLocalStorage) {
                console.log(`API version changed: ${headerName} ${apiVersionFromLocalStorage} -> ${apiVersion}`)

                localStorage.setItem(headerName, apiVersion)

                window.location.reload()
              }
            } else {
              console.log(`API version saved: ${headerName} -> ${apiVersion}`)

              localStorage.setItem(headerName, apiVersion)
            }
          }
        }

        handleAPIVersion('x-thehub-api-version')
        handleAPIVersion('x-providers-api-version')

        return response
      },
      error => {
        const { config, response } = error

        if (!response) {
          return Promise.reject(
            Object.assign(new Error('Check your internet connection and try again.'))
          )
        }

        if (!this.config.disableLogging) {
          console.log(error)
          console.log(`(ERROR) ${config.method} ${config.url}`, response.data)
        }

        if (response.data.code === 'auth/invalidSession' && this._onAuthenticationLost) {
          this._onAuthenticationLost()
        }

        if (response.data.code === 'common/invalidParameters') {
          if (response.data.details?.invalidParameters?.[0]) {
            const invalidParameter = response.data.details.invalidParameters[0]

            return Promise.reject(
              Object.assign(new Error(`${invalidParameter.name} ${invalidParameter.message}`))
            )
          }

          return Promise.reject(Object.assign(new Error('Validation failed')))
        }

        return Promise.reject(
          response && response.data && response.data.message && response.data.code
            ? Object.assign(new Error(response.data.message), {
                isAlreadyRegistered: response.data.code === 'auth/phoneNumberAlreadyRegistered',
                errorCode: response.data.code
              })
            : error.message === 'Network Error'
            ? new Error('Network problems. Please check your Internet connection and try again.')
            : response.data
        )
      }
    )
  }
}
