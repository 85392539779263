import { call, take, select, cancelled, put, fork, cancel } from 'redux-saga/effects'
import { SagaIterator, Task } from 'redux-saga'
import { Action } from 'typescript-fsa'
import { State } from 'src/models'
import { mediaUploadUpdate } from './actions'
import { mediaUploads } from './selectors'
import remove from 'lodash/remove'
import { MediaUpload } from './types'
import { UploadType } from 'src/models/uploads'
import { Upload } from 'src/models/uploads'
import { API, fileUploader } from 'src/data'

const MAX_SIMULTANEOUS_UPLOADS = 10

interface UploadTask {
  task: Task
  uid: number
  file?: File
}

export default function createRootSaga() {
  function* prepareAndUploadMedia(mediaUpload: MediaUpload, type: UploadType) {
    try {
      const uploadMedia: Upload = yield call(
        [API, API.createUpload],
        type,
        mediaUpload.file.type,
        `.${mediaUpload.file.name.split('.').pop()}`
      )

      yield put(
        mediaUploadUpdate(
          (mediaUpload = {
            ...mediaUpload,
            state: 'uploading'
          })
        )
      )

      yield call(fileUploader.upload, mediaUpload.file, uploadMedia.url, {
        'Content-Type': mediaUpload.file.type
      })
      yield put(
        mediaUploadUpdate(
          (mediaUpload = {
            ...mediaUpload,
            state: 'ready',
            uploadHandle: uploadMedia.handle
          })
        )
      )
    } catch (error) {
      mediaUploadUpdate({
        ...mediaUpload,
        state: 'error',
        error:
          typeof error === 'string'
            ? error
            : typeof (error as Error).message === 'string'
            ? (error as Error).message
            : 'Upload failed'
      })
    } finally {
      //@ts-ignore
      if (yield cancelled()) {
        console.log('Upload cancelled', mediaUpload)
      }
    }
  }

  function* manageMediaUploads() {
    let runningUploads: UploadTask[] = []
    let prevMediaUploads: MediaUpload[] | undefined

    while (true) {
      yield take('*')

      const nextMediaUploads: MediaUpload[] = yield select(mediaUploads)

      if (nextMediaUploads !== prevMediaUploads) {
        // Stop cancelled uploads
        const uploadsToCancel = remove(
          runningUploads,
          upload =>
            !nextMediaUploads.find(
              nextUpload => nextUpload.uid === upload.uid && nextUpload.file === upload.file
            )
        )
        for (let upload of uploadsToCancel) {
          yield cancel(upload.task)
        }

        // Clean up running uploads that were completed
        const completedUploads = remove(
          runningUploads,
          upload =>
            nextMediaUploads.find(
              nextUpload => nextUpload.uid === upload.uid && nextUpload.file === upload.file
            )?.state === 'ready'
        )

        // Launch more uploads
        const queuedUploads = nextMediaUploads.filter(nextUpload => nextUpload.state === 'queued')

        while (runningUploads.length < MAX_SIMULTANEOUS_UPLOADS && queuedUploads.length) {
          const next = queuedUploads.shift()!
          //@ts-ignore
          const task = yield fork(prepareAndUploadMedia, next, next.uploadType)

          runningUploads.push({
            task,
            uid: next.uid,
            file: next.file
          })
        }
      }

      prevMediaUploads = nextMediaUploads
    }
  }

  return [call(manageMediaUploads)]
}
