import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { googleRecaptchaSecretKey } from 'src/config/index'
import { FC, ReactNode } from 'react'

interface GoogleRecaptchaWrapperProps {
  children: ReactNode
}

export const GoogleRecaptchaWrapper: FC<GoogleRecaptchaWrapperProps> = ({ children }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={googleRecaptchaSecretKey}>
      {children}
    </GoogleReCaptchaProvider>
  )
}
