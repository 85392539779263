import { error } from './../providerLocations/selectors'
import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { updateDraft, edit, createLocation as create, dismissError } from './actions'

// Types imports
import { StateCreateLocation } from './types'

const initialState: StateCreateLocation = {
  drafts: {}
}

export function createLocation(
  state: StateCreateLocation = initialState,
  action: Action
): StateCreateLocation {
  if (isType(action, create.started)) {
    return {
      ...state,
      isCreating: true,
      error: undefined
    }
  }

  if (isType(action, create.done)) {
    return {
      ...state,
      drafts: {},
      isCreating: false
    }
  }

  if (isType(action, create.failed)) {
    return {
      ...state,
      isCreating: false,
      error: action.payload.error
    }
  }

  if (isType(action, updateDraft)) {
    const id = action.payload?.id || 'new'

    return {
      ...state,
      drafts: {
        ...state.drafts,
        [id]: {
          ...(state.drafts[id] || {}),
          ...action.payload
        }
      }
    }
  }

  if (isType(action, edit)) {
    const location = action.payload
    return {
      ...state,
      //@ts-ignore
      drafts: {
        ...state.drafts,
        [location.id!]: location
      }
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  return state
}
