import { useEffect, useState } from 'react'
// @mui
import { styled } from '@mui/material/styles'
import { Box, Button, Card, CircularProgress, FormLabel, Stack, Typography } from '@mui/material'
// components
import Counter from './Counter'
import { SubscriptionPlan } from 'src/models/subscriptions'
import i18next from 'i18next'
import PlanPremiumIcon from './Icon'
import { useDispatch, useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import Discount from './Discount'

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3),
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.1)'
  },
  [theme.breakpoints.up(414)]: {
    padding: theme.spacing(5)
  }
}))

const useStyles = makeStyles(theme => ({
  box: {
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.1)'
    }
  }
}))

// ----------------------------------------------------------------------

type Props = {
  card: SubscriptionPlan
  index: number
}

export default function PricingPlanCard({ card, index }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation')

  const isCreating = useSelector(subscriptionsStore.selectors.isCreating)

  const [count, setCount] = useState(1)

  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    !isCreating && setProcessing(false)
  }, [isCreating])

  const onSubmit = (startTrial: boolean) => () => {
    setProcessing(true)
    dispatch(
      subscriptionsStore.actions.createSubscription.started({
        subscriptionPlanId: card.id,
        startTrial: startTrial,
        locationsCount: count
      })
    )
  }
  const { i18n } = card
  const strings = i18n?.[i18next?.language] || i18n?.['en']

  const { intervalCount, interval } = card.recurring

  return (
    <RootStyle>
      <Typography variant='overline' sx={{ color: 'text.secondary', fontWeight: 600 }}>
        {t(strings?.title)}
      </Typography>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
        <Typography variant='subtitle1' sx={{ color: 'text.secondary' }}>
          $
        </Typography>
        <Typography variant='h2' sx={{ mx: 1 }}>
          {card.price}
        </Typography>

        <Typography
          gutterBottom
          component='span'
          variant='subtitle2'
          sx={{
            alignSelf: 'flex-end',
            color: 'text.secondary'
          }}
        >
          {'/ ' + t(`subscription_plan.price_${interval}`, { intervalCount })}
        </Typography>
      </Box>
      <Discount data={card} />
      <Typography
        variant='subtitle1'
        textAlign={'center'}
        sx={{
          mb: 2,
          fontWeight: 500,
          color: 'primary.main'
        }}
      >
        {t(strings?.description)}
      </Typography>

      {/*lists?.length ? (
        <Stack component='ul' spacing={2} sx={{ my: 5, width: 1 }}>
          {lists?.map(item => (
            <Stack
              key={item.text}
              component='li'
              direction='row'
              alignItems='center'
              spacing={1.5}
              sx={{
                typography: 'body2',
                color: item.isAvailable ? 'text.primary' : 'text.disabled'
              }}
            >
              <DoneIcon sx={{ width: 20, height: 20 }} />
              <Typography variant='body2'>{item.text}</Typography>
            </Stack>
          ))}
        </Stack>
      ) : null
            */}

      <>
        <FormLabel id='location_label'>{t('subscription_plan.number_of_locations')}</FormLabel>
        <Counter value={count} onChange={setCount} />
      </>
      <Button
        fullWidth
        size='large'
        onClick={onSubmit(true)}
        disabled={processing}
        variant='contained'
      >
        {processing ? (
          <CircularProgress size={24} color='inherit' />
        ) : (
          t('subscription_plan.btn_subscribe', {
            price: `$${(+card.price * count).toFixed(2)}`
          })
        )}
      </Button>
    </RootStyle>
  )
}
