import React, { FC } from 'react'
import { Avatar } from '@mui/material'

interface FoodicsStatusIconProps {
  children: React.ReactNode
  bgcolor: string
}

export const FoodicsStatusIcon: FC<FoodicsStatusIconProps> = ({ children, bgcolor }) => {
  return (
    <Avatar sx={{ bgcolor: bgcolor, color: 'white', width: 100, height: 100 }}>{children}</Avatar>
  )
}
