import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PhoneNumberInput from '../../components/PhoneNumberInput'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import RoundedButton from '../../components/RoundedButton'
import LanguagePicker from 'src/components/LanguagePicker'
import Typography from '@mui/material/Typography'
import authStore from 'src/store/auth'
import usePrevious from 'src/hooks/usePrevious'
import { useTranslation } from 'react-i18next'
import logo from '../../assets/logo.svg'
import { useSnackbar } from 'notistack'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CountryCode, parsePhoneNumber } from 'libphonenumber-js'
import { OTPMethods } from 'src/models/auth'
import { CircularProgress } from '@mui/material'

interface LoginProps {
  // This stuff allows us to use the same form for login/register and pass translation key
  // so that we can write Register/Login on submit button by default it's btn_login
  submitButtonTranslateKey?: string
}

function Login({ submitButtonTranslateKey = 'btn_login' }: LoginProps) {
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const [phoneCountryCode, setPhoneCountryCode] = useState<CountryCode | undefined>()

  const { t } = useTranslation('translation', { keyPrefix: 'login_page' })
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const error = useSelector(authStore.selectors.error)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)
  const signInData = useSelector(authStore.selectors.signInData)
  const isFetchingCountriesOTPRequestMethods = useSelector(
    authStore.selectors.countriesOTPRequestMethodsList
  )
  const countriesOTPRequestMethodsList = useSelector(
    authStore.selectors.countriesOTPRequestMethodsList
  )
  const isSending = useSelector(authStore.selectors.isSendingPhoneVerification)

  const prevIsSending = usePrevious(isSending)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha in not yet available')
      return
    }

    const token = await executeRecaptcha('sendOTP')

    setCaptchaToken(token)
  }, [executeRecaptcha])

  useEffect(() => {
    dispatch(authStore.actions.resetLimits())

    dispatch(authStore.actions.getCountriesOTPRequestMethodsList.started())
  }, [])

  useEffect(() => {
    if (!!prevIsSending !== isSending && !isSending && !error) {
      navigate('/password')
    }
  }, [isSending])

  const handleChangePhone = (phoneNumber: string) => {
    dispatch(
      authStore.actions.updateSignInData({
        phoneNumber
      })
    )

    const parsedPhoneNumber = parsePhoneNumber('+' + phoneNumber)

    console.log('parsedPhoneNumber', parsedPhoneNumber)

    setPhoneCountryCode(parsedPhoneNumber.country)
  }

  // Get allowed OTP methods for entered phone number
  useEffect(() => {
    if (!phoneCountryCode || !countriesOTPRequestMethodsList) {
      return
    }

    const allowedOTPMethods = countriesOTPRequestMethodsList.find(
      country => country.countryCode === phoneCountryCode.toLowerCase()
    )

    if (allowedOTPMethods?.methods) {
      dispatch(authStore.actions.allowedOTPMethodsChange(allowedOTPMethods.methods))
    }
  }, [phoneCountryCode, countriesOTPRequestMethodsList])

  const handleSubmit = () => {
    if (!signInData?.phoneNumber) return

    if (!allowedOTPMethods) {
      enqueueSnackbar('No allowed OTP methods for your country', {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }

    if (allowedOTPMethods?.includes('sms')) {
      handleSignIn()
    } else if (allowedOTPMethods?.includes('sms_with_captcha')) {
      handleReCaptchaVerify()
    } else if (allowedOTPMethods?.includes('whatsapp')) {
      handleSignIn()
    }
  }

  // When got captcha token request OTP
  useEffect(() => {
    if (!captchaToken) {
      return
    }

    handleSignIn()
  }, [captchaToken])

  const handleSignIn = () => {
    let messenger: OTPMethods | undefined

    if (allowedOTPMethods?.includes('sms')) {
      messenger = 'sms'
    } else if (allowedOTPMethods?.includes('sms_with_captcha') && captchaToken) {
      messenger = 'sms'
    } else if (allowedOTPMethods?.includes('whatsapp')) {
      messenger = 'whatsapp'
    }

    if (!messenger) {
      enqueueSnackbar('No allowed OTP methods for your country', {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
      return
    }

    dispatch(
      authStore.actions.sendPhoneVerification.started({
        operation: 'signIn',
        recaptcha: captchaToken,
        messenger
      })
    )
  }

  // Process errors
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  const handleBack = () => {
    navigate('/')
  }

  return (
    <div className='full-page full-page-gray-bg full-page-center'>
      <div style={{ position: 'absolute', right: 50, top: 50 }}>
        <LanguagePicker />
      </div>
      <Container
        component='main'
        maxWidth='xs'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'white',
          flexDirection: 'column',
          padding: '32px',
          borderRadius: '16px',
          boxShadow: 3
        }}
      >
        <img src={logo} alt='Logo' />
        <Grid container justifyContent='flex-start'>
          <Typography
            component='h6'
            align='left'
            color='text.secondary'
            sx={{
              fontWeight: 500,
              mt: 3,
              mb: 1
            }}
          >
            {t('input_label')}
          </Typography>
          <PhoneNumberInput
            defaultValue={signInData?.phoneNumber}
            onChangePhone={handleChangePhone}
            onEnterKeyPress={handleSubmit}
          />
        </Grid>
        <RoundedButton
          fullWidth
          sx={{ mt: 3 }}
          variant='contained'
          onClick={handleSubmit}
          disabled={!signInData?.phoneNumber || !isFetchingCountriesOTPRequestMethods || isSending}
        >
          {isSending ? (
            <CircularProgress size={18} sx={{ mr: 1, display: isSending ? 'block' : 'none' }} />
          ) : (
            <></>
          )}{' '}
          {t(submitButtonTranslateKey)}
        </RoundedButton>
        <RoundedButton variant='text' fullWidth sx={{ mt: 1, mb: 0 }} onClick={handleBack}>
          {t('btn_back')}
        </RoundedButton>
      </Container>
    </div>
  )
}
export default React.memo(Login)
