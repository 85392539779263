import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import SubscriptionModal from './SubscriptionModal'

interface Props {
  children: JSX.Element
}

export type ModalType = 'success' | 'cancel' | false
const Wrapper: React.FC<Props> = ({ children }) => {
  const location = useLocation()
  const [showModal, setSuccessModal] = useState<ModalType>(false)

  useEffect(() => {
    //@ts-ignore
    if (location.state?.prevRoute?.pathname?.includes('subscriptions/subscriptionCreated')) {
      setSuccessModal('success')
    }
    //@ts-ignore
    if (location.state?.prevRoute?.pathname?.includes('subscriptions/subscriptionCancelled')) {
      setSuccessModal('cancel')
    }
  }, [])

  const handleCloseModal = () => {
    setSuccessModal(false)
  }

  return (
    <div>
      {children}
      <SubscriptionModal type={showModal} onClose={handleCloseModal} />
    </div>
  )
}

export default Wrapper
