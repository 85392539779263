import React, { useEffect, useState } from 'react'
import { Box, CircularProgress } from '@mui/material'

export const Progress = () => {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      minHeight='100vh' // Adjust the height as needed
    >
      <CircularProgress />
    </Box>
  )
}
