import * as React from 'react'
import { Fade, CircularProgress, Modal } from '@mui/material'

interface LoadingProps {
  loading?: boolean
}

const FullScreenSpinner = (props: LoadingProps) => {
  const spinnerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 0,
    height: '100vh'
  }

  return (
    <Modal open={!!props.loading}>
      <Fade in={props.loading}>
        <div style={spinnerStyle}>
          <CircularProgress color={'primary'} />
        </div>
      </Fade>
    </Modal>
  )
}

export default FullScreenSpinner
