import { PromotionId, Promotion } from 'src/models/promotions'

import { State } from 'src/models'
import { createSelector } from 'reselect'

export const locations = (state: State) => state.providerLocations.cache
export const selectedLocationId = (state: State) => state.promotions.selectedLocationId
export const selectedLocation = createSelector(selectedLocationId, locations, (id, list) =>
  id ? list[id] : Object.values(list)?.[0]
)
const promotionsCache = (state: State) => state.promotions.cache

export const list = createSelector(promotionsCache, selectedLocationId, (list, key) => {
  if (key === 'all') {
    const all = Object.values(list || {})
    //@ts-ignore
    return all.reduce((acc, item) => acc.concat(Object.values(item)), [])
  }
  return Object.values(list?.[key!] || {})
})

export const isFetching = (state: State) => state.promotions.isFetching
export const isRemoving = (state: State) => state.promotions.isRemoving
export const error = (state: State) => state.promotions.error
export const promotionById = (id: PromotionId) =>
  createSelector(
    promotionsCache,
    selectedLocationId,
    (list, key) => list?.[key!]?.[id] || Object.values(list || {}).find(item => item[id])?.[id]
  )
