import React from 'react'
import { makeStyles } from '@mui/styles'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import icon from 'src/assets/check.svg'

import { DraftDiscountSchedule } from 'src/store/createPromotion/types'
import { isArray } from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    flexDirection: 'row',
    border: '1px solid rgba(41, 56, 69, 0.3)'
  },
  activeDay: {
    color: 'green'
  },
  closed: {
    color: '#1c2025'
  }
}))

const WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

const formatScheduleTime = (time: string | undefined) => {
  if (time !== undefined && time !== null) {
    if (time.toString().indexOf(':') === -1) {
      if (time.toString().indexOf('.') !== -1) {
        const timeDecomposed = time.toString().split('.')
        const [hours, minutes] = timeDecomposed

        return `${hours}:${minutes}`
      } else {
        time = time.toString() + ':00'
      }
    }

    return time
  }
}

function WeekHours({ discountSchedule, discountAvailableAtAnyTime }: any) {
  const classes = useStyles()

  const renderDays = () => {
    return WEEK.map((item, index) => {
      const day: DraftDiscountSchedule = isArray(discountSchedule)
        ? discountSchedule.find(day => day.dayOfTheWeek === item)
        : discountSchedule[item]

      return (
        <Box
          key={index}
          borderRight={index < 6 ? 1 : 0}
          borderColor='rgba(41, 56, 69, 0.3)'
          sx={{
            flex: 1
          }}
        >
          <Box bgcolor={'rgba(41, 56, 69, 0.1)'} padding={1}>
            <Typography variant='body1' align='center'>
              <strong>{item.charAt(0).toUpperCase()}</strong>
            </Typography>
          </Box>
          <Box minHeight={'40px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {discountAvailableAtAnyTime ? (
              <img src={icon} />
            ) : (
              <Box>
                <Typography
                  align='center'
                  sx={{ color: '#39A1AB', fontWeight: '600', fontSize: '11px' }}
                >
                  {day?.isSelected || isArray(discountSchedule)
                    ? formatScheduleTime(day?.startTime)
                    : ''}
                </Typography>
                <Typography
                  align='center'
                  variant='body1'
                  sx={{ color: '#39A1AB', fontWeight: '600', fontSize: '11px' }}
                >
                  {day?.isSelected || isArray(discountSchedule)
                    ? formatScheduleTime(day?.endTime)
                    : ''}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )
    })
  }

  return (
    <Grid container xs={10} mt={2} className={classes.root}>
      {renderDays()}
    </Grid>
  )
}

export default WeekHours
