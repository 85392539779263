import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Fab from '@mui/material/Fab'

import { useNavigate } from 'react-router-dom'
import { Spinner } from 'src/components'

import locationAdminsStore from 'src/store/locationAdmins'

import { useTranslation } from 'react-i18next'
import { Admin, ProviderAdminCreate } from 'src/models/admins'
import { ProviderLocationId } from 'src/models/providerLocations'
import { State } from 'src/models'
import ButtonRemoveWithPrompt from 'src/components/ButtonRemoveWithPrompt'
import { Hidden } from '@mui/material'

interface Props {
  locationId: ProviderLocationId | undefined
}

export default function LocationAdminsList({ locationId }: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'admins_list' })

  useEffect(() => {
    if (locationId) {
      dispatch(locationAdminsStore.actions.fetch.started(locationId))
      dispatch(locationAdminsStore.actions.clearDraft())
    }
  }, [locationId]) // eslint-disable-line react-hooks/exhaustive-deps

  const drafts = useSelector(locationAdminsStore.selectors.drafts)
  const admins = useSelector((state: State) =>
    locationAdminsStore.selectors.list(state, locationId)
  )

  const isRemoving = useSelector(locationAdminsStore.selectors.isRemoving)
  const isFetching = useSelector(locationAdminsStore.selectors.isFetching)

  const locationAdmins = [...admins, ...drafts]

  const renderText = (title: string, mt?: number) => (
    <Typography
      component='h6'
      align='left'
      mt={mt || 0}
      color={'text.secondary'}
      sx={{
        fontWeight: 500
      }}
    >
      {title}
    </Typography>
  )

  const navigateToAddAdmin = () =>
    navigate('/locations/admins/create', { state: { locationId } })

  const remove = (admin: Admin | ProviderAdminCreate) => () => {
    dispatch(
      locationAdminsStore.actions.remove.started({
        locationId,
        admin
      })
    )
  }

  const renderItem = (item: Admin | ProviderAdminCreate, hideButton?: boolean) => {
    return (
      <Box
        key={(item as Admin).id || item.phoneNumber}
        sx={{
          display: 'flex',
          mt: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexGrow: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'flex-start', sm: 'space-between' },
            alignItems: { xs: 'flex-start', sm: 'center' }
          }}
        >
          <Box>
            <Typography
              component='h6'
              sx={{
                color: '#293845',
                fontWeight: 500
              }}
            >
              {item.name}
            </Typography>
          </Box>
          <Box>
            <Typography
              component='h6'
              sx={{
                color: '#293845',
                fontWeight: 500
              }}
            >
              {'+' + item.phoneNumber}
            </Typography>
          </Box>
        </Box>
        {!hideButton ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              minWidth: { xs: '65px', sm: '160px' },
              width: { xs: '65px', sm: '160px' }
            }}
          >
            {!isRemoving ? (
              <ButtonRemoveWithPrompt
                btnText={t('btn_remove')}
                description={t('popup_removal_text').replace('%admin_name%', item.name)}
                onRemove={remove(item)}
              />
            ) : (
              <Spinner />
            )}
          </Box>
        ) : null}
      </Box>
    )
  }

  return (
    <Grid item mt={1} xs={12}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        mb={2}
      >
        {renderText(t('section_title_providers'))}

        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ cursor: 'pointer' }}
          onClick={navigateToAddAdmin}
        >
          <Hidden smDown>{t('btn_add_admin')}</Hidden>
          <Fab size='medium' sx={{ ml: 3 }} color='primary' aria-label='add'>
            <AddIcon />
          </Fab>
        </Box>
      </Box>

      {!isFetching && locationAdmins.length ? (
        locationAdmins.map(item => renderItem(item))
      ) : (
        <Typography
          component='h6'
          align='center'
          mt={2}
          mb={2}
          color={'text.secondary'}
          sx={{
            fontWeight: 400
          }}
        >
          {t('text_empty_list')}
        </Typography>
      )}
    </Grid>
  )
}
