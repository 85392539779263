import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { fetchFoodicsUserInfo, dismissError, fetchFoodicsAuthCallback } from './actions'

// Types imports
import { StateFoodics } from './types'

const initialState: StateFoodics = {}

export function foodics(state: StateFoodics = initialState, action: Action): StateFoodics {
  if (isType(action, fetchFoodicsUserInfo.started)) {
    return {
      ...state,
      error: undefined,
      isFetching: true
    }
  }

  if (isType(action, fetchFoodicsUserInfo.done)) {
    const { result } = action.payload

    return {
      ...state,
      foodicsUserInfo: result,
      isFetching: false
    }
  }

  if (isType(action, fetchFoodicsUserInfo.failed)) {
    return {
      ...state,
      error: action.payload.error,
      isFetching: false
    }
  }

  if (isType(action, fetchFoodicsAuthCallback.started)) {
    return {
      ...state,
      error: undefined,
      isFetching: true
    }
  }

  if (isType(action, fetchFoodicsAuthCallback.done)) {
    return {
      ...state,
      isFetching: false
    }
  }

  if (isType(action, fetchFoodicsAuthCallback.failed)) {
    return {
      ...state,
      error: action.payload.error,
      isFetching: false
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  return state
}
