import { auth } from './auth/reducer'
import { createLocation } from './createLocation/reducer'
import { providerLocations } from './providerLocations/reducer'
import { createPromotion } from './createPromotion/reducer'
import { promotions } from './promotions/reducer'
import { admins } from './admins/reducer'
import { locationAdmins } from './locationAdmins/reducer'
import { remoteConfig } from './remoteConfig/reducer'
import { mediaUploads } from './mediaUploads/reducer'
import { foodics } from './foodics/reducer'
import { subscriptions } from './subscriptions/reducer'
import { adminPreview } from './adminPreview/reducer'

import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistPromotionsConfig = {
  key: 'promotions_v2',
  blacklist: ['error'],
  storage
}

const persistLocationConfig = {
  key: 'providerLocations_v1',
  blacklist: ['error'],
  storage
}

const persistCreateLocationConfig = {
  key: 'createProviderLocation_v4',
  storage,
  blacklist: ['error', 'isCreating']
}

const persistCreatePromotionConfig = {
  key: 'createPromotion_v5',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistAdminsConfig = {
  key: 'admins',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistLocationAdminsConfig = {
  key: 'locationAdmins-v1',
  blacklist: ['error', 'isCreating'],
  storage
}

const persistSubscriptionsConfig = {
  key: 'subscriptions-v4',
  blacklist: [],
  storage
}

export default Object.assign({
  remoteConfig,
  auth,
  createLocation: persistReducer(persistCreateLocationConfig, createLocation),
  providerLocations: persistReducer(persistLocationConfig, providerLocations),
  createPromotion: persistReducer(persistCreatePromotionConfig, createPromotion),
  promotions: persistReducer(persistPromotionsConfig, promotions),
  admins: persistReducer(persistAdminsConfig, admins),
  locationAdmins: persistReducer(persistLocationAdminsConfig, locationAdmins),
  mediaUploads,
  foodics,
  subscriptions: persistReducer(persistSubscriptionsConfig, subscriptions),
  adminPreview
})
