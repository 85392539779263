import React, { useMemo, useState } from 'react'
import { Position } from './Map'
import RoomIcon from '@mui/icons-material/Room'
import clsx from 'clsx'

import './styles.css'

export const Marker: React.FC<Position> = ({ lat, lng }) => {
  const [shouldAnimatePin, setShouldAnimatePin] = useState(true)

  useMemo(() => {
    setShouldAnimatePin(true)
  }, [lat, lng])

  const onPinAnimationEnd = () => {
    setShouldAnimatePin(false)
  }

  return (
    <>
      <RoomIcon
        className={clsx('pin', shouldAnimatePin ? 'pin-animate' : undefined)}
        onAnimationEnd={onPinAnimationEnd}
        sx={{ fontSize: 50 }}
      />
      <div className='pulse' />
    </>
  )
}
