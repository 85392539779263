import { call, delay, put } from 'redux-saga/effects'
import { firebaseRemoteConfig } from 'src/data/Firebase/RemoteConfig'

import { init18next, loadUserLocale } from '../../locales/languages'
import { RemoteConfig } from 'src/models/remoteConfig'
import { setStatus } from '../remoteConfig/actions'
import translations from '../../locales/translations.json'

import axios from 'axios'

export default function createRootSaga() {
  function* checkRemoteTranslationsForUpdate(config: RemoteConfig) {
    try {
      if (config.providerWebsiteTranslationsPackageUrl_2_1) {
        const { data } = yield call(axios.get, config.providerWebsiteTranslationsPackageUrl_2_1)
        if (translations._id !== data['_id']) {
          return data
        }
        return translations
      }
    } catch (e) {
      console.error(e)
      return translations
    }
  }

  function* initI18NSaga() {
    try {
      const data: RemoteConfig = yield firebaseRemoteConfig()
      const translation: string = yield call(checkRemoteTranslationsForUpdate, data)

      init18next(translation)
      loadUserLocale()

      yield put(setStatus())
    } catch (e) {
      console.log('Failed to get remote config', e)
    }
  }

  return [call(initI18NSaga)]
}
