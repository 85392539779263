import React, { useEffect, useState } from 'react'
import { Typography, Card, Grid } from '@mui/material'

interface InfoItemProps {
  icon: React.ReactNode
  label: string
  value: string
  link?: string
  email?: string
  phoneNumber?: string
}

const Item: React.FC<InfoItemProps> = ({ icon, value, label, link, email, phoneNumber }) => {
  const handleItemClick = () => {
    if (link) {
      window.open(link, '_blank')
    } else if (email) {
      window.location.href = `mailto:${email}`
    } else if (phoneNumber) {
      window.location.href = `tel:${phoneNumber}`
    }
  }

  return (
    <Grid
      container
      spacing={1}
      alignItems='center'
      onClick={handleItemClick}
      style={{ cursor: 'pointer' }}
    >
      <Grid item>{icon}</Grid>
      <Grid item>
        <Typography variant='body1'>{label}:</Typography>
      </Grid>
      <Grid item xs={12} md={6} ml={2} alignItems='center'>
        <Typography variant='body1' fontWeight='bold'>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default React.memo(Item)
