import { Action } from 'typescript-fsa'
import { SagaIterator } from 'redux-saga'

import { put, call, takeLatest } from 'redux-saga/effects'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { add, fetch, remove } from './actions'
import { API } from 'src/data'

export default function createRootSaga() {
  function* createAdminSaga({ payload }: Action<ProviderAdminCreate>): SagaIterator {
    try {
      const result = yield call([API, API.createAdmin], payload)
      yield put(add.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(add.failed({ params: payload, error }))
    }
  }

  function* fetchAdminsSaga(): SagaIterator {
    try {
      const result = yield call([API, API.readAdmins])
      yield put(fetch.done({ result: result.data }))
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ error }))
    }
  }

  function* removeSaga({ payload }: Action<Admin>): SagaIterator {
    try {
      const result = yield call([API, API.deleteAdmin], payload.id!)
      yield put(remove.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ params: payload, error }))
    }
  }

  return [
    takeLatest(fetch.started, fetchAdminsSaga),
    takeLatest(add.started, createAdminSaga),
    takeLatest(remove.started, removeSaga)
  ]
}
