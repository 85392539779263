import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import LocationItem from './LocationItem'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import providerLocations from 'src/store/providerLocations'
import { ProviderLocation, ProviderLocationId } from 'src/models/providerLocations'
import LinearProgress from 'src/components/LinearProgress'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { Container, Grid } from '@mui/material'

const LocationPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'locations_page' })

  const navigateToCreate = (id?: ProviderLocationId) =>
    navigate('/locations/create', { state: { id } })

  const list = useSelector(providerLocations.selectors.list)
  const isFetching = useSelector(providerLocations.selectors.isFetching)

  useEffect(() => {
    dispatch(providerLocations.actions.fetch.started())
  }, [])

  const onPressCreate = () => navigateToCreate()

  return (
    <div className='full-page full-page-gray-bg'>
      {isFetching ? <LinearProgress /> : null}
      {list.length ? (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box
            display={'flex'}
            sx={{ mr: { xs: 3, sm: 5 }, mt: 3, mb: 3, cursor: 'pointer' }}
            alignItems={'center'}
            onClick={onPressCreate}
          >
            <Typography component='h6' mr={2} color={'text.secondary'}>
              {t('btn_create')}
            </Typography>
            <Fab size='medium' color='primary' aria-label='add'>
              <AddIcon />
            </Fab>
          </Box>
        </Box>
      ) : null}
      <Container className='location-container'>
        <Grid container direction='row' spacing={3} sx={{ justifyContent: 'center' }}>
          {list?.length ? (
            list.map((item: ProviderLocation) => (
              <LocationItem {...item} key={item.id} navigateToCreate={navigateToCreate} />
            ))
          ) : (
            <Box mt={6} textAlign='center'>
              <Typography
                component='h6'
                align='center'
                mt={2}
                mb={2}
                color={'text.secondary'}
                sx={{
                  fontWeight: 400
                }}
              >
                {t('list_placeholder')}
              </Typography>
              <Button
                type='submit'
                variant='contained'
                sx={{ mt: 3, mb: 2, alignContent: 'center' }}
                onClick={onPressCreate}
              >
                {t('btn_create')}
              </Button>
            </Box>
          )}
        </Grid>
      </Container>
    </div>
  )
}

export default LocationPage
