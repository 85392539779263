import Typography from '@mui/material/Typography'
import { Button } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Provider } from 'src/models/auth'
import Box from '@mui/material/Box'
import { foodicsConnectUrl } from 'src/config/index'

interface FoodicsNotConnectedProps {
  currentProvider: Provider | null
}

export const FoodicsNotConnected: FC<FoodicsNotConnectedProps> = ({ currentProvider }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'foodics_settings' })

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',

        alignItems: 'center'
      }}
    >
      <Typography>{t('not_connected')}</Typography>
      <Button
        sx={{
          ml: 2
        }}
        variant='contained'
        // TODO: Use client id from env
        href={`${foodicsConnectUrl}&state=${currentProvider?.id}`}
        target='_blank'
        rel='noreferrer'
        disableElevation
      >
        {t('connect_foodics')}
      </Button>
    </Box>
  )
}
