import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'

import { ChangeEvent, useState } from 'react'

interface SearchBarProps {
  onChange: (value: string) => void
}

function SearchBar({ onChange }: SearchBarProps) {
  const [searchText, setSearchText] = useState<string>('')

  const onChangeText = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
    setSearchText(event.target.value)
  }

  const handleClearSearch = () => {
    onChange('')
    setSearchText('')
  }

  return (
    <TextField
      label='Search'
      placeholder='Enter the name or  provider ID'
      variant='outlined'
      fullWidth
      value={searchText}
      onChange={onChangeText}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: searchText && (
          <InputAdornment position='end'>
            <IconButton onClick={handleClearSearch} edge='end'>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default SearchBar
