import React, { useEffect, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import RoundedButton from 'src/components/RoundedButton'
import PhoneNumberInput from 'src/components/PhoneNumberInput'

import locationAdminsStore from 'src/store/locationAdmins'

export default function AddLocationAdmin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'add_admin' })

  const location = useLocation()
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const { enqueueSnackbar } = useSnackbar()
  const error = useSelector(locationAdminsStore.selectors.error)
  const isCreatingAdmin = useSelector(locationAdminsStore.selectors.isCreating)
  const [initialIsCreatingAdminValue, setInitialIsCreatingAdminValue] = useState<
    boolean | undefined
  >(undefined)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(locationAdminsStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    setInitialIsCreatingAdminValue(isCreatingAdmin)
  }, [])

  useEffect(() => {
    const navigationState = location?.state as { locationId: string | undefined }

    // Check if we are creating admin for existing location
    if (!navigationState.locationId) {
      return
    }

    if (initialIsCreatingAdminValue === undefined && !isCreatingAdmin) {
      return
    }

    if (error === undefined && !isCreatingAdmin) {
      enqueueSnackbar(t('admin_added_successfully'), {
        variant: 'success'
      })
      handleBack()
    }
  }, [isCreatingAdmin, error])

  const handleBack = () => {
    navigate(-1)
  }

  const handleNameInput = (value: ChangeEvent<HTMLInputElement>) =>
    setName(value.currentTarget.value)

  const save = () => {
    if (name.length === 0 || phoneNumber.length === 0) {
      return
    }

    const navigationState = location?.state as { locationId: string | undefined }

    // If we have locationId in location.state, then we are creating admin for existing promotion
    if (navigationState?.locationId !== undefined) {
      dispatch(
        locationAdminsStore.actions.add.started({
          locationId: navigationState.locationId,
          data: {
            phoneNumber,
            name
          }
        })
      )
    } else {
      // If we are creating admin for not existing location (while creating new location)
      // then we just add admin to our draft
      dispatch(
        locationAdminsStore.actions.addToDraft({
          phoneNumber,
          name
        })
      )
      handleBack()
      enqueueSnackbar(t('admin_added_successfully'), {
        variant: 'success'
      })
    }
  }

  const renderTitle = (title: string) => (
    <Typography
      component='h6'
      align='left'
      color={'text.secondary'}
      sx={{
        fontWeight: 400,
        mb: 1
      }}
    >
      {title}
    </Typography>
  )

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth='sm'
        sx={{ mt: 0, pt: 4, pb: 4, backgroundColor: '#f2f2f2' }}
      >
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography
            color={'text.secondary'}
            fontWeight={'500'}
            component='h3'
            variant='h4'
            align='left'
          >
            {t('add_location_admin')}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {renderTitle(t('input_admin_name') + ' *')}
                <OutlinedInput
                  autoComplete='given-name'
                  required
                  value={name}
                  fullWidth
                  onChange={handleNameInput}
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                {renderTitle(t('input_phone_number') + ' *')}
                <PhoneNumberInput
                  defaultValue={phoneNumber}
                  onChangePhone={setPhoneNumber}
                  onEnterKeyPress={save}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                type='submit'
                fullWidth
                disabled={!phoneNumber || !name}
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={save}
                label={t('btn_save')}
              />

              <RoundedButton
                type='submit'
                fullWidth
                variant='outlined'
                sx={{ mb: 2 }}
                onClick={handleBack}
                label={t('btn_cancel')}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}
