import React, { FC } from 'react'
import { blue, green, red } from '@mui/material/colors'
import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { FoodicsStatusIcon } from 'src/components/Foodics/FoodicsStatusIcon'

interface FoodicsStatusProps {
  isFetching?: boolean
  error?: Error
}

export const FoodicsStatus: FC<FoodicsStatusProps> = ({ isFetching, error }) => {
  if (isFetching) {
    return (
      <>
        <FoodicsStatusIcon bgcolor={blue[500]}>
          <CircularProgress sx={{ color: 'white' }} />
        </FoodicsStatusIcon>

        <Typography variant='h1' sx={{ mt: 2, textAlign: 'center' }}>
          Foodics Authorization Processing
        </Typography>
      </>
    )
  }

  if (error) {
    return (
      <>
        <FoodicsStatusIcon bgcolor={red[500]}>
          <CloseIcon sx={{ fontSize: 70 }} />
        </FoodicsStatusIcon>

        <Typography variant='h1' sx={{ mt: 2, textAlign: 'center' }}>
          Foodics Authorization Failed
        </Typography>
        <Typography variant='body1' sx={{ mt: 2, textAlign: 'center' }}>
          {error?.message}
        </Typography>
      </>
    )
  }

  return (
    <>
      <FoodicsStatusIcon bgcolor={green[500]}>
        <DoneIcon sx={{ fontSize: 70 }} />
      </FoodicsStatusIcon>

      <Typography variant='h1' sx={{ mt: 2, textAlign: 'center' }}>
        Foodics Authorization Success
      </Typography>
    </>
  )
}
