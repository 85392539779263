import React, { useState } from 'react'
import FilterListIcon from '@mui/icons-material/FilterList'
import Popover from '@mui/material/Popover'
import Button from '@mui/material/Button'
import Badge from '@mui/material/Badge'

import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import Checkbox from '@mui/material/Checkbox'

import { useDispatch, useSelector } from 'react-redux'
import adminPreviewStore from 'src/store/adminPreview'

const FilterPopup: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const filters = useSelector(adminPreviewStore.selectors.providerInfosListFilter)
  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      adminPreviewStore.actions.setProviderInfoListFilters({
        ...filters,
        [event.target.name]: event.target.checked
      })
    )

    dispatch(adminPreviewStore.actions.fetchProvidersInfo.started())
  }

  const handleTypeChangeCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      adminPreviewStore.actions.setProviderInfoListFilters({
        ...filters,
        [event.target.name]: event.target.value
      })
    )

    dispatch(adminPreviewStore.actions.fetchProvidersInfo.started())
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleReset = () => {
    dispatch(adminPreviewStore.actions.setProviderInfoListFilters({}))
    dispatch(adminPreviewStore.actions.fetchProvidersInfo.started())
  }

  const countOfFilters = Object.values(filters).filter(value => value === true).length
  return (
    <div>
      <Badge badgeContent={countOfFilters} color='secondary'>
        <Button aria-describedby={id} variant='contained' color='primary' onClick={handleClick}>
          <FilterListIcon />
        </Button>
      </Badge>
      {countOfFilters > 0 ? (
        <IconButton onClick={handleReset} color='primary' aria-label='reset'>
          <HighlightOffIcon />
        </IconButton>
      ) : null}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <FormControl component='fieldset' style={{ padding: '20px' }}>
          <FormLabel component='legend'>Filters</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.hasActiveSubscriptions}
                  onChange={handleTypeChange}
                  name='hasActiveSubscriptions'
                />
              }
              label='Has Active Subscriptions'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.hadActiveSubscriptions}
                  onChange={handleTypeChange}
                  name='hadActiveSubscriptions'
                />
              }
              label='Had Active Subscriptions'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.foodicsIsConnected}
                  onChange={handleTypeChange}
                  name='foodicsIsConnected'
                />
              }
              label='Foodics Is Connected'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.hasActivePromotions}
                  onChange={handleTypeChange}
                  name='hasActivePromotions'
                />
              }
              label='Has Active Promotions'
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.locationCreationIsFree}
                  onChange={handleTypeChange}
                  name='locationCreationIsFree'
                />
              }
              label='Location Creation Is Free'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={'US'}
                  checked={filters.subscriptionCountry === 'US'}
                  onChange={handleTypeChangeCountry}
                  name='subscriptionCountry'
                />
              }
              label='Subscription Country US'
            />
            <FormControlLabel
              control={
                <Checkbox
                  value={'other'}
                  checked={filters.subscriptionCountry === 'other'}
                  onChange={handleTypeChangeCountry}
                  name='subscriptionCountry'
                />
              }
              label='Subscription Country Other'
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </div>
  )
}

export default FilterPopup
