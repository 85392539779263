import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import { Spinner } from 'src/components'
import PromotionForm from './PromotionForm'
import createPromotion from 'src/store/createPromotion'
import promotionsStore from 'src/store/promotions'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Promotion, PromotionId } from 'src/models/promotions'
import usePrevious from 'src/hooks/usePrevious'
import { useSnackbar } from 'notistack'
import { State } from 'src/models'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import ButtonRemoveWithPrompt from 'src/components/ButtonRemoveWithPrompt'
import RoundedButton from 'src/components/RoundedButton'
import { useTranslation } from 'react-i18next'
import { ReviewStatusAlert } from 'src/components/Statuses/ReviewStatusAlert'
import { API } from 'src/data/index'
import { Alert, AlertTitle } from '@mui/material'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import authStore from 'src/store/auth'
import mediaUploadsStore from 'src/store/mediaUploads'
import { MediaFile } from 'src/models/uploads'

export default function CreatePromotion() {
  const user = useSelector(authStore.selectors.user)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation('translation', { keyPrefix: 'create_promotion' })

  const location = useLocation()
  const params = useParams()
  const promotionId: PromotionId = params?.id || (location?.state as any)?.id
  const isRemoving = useSelector(promotionsStore.selectors.isRemoving)
  const error = useSelector(promotionsStore.selectors.error)

  const isCreating = useSelector(createPromotion.selectors.isCreating)
  const createError = useSelector(createPromotion.selectors.error)
  const draft = useSelector((state: any) =>
    createPromotion.selectors.currentDraft(state, promotionId || 'new')
  )

  const promotionFromCache: Promotion = useSelector((state: State) =>
    promotionsStore.selectors.promotionById(promotionId!)(state)
  )

  const prevIsRemoving = usePrevious(isRemoving)
  const prevIsCreating = usePrevious(isCreating)

  const navigateToPreview = () =>
    navigate('/promotions/preview', { state: { promotionId, isEdit: true } })

  const save = () => {
    dispatch(createPromotion.actions.create.started(promotionId))
  }

  const fetchPromotionFromAPI = async () => {
    try {
      const promotion = await API.getPromotionById(promotionId)

      dispatch(createPromotion.actions.edit(promotion))
    } catch (error) {
      console.error('failed to get promotion from api', error)

      enqueueSnackbar('Failed to get promotion from API', {
        variant: 'error',
        onExited: () => dispatch(createPromotion.actions.dismissError())
      })

      navigate('/promotions')
    }
  }

  useEffect(() => {
    if (promotionId) {
      if (!draft) {
        if (promotionFromCache) {
          dispatch(createPromotion.actions.edit(promotionFromCache))
        } else {
          fetchPromotionFromAPI()
        }
      } else {
        //TO DO change logic for set images if draft exist
        draft?.images?.length && dispatch(mediaUploadsStore.actions.setImages(draft.images as any))
      }
    }
  }, [promotionFromCache])

  const handleBack = () => {
    navigate(-1)
    if (promotionFromCache) {
      dispatch(createPromotion.actions.edit(promotionFromCache))
    }
  }

  useEffect(
    () => () => {
      dispatch(mediaUploadsStore.actions.clearDraft())
    },
    []
  )

  const remove = () => {
    dispatch(
      promotionsStore.actions.remove.started({
        locationId: draft?.locationId || '',
        promotionId: promotionId!
      })
    )
  }

  useEffect(() => {
    if (!!isRemoving !== !!prevIsRemoving && !isRemoving && !error) {
      navigate('/promotions')
    }
  }, [isRemoving])

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !createError) {
      // Navigate to promotions page, after successfully editing or creating promotion
      navigate('/promotions')
      // Before it was done with
      // handleBack()
      // But it's working properly when we create new promotion
    }
  }, [prevIsCreating])

  useEffect(() => {
    if (createError || error) {
      enqueueSnackbar(createError?.message || error?.message, {
        variant: 'error',
        onExited: () => {
          dispatch(promotionsStore.actions.dismissError())
          dispatch(createPromotion.actions.dismissError())
        }
      })
    }
  }, [error, createError])

  if (promotionId && !draft) {
    return <Spinner loading={true} />
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth='sm'
        sx={{ mt: 0, pt: { xs: 0, sm: 4 }, pb: 4, backgroundColor: '#f2f2f2' }}
      >
        {promotionFromCache?.location?.ownerId !== user?.id &&
          user?.roles?.find(role => role.isRoot) && (
            <Alert
              icon={<AdminPanelSettingsIcon fontSize='inherit' />}
              sx={{ mb: 1 }}
              variant='filled'
              severity='info'
            >
              <AlertTitle>Admin Zone</AlertTitle>
              You are editing promotion with admin rights.
            </Alert>
          )}
        <ReviewStatusAlert promotion={promotionFromCache} />
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography
              component='h3'
              variant='h4'
              color={'text.secondary'}
              fontWeight={'500'}
              align='left'
            >
              {promotionId ? t('title_edit') : t('title_create')}
            </Typography>
            <IconButton
              sx={{
                position: 'relative',
                top: 4,
                left: -4
              }}
              onClick={handleBack}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <React.Fragment>
            <PromotionForm promotionId={promotionId} promotion={promotionFromCache} />
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={save}
                label={t('btn_save')}
              />

              <RoundedButton
                fullWidth
                disabled={
                  (!draft?.image && !draft?.location?.image && !draft?.locationId) ||
                  !draft?.title ||
                  !draft?.discountPercentage
                }
                variant='outlined'
                sx={{ mb: 2 }}
                onClick={navigateToPreview}
                label={t('btn_preview')}
              />
            </Box>
            <Grid
              container
              item
              xs={12}
              alignItems={'center'}
              justifyContent={'space-between'}
              flexDirection={'row'}
            >
              <Grid item xs={4} justifyContent={'center'}>
                <Button onClick={handleBack}>
                  <Typography
                    sx={{
                      fontFamily: 'Share',
                      fontWeight: 400,
                      fontSize: '16px',
                      color: 'rgba(41, 56, 69, 0.7)',
                      textTransform: 'none'
                    }}
                  >
                    {t('btn_cancel')}
                  </Typography>
                </Button>
              </Grid>
              {promotionId ? (
                <ButtonRemoveWithPrompt
                  btnText={t('btn_delete')}
                  description={t('popup_removal_text')}
                  onRemove={remove}
                />
              ) : null}
            </Grid>
          </React.Fragment>
        </Paper>
      </Container>
      <Spinner loading={isCreating} />
    </div>
  )
}
