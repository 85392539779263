import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import foodicsStore from 'src/store/foodics'
import { Button, Card, CardContent } from '@mui/material'
import Box from '@mui/material/Box'
import { FoodicsStatus } from 'src/components/Foodics/FoodicsStatus'

export const FoodicsSuccess = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isFetching = useSelector(foodicsStore.selectors.isFetching)
  const error = useSelector(foodicsStore.selectors.error)

  const [searchParams] = useSearchParams()
  const codeQueryParam = searchParams.get('code')
  const stateQueryParam = searchParams.get('state')

  useEffect(() => {
    if (codeQueryParam && stateQueryParam) {
      dispatch(
        foodicsStore.actions.fetchFoodicsAuthCallback.started({
          code: codeQueryParam,
          state: stateQueryParam
        })
      )
    }
  }, [codeQueryParam, stateQueryParam])

  return (
    <Box
      sx={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', my: 5 }}
    >
      <Card sx={{ maxWidth: '400px', px: { xs: 0, sm: 2 }, py: 2 }}>
        <CardContent>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <FoodicsStatus isFetching={isFetching} error={error} />
            <Button
              onClick={() => navigate('/')}
              size='large'
              sx={{ mt: 2 }}
              variant='contained'
              disableElevation
            >
              Home
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
