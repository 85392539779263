import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { Promotion, ProviderReview } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import subscriptionsStore from 'src/store/subscriptions'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    color: 'w',
    textDecoration: 'underline'
  }
}))

interface ReviewStatusAlertProps {
  isNew?: boolean
}

const SubscriptionsStatusAlert: FC<ReviewStatusAlertProps> = ({ isNew }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'review_status_alert' })
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)
  const navigate = useNavigate()

  if (!subscriptionStatus?.locationCreationAllowed) {
    return (
      <Alert severity='error' sx={{ backgroundColor: 'rgba(255, 99, 71, 0.5)' }}>
        <AlertTitle>Subscription Required</AlertTitle>
        You are not currently subscribed to our service. Please purchase a subscription to create
        locations.
        <Button
          variant='text'
          sx={{
            textDecoration: 'underline',
            color: 'white'
          }}
          component={RouterLink}
          to='/subscriptions'
        >
          Go to Subscription
        </Button>
      </Alert>
    )
  }
  return null
}

export default SubscriptionsStatusAlert
