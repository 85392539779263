import Typography from '@mui/material/Typography'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { Promotion, ProviderReview } from 'src/models/promotions'
import { useTranslation } from 'react-i18next'
import AlertTitle from '@mui/material/AlertTitle'
import Alert from '@mui/material/Alert'
import { ProviderLocation } from 'src/models/providerLocations'

interface ReviewStatusAlertProps {
  promotion?: Promotion
  providerLocation?: ProviderLocation
}

export const ReviewStatusAlert: FC<ReviewStatusAlertProps> = ({ promotion, providerLocation }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'review_status_alert' })

  const [status] = useState<string | undefined>(promotion?.status || providerLocation?.status)
  const [latestProviderReview] = useState<ProviderReview | undefined>(
    promotion?.latestProviderReview || providerLocation?.latestProviderReview
  )

  if (status === 'live' || status === 'declined') {
    return (
      <Alert
        severity={status === 'live' ? 'success' : 'error'}
        variant='outlined'
        sx={{ mt: { xs: 2, sm: 0 } }}
      >
        <AlertTitle sx={{ fontWeight: 700 }}>
          {t(promotion ? 'promotion_new_status' : 'location_new_status').replace(
            '%status%',
            t(status === 'live' ? 'approved' : 'declined')
          )}
        </AlertTitle>
        {latestProviderReview?.comment?.length && (
          <Typography>
            <Typography sx={{ fontWeight: 500 }}>{t('administrator_comment')}</Typography>{' '}
            {latestProviderReview?.comment}
          </Typography>
        )}
        <Typography sx={{ mt: 2, fontSize: '14px' }}>
          {t('last_update_date')}:{' '}
          {moment(latestProviderReview?.updatedAt).format('MMMM d, YYYY [at] HH:mm')}
        </Typography>
      </Alert>
    )
  } else if (status === 'review') {
    return (
      <Alert severity='info' variant='outlined' sx={{ mt: { xs: 2, sm: 0 } }}>
        <AlertTitle sx={{ fontWeight: 700 }}>
          {t(promotion ? 'promotion_under_review_title' : 'location_under_review_title')}
        </AlertTitle>
        <Typography>
          {t(
            promotion ? 'promotion_under_review_description' : 'location_under_review_description'
          )}
        </Typography>
        <Typography sx={{ mt: 2, fontSize: '14px' }}>
          {t('review_requested_date')}:{' '}
          {moment(latestProviderReview?.createdAt).format('MMMM d, YYYY [at] HH:mm')}
        </Typography>
      </Alert>
    )
  }

  return <></>
}
