import clsx from 'clsx'
import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'
import React, { FC } from 'react'
import { useHeaderStyles } from 'src/components/Header/index'

interface SideBarNavItemProps {
  link: string
  Icon: FC
  label: string
}

export const SideBarNavItem: FC<SideBarNavItemProps> = ({ link, Icon, label }) => {
  const classes = useHeaderStyles()

  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? clsx(classes.mobileNavLinkActive, classes.mobileNavLink) : classes.mobileNavLink
      }
      to={link}
    >
      <ListItem disablePadding>
        <ListItemButton>
          {/* Set margin right in sx prop here because it will automatically support rtl */}
          <ListItemIcon className={classes.mobileNavLinkIcon} sx={{ marginRight: '8px' }}>
            <Icon />
          </ListItemIcon>
          <ListItemText className={classes.mobileNavLinkText} primary={label} />
        </ListItemButton>
      </ListItem>
    </NavLink>
  )
}
