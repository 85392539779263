export const baseUrl =
  process.env['REACT_APP_PROVIDERS_API_BASE_URL'] || 'https://provider-backend-devel.hubapp.net'
export const mainBaseUrl =
  process.env['REACT_APP_MAIN_API_BASE_URL'] || 'https://the-hub-devel.f17y.com'
export const ipstackURL =
  'https://api.ipstack.com/check?access_key=9f4cbe5d92324ea73b41da93c4c10de3'
export const googleRecaptchaSecretKey =
  process.env['REACT_APP_RECAPTCHA_SECRET_KEY'] || '6LfeTGQhAAAAAOS7QHWcBphZbpHlufudKI3djGzj'

const isProduction = process.env.NODE_ENV === 'production'

const foodicsClientId = isProduction
  ? '9ae4e8f5-f210-4861-a1e9-22dfb1d2012e'
  : '97ccf7dd-b57b-4101-9856-74d36709c21d'
const foodicsBaseUrl = isProduction
  ? 'https://console.foodics.com'
  : 'https://console-sandbox.foodics.com'

export const foodicsConnectUrl = `${foodicsBaseUrl}/authorize?client_id=${foodicsClientId}`

export const firebaseConfig = {
  apiKey: 'AIzaSyBABOnefpq2-1RrMIM_CX7BpMZ0xu6tyHY',
  authDomain: 'the-hub-b882c.firebaseapp.com',
  databaseURL: 'https://the-hub-b882c.firebaseio.com',
  projectId: 'the-hub-b882c',
  storageBucket: 'the-hub-b882c.appspot.com',
  messagingSenderId: '87201409624',
  appId: '1:87201409624:web:8856ad36e3b7d68114782d',
  measurementId: 'G-E5GEP98SYH'
}
