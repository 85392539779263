import React, { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Card,
  Grid,
  CardContent,
  Divider,
  List,
  ListItem,
  Box,
  IconButton,
  CircularProgress
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import store from 'src/store/adminPreview'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { PromotionItem } from '../Promotions/PromotionItem'
import LocationItem from '../Locations/LocationItem'
import { ProviderLocationId } from 'src/models/providerLocations'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const useStyles = makeStyles(theme => ({
  horizontalList: {
    display: 'flex',
    overflowX: 'auto'
  },
  listItem: {
    //@ts-ignore
    marginRight: theme.spacing(2) // Adjust the spacing between items
  }
}))

const App: React.FC = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const classes = useStyles()
  const boldStyle = { fontWeight: 'bold' }
  const itemStyle = { marginTop: '8px', paddingTop: '8px' }

  const data = useSelector(store.selectors.providerInfo)!
  useEffect(() => {
    dispatch(store.actions.fetchProviderInfo.started(id!))
  }, [])

  if (!data) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        minHeight='100vh' // Adjust the height as needed
      >
        <CircularProgress />
      </Box>
    )
  }

  const { subscriptionStatus, promotions, provider, locations } = data

  const renderStatus = () => {
    return (
      <>
        <Grid item xs={6}>
          <Typography variant='h6'>Subscription Status:</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6} style={itemStyle}>
            <Typography style={itemStyle}>
              Location Creation is Free:{' '}
              <span style={boldStyle}>
                {subscriptionStatus.locationCreationIsFree ? 'Yes' : 'No'}
              </span>
            </Typography>
            <Typography style={itemStyle}>
              Location Creation Allowed:{' '}
              <span style={boldStyle}>
                {subscriptionStatus.locationCreationAllowed ? 'Yes' : 'No'}
              </span>
            </Typography>
            <Typography style={itemStyle}>
              Locations Count Available:{' '}
              <span style={boldStyle}>{subscriptionStatus.locationsCountAvailable}</span>
            </Typography>
            <Typography style={itemStyle}>
              Paid Invoices Total:{' '}
              <span style={boldStyle}>{subscriptionStatus.paidInvoicesTotal}</span>
            </Typography>
          </Grid>
          <Grid item xs={6} style={itemStyle}>
            <Typography style={itemStyle}>
              Locations Count Used:{' '}
              <span style={boldStyle}>{subscriptionStatus.locationsCountUsed}</span>
            </Typography>
            <Typography style={itemStyle}>
              Locations Count Left:{' '}
              <span style={boldStyle}>{subscriptionStatus.locationsCountLeft}</span>
            </Typography>
            <Typography style={itemStyle}>
              Trialing Subscriptions Count:{' '}
              <span style={boldStyle}>{subscriptionStatus.trialingSubscriptionsCount}</span>
            </Typography>
            <Typography style={itemStyle}>
              Active Subscriptions Count:{' '}
              <span style={boldStyle}>{subscriptionStatus.activeSubscriptionsCount}</span>
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  const navigateToPreview = (id?: ProviderLocationId) => navigate(`/admin/location/${id}`)

  const onBackClick = () => navigate(-1)

  return (
    <div className='full-page full-page-gray-bg'>
      <Container sx={{ mt: 3 }} component='main' maxWidth='md'>
        <Card>
          <CardContent>
            <Box display='flex' alignItems='center' mt={2} mb={3}>
              <IconButton onClick={onBackClick} aria-label='back'>
                <ArrowBackIcon />
              </IconButton>
              <Typography ml={5} variant='h5' sx={{ fontWeight: '500' }}>
                Provider statistics
              </Typography>
              {/* If there's additional content to the right, add it here */}
            </Box>

            <Typography style={itemStyle}>
              Provider Name: <span style={boldStyle}>{provider.name}</span>
            </Typography>

            <Typography style={itemStyle}>
              Subscription Country: <span style={boldStyle}>{provider.subscriptionCountry}</span>
            </Typography>

            <Typography style={itemStyle}>
              Foodics:{' '}
              <span style={boldStyle}>
                {provider.integrations?.foodics ? 'Enabled' : 'Disabled'}
              </span>
            </Typography>

            <Divider style={{ margin: '16px 0' }} />

            {renderStatus()}
            <Divider style={{ margin: '16px 0' }} />

            <Grid item xs={6}>
              <Typography variant='h6'>{`Last ${locations.data.length} promotions out of ${locations.totalCount}`}</Typography>
            </Grid>

            <List className={classes.horizontalList}>
              {promotions.data.map((item, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <PromotionItem promotion={item} link={`/admin/promotion/${item.id}`} />
                </ListItem>
              ))}
            </List>
            <Divider style={{ margin: '16px 0' }} />

            <Grid item xs={6}>
              <Typography variant='h6'>{`Last ${locations.data.length} locations out of ${locations.totalCount}`}</Typography>
            </Grid>

            <List className={classes.horizontalList}>
              {locations.data.map((item, index) => (
                <ListItem key={index} className={classes.listItem}>
                  <LocationItem {...item} navigateToPreview={navigateToPreview} />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      </Container>
    </div>
  )
}

export default App
