
import * as React from "react"
import { styled } from "@mui/material/styles"
import Container, { ContainerProps } from "@mui/material/Container"
import CircularProgress from "@mui/material/CircularProgress"

interface LoadingViewProps
    extends React.ComponentPropsWithRef<React.ElementType> {

}

const EmptyContainer = styled(Container)<ContainerProps>({
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: '100%',
    height: "100vh",
})

function LoadingView (props: LoadingViewProps) {
    return (
        <EmptyContainer>
            <CircularProgress />
        </EmptyContainer>
    )
}
export default LoadingView
