import React, { FC } from 'react'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography
} from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Provider, ProviderId } from 'src/models/auth'
import authStore from 'src/store/auth'

import CheckIcon from '@mui/icons-material/Check'
import Settings from '@mui/icons-material/Settings'
import Logout from '@mui/icons-material/Logout'

interface ProviderMenuProps {
  anchorEl: null | HTMLElement
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
}

export const ProviderMenu: FC<ProviderMenuProps> = ({ anchorEl, setAnchorEl }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'header' })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(authStore.selectors.user)
  const providerAdmins = useSelector(authStore.selectors.providerAdmins)
  const currentProvider = useSelector(authStore.selectors.currentProvider)

  const navigateToProfile = () => {
    navigate('/profile')
  }

  const handleCloseProviderMenu = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    dispatch(authStore.actions.logout({}))
  }

  const navigateToUserProfile = () => {
    handleCloseProviderMenu()

    navigateToProfile()
  }

  const handleProviderSelect = (provider: Provider) => {
    dispatch(
      authStore.actions.selectProvider({
        provider: provider
      })
    )

    handleCloseProviderMenu()
  }

  const handleProviderSettingsClick = (
    event: React.MouseEvent<HTMLElement>,
    providerId: ProviderId
  ) => {
    handleCloseProviderMenu()

    navigate('/providers/' + providerId)
  }

  return (
    <Menu
      sx={{ mt: { xs: '40px', md: '55px' }, width: 300 }}
      id='menu-appbar'
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      onClose={handleCloseProviderMenu}
    >
      <MenuItem key={'logged_in_as'} disabled={true} sx={{ my: { xs: -1, md: 0 } }}>
        {t('logged_in_as')}
      </MenuItem>
      <MenuItem key={user?.profile?.id} onClick={navigateToUserProfile}>
        <ListItemIcon>
          <Avatar
            src={((user?.profile?.image as any)?.url as string) || undefined}
            sx={{ width: 24, height: 24, mr: 1 }}
          />
        </ListItemIcon>
        <ListItemText>
          <Typography noWrap>
            {user?.profile.fullName ||
              user?.email ||
              user?.profile.phoneNumber ||
              user?.profile.phoneNumber}
          </Typography>
        </ListItemText>
      </MenuItem>
      <Divider key={'divider'} />
      {providerAdmins && providerAdmins.length
        ? [
            <MenuItem key={user?.profile?.id} disabled={true} sx={{ my: { xs: -1, md: 0 } }}>
              {t('providers')}
            </MenuItem>,
            ...providerAdmins.map(providerAdmin => {
              const { provider } = providerAdmin
              const isSelected = provider.id === currentProvider?.id

              return (
                <MenuItem key={provider.id} onClick={() => handleProviderSelect(provider)}>
                  <ListItemIcon>
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        mr: 1,
                        bgcolor: isSelected ? '#38A2AB' : undefined
                      }}
                    >
                      {isSelected ? <CheckIcon /> : null}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography noWrap>{provider.name || 'Provider #' + provider.id}</Typography>
                  </ListItemText>
                  {providerAdmin.role === 'owner' && (
                    <IconButton
                      sx={{ mr: -1, my: -1 }}
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        handleProviderSettingsClick(event, provider.id)
                      }
                    >
                      <Settings color='disabled' fontSize='small' />
                    </IconButton>
                  )}
                </MenuItem>
              )
            }),
            <Divider key={`divider_provider`} />
          ]
        : null}
      <MenuItem key='logout' onClick={handleLogout}>
        <ListItemIcon>
          <Logout fontSize='small' />
        </ListItemIcon>
        {t('logout')}
      </MenuItem>
    </Menu>
  )
}
