// @mui
import PricingPlanCard from './PricingPlanCard'
import PlanPremiumIcon from './Icon'
import { Box, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { useTranslation } from 'react-i18next'

export default function Pricing() {
  const { t } = useTranslation('translation', { keyPrefix: 'subscriptions' })

  const subscriptionPlans = useSelector(subscriptionsStore.selectors.subscriptionPlans)

  return (
    <>
      <Box sx={{ mt: 5 }}>
        <Typography variant='h4' align='center' paragraph>
          {t('plans_title')}
        </Typography>
        <Typography align='center' sx={{ color: 'text.secondary' }}>
          {t('plans_subtitle')}
        </Typography>

        <Grid sx={{ my: 5 }} container spacing={3} justifyContent={'center'} alignItems='stretch'>
          {subscriptionPlans.map((card, index) => (
            <Grid item xs={12} md={4} key={card.id}>
              <PricingPlanCard card={card} index={index} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}
