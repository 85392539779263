import React from 'react'
import { ToggleButton, ToggleButtonGroup, makeStyles } from '@mui/material'

type Status = 'daily' | 'monthly' | 'yearly'

interface StatusToggleProps {
  status: Status
  onChange: (event: React.MouseEvent<HTMLElement>, newStatus: Status) => void
}

const StatusToggle: React.FC<StatusToggleProps> = ({ status, onChange }) => {
  return (
    <ToggleButtonGroup
      color='primary'
      value={status}
      exclusive
      onChange={onChange}
      aria-label='status'
    >
      <ToggleButton value='daily' aria-label='daily'>
        Daily
      </ToggleButton>
      <ToggleButton value='monthly' aria-label='monthly'>
        Monthly
      </ToggleButton>
      <ToggleButton value='yearly' aria-label='yearly'>
        Yearly
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default StatusToggle
