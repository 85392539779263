import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import AdminsList from 'src/components/AdminsList'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import authStore from 'src/store/auth'
import RoundedButton from 'src/components/RoundedButton'
import { useTranslation } from 'react-i18next'
import { Provider } from 'src/models/auth'
import usePrevious from '../../hooks/usePrevious'
import { Spinner } from 'src/components/index'
import { Alert, AlertTitle, Button } from '@mui/material'
import { FoodicsSettings } from 'src/components/Foodics/FoodicsSettings'
import { SectionTitle } from 'src/components/SectionTitle'
import { ProviderCountryPicker } from 'src/components'
import { SubscriptionCountry } from 'src/models/auth'

export default function ProviderSettings() {
  const { t } = useTranslation('translation', { keyPrefix: 'provider_settings' })

  const [providerName, setProviderName] = useState('')
  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  const [params] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const currentProvider = useSelector(authStore.selectors.currentProvider)
  const user = useSelector(authStore.selectors.user)

  const [providerCountry, setProviderCountry] = React.useState<SubscriptionCountry | undefined>(
    currentProvider?.subscriptionCountry
  )

  const isUpdatingCurrentProvider = useSelector(authStore.selectors.isUpdatingCurrentProvider)
  const error = useSelector(authStore.selectors.error)

  const prevIsUpdatingCurrentProvider = usePrevious(isUpdatingCurrentProvider)

  useEffect(() => {
    if (currentProvider?.name) {
      setProviderName(currentProvider.name)
    }
  }, [currentProvider])

  useEffect(() => {
    if (prevIsUpdatingCurrentProvider && !isUpdatingCurrentProvider) {
      enqueueSnackbar(error?.message || t('action_saved'), {
        variant: error ? 'error' : 'success',
        onExited: () => error && dispatch(authStore.actions.dismissError())
      })
    }
  }, [isUpdatingCurrentProvider])

  const handleChangeProviderName = (event: ChangeEvent<HTMLInputElement>) => {
    setProviderName(event.target.value)
  }

  useEffect(() => {
    if (providerName.length >= 3) {
      setIsSaveDisabled(false)
    } else {
      setIsSaveDisabled(true)
    }
  }, [providerName])

  const handleSaveProvider = () => {
    dispatch(
      authStore.actions.updateCurrentProvider.started({
        provider: {
          ...(currentProvider as Provider),
          name: providerName,
          subscriptionCountry: providerCountry
        }
      })
    )
  }

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth={false}
        sx={{ mt: 0, pt: { xs: 0, sm: 4 }, pb: 4, backgroundColor: '#f2f2f2', maxWidth: 700 }}
      >
        {params.get('provider-created') && (
          <Alert
            variant='outlined'
            severity='success'
            action={
              <Button
                sx={{ alignSelf: 'center' }}
                color='inherit'
                size='small'
                onClick={() => navigate('/locations/create')}
              >
                {t('create_location_btn')}
              </Button>
            }
          >
            <AlertTitle>{t('provider_created_alert_title')}</AlertTitle>
            {t('provider_created_alert_body')}
          </Alert>
        )}
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography
            color={'text.secondary'}
            fontWeight={'500'}
            component='h3'
            variant='h4'
            align='left'
          >
            {t('title')}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <SectionTitle>{t('input_provider_name') + ' *'}</SectionTitle>
                <OutlinedInput
                  name='providerName'
                  required
                  value={providerName}
                  fullWidth
                  onChange={handleChangeProviderName}
                  id='providerName'
                />
              </Grid>
              <FoodicsSettings />
              {!providerCountry ? (
                <Grid item xs={12} sm={12}>
                  <ProviderCountryPicker value={providerCountry} onChange={setProviderCountry} />
                </Grid>
              ) : null}
              <AdminsList />
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={handleSaveProvider}
                label={t('btn_save')}
                disabled={isSaveDisabled || isUpdatingCurrentProvider}
              />
              <RoundedButton
                type='submit'
                fullWidth
                variant='outlined'
                sx={{ mb: 2 }}
                onClick={handleBack}
                label={t('btn_cancel')}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
      <Spinner loading={isUpdatingCurrentProvider} />
    </div>
  )
}
