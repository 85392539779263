import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { fetch, fetchAll, remove, dismissError, updateStatus } from './actions'
import { create } from '../createPromotion/actions'
import { logout, selectProvider } from '../auth/actions'

import keyBy from 'lodash/keyBy'
import _, { groupBy } from 'lodash'

// Types imports
import { StatePromotions } from './types'
import omitBy from 'lodash/omitBy'

const initialState: StatePromotions = {
  cache: {}
}

export function promotions(state: StatePromotions = initialState, action: Action): StatePromotions {
  if (isType(action, create.done)) {
    const { locationId, result } = action.payload.result
    return {
      ...state,
      cache: {
        ...state.cache,
        [locationId]: {
          ...state.cache[locationId],
          [result.id!]: result
        }
      }
    }
  }

  if (isType(action, updateStatus.done)) {
    const result = action.payload.result
    const locationId = result!.location!.id!
    return {
      ...state,
      isUpdatingStatus: false,
      cache: {
        ...state.cache,
        [locationId]: {
          ...state.cache[locationId],
          [result.id!]: result
        }
      }
    }
  }

  if (isType(action, fetch.started) || isType(action, updateStatus.done)) {
    return {
      ...state,
      isUpdatingStatus: true,
      error: undefined,
      isFetching: true
    }
  }

  if (isType(action, fetch.done)) {
    const { result, params } = action.payload
    const data = keyBy(result.data, 'id')
    return {
      ...state,
      cache: {
        ...state.cache,
        [params]: data
      },
      isFetching: false,
      selectedLocationId: params
    }
  }

  if (isType(action, fetchAll)) {
    const result = action.payload
    const locations = _.groupBy(result.data, 'location.id')
    let cache: any = {}
    Object.entries(locations).map(([k, v]) => {
      cache[k] = keyBy(v, 'id')
    })
    return {
      ...state,
      isFetching: false,
      cache,
      selectedLocationId: 'all'
    }
  }

  if (isType(action, fetch.failed) || isType(action, updateStatus.failed)) {
    return {
      ...state,
      isUpdatingStatus: false,
      error: action.payload.error,
      isFetching: false
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      isRemoving: true,
      error: undefined
    }
  }
  if (isType(action, remove.done)) {
    return {
      ...state,
      isRemoving: false,
      cache: {
        ...state.cache,
        [state.selectedLocationId!]: omitBy(
          state.cache[state.selectedLocationId!],
          ({ id }) => id === action.payload.params.promotionId
        )
      }
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,
      isRemoving: false,
      error: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, logout) || isType(action, selectProvider)) {
    return initialState
  }

  return state
}
