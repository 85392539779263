import React from 'react'
import Map from './Map'
import { PlaceInfo } from './google/types'
import { LatLng } from 'src/models/providerLocations'

interface Props {
  location: LatLng | undefined
  address: string | undefined
  changeLocation: (place: PlaceInfo) => void
}

const LocationPicker: React.FC<Props> = ({ location, address, changeLocation }: Props) => {
  return <Map position={location} address={address} changeLocation={changeLocation} />
}

export default LocationPicker
