import i18n from 'i18next'

const numHindi = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹']
const numEasternArabic = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']

export function convertNumberByLocale(num: string | number) {
  if (i18n.language !== 'ar') {
    return num.toString()
  }

  return num.toString().replace(/[0-9]/g, n => {
    return numEasternArabic[+n]
  })
}

export function convertNumberToEuropean(num: string) {
  if (i18n.language !== 'ar') {
    return num
  }

  return num
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (d: string) => (d.charCodeAt(0) - 1632).toString())
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, (d: string) => (d.charCodeAt(0) - 1776).toString())
}
