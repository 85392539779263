export const chartData = [
  { label: 'Provider with Active Subscriptions', field: 'providersWithActiveSubscriptionsCount' },
  { label: 'Provider with Active Promotions', field: 'providersWithActivePromotionsCount' },
  { label: 'Provider with Foodics', field: 'providersWithFoodicsCount' },
  { label: 'Active Locations', field: 'activeLocationsCount' },
  { label: 'Active Promotions', field: 'activePromotionsCount' },
  { label: 'Active Promotions With Foodics', field: 'activePromotionsWithFoodicsCount' }
]

export const chartColors = {
  providersWithActiveSubscriptionsCount: '#b223ac', // Darker Pink
  providersWithActivePromotionsCount: '#23b23c', // Darker Light Green
  providersWithFoodicsCount: '#acb223', // Darker Yellow
  activeLocationsCount: '#23b2ac', // Darker Cyan
  activePromotionsCount: '#233cb2', // Darker Blue
  activePromotionsWithFoodicsCount: '#b23c23' // Darker Orange
}

export type TypeStatus = 'daily' | 'monthly' | 'yearly'

export type GraphViewType = 'all' | 'single'
