import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  fetchLocation,
  fetchPromotion,
  fetchProvidersInfo,
  fetchProviderInfo,
  dismissError,
  setProviderInfoListFilters,
  fetchProvidersStats,
  setProviderStatsFilter
} from './actions'

// Types imports
import { StateAdminPreview } from './types'

const initialState: StateAdminPreview = {
  providerInfosListFilter: {},
  providerStatsFilter: {}
}

export function adminPreview(
  state: StateAdminPreview = initialState,
  action: Action
): StateAdminPreview {
  if (isType(action, fetchLocation.done)) {
    return {
      ...state,
      isFetching: false,
      location: action.payload.result
    }
  }
  if (isType(action, fetchLocation.started)) {
    return {
      ...state,
      isFetching: true,
      error: undefined
    }
  }
  if (isType(action, fetchPromotion.done)) {
    return {
      ...state,
      promotion: action.payload.result,
      isFetching: false
    }
  }

  if (isType(action, fetchProviderInfo.started)) {
    return {
      ...state,
      isFetching: true,
      providerInfo: undefined
    }
  }

  if (isType(action, fetchProvidersInfo.started)) {
    return {
      ...state,
      isFetching: true,
      providerInfosList: undefined
    }
  }

  if (isType(action, fetchProvidersInfo.done)) {
    return {
      ...state,
      isFetching: false,
      providerInfosList: action.payload.result
    }
  }

  if (isType(action, fetchProviderInfo.done)) {
    return {
      ...state,
      providerInfo: action.payload.result,
      isFetching: false
    }
  }

  if (isType(action, fetchProvidersStats.done)) {
    return {
      ...state,
      providerStats: action.payload.result,
      isFetching: false
    }
  }

  if (
    isType(action, fetchLocation.failed) ||
    isType(action, fetchPromotion.failed) ||
    isType(action, fetchProvidersInfo.failed) ||
    isType(action, fetchProviderInfo.failed)
  ) {
    return {
      ...state,
      isFetching: false,
      error: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, setProviderInfoListFilters)) {
    return {
      ...state,
      providerInfosListFilter: action.payload
    }
  }

  if (isType(action, setProviderStatsFilter)) {
    return {
      ...state,
      providerStatsFilter: action.payload
    }
  }

  return state
}
