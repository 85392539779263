import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import subscriptionsStore from 'src/store/subscriptions'
import { Container } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSearchParams } from 'react-router-dom'

import ActiveSubscriptions from './ActiveSubscriptions'
import Pricing from './Pricing'
import PromoCode from './PromoCode'

import { useSnackbar } from 'notistack'

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: '100%',
  backgroundColor: '#f2f2f2',
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10)
}))

// App component
const App: React.FC = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const error = useSelector(subscriptionsStore.selectors.error)
  const subscriptionPromoCode = useSelector(subscriptionsStore.selectors.subscriptionPromoCode)

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
        onExited: () => dispatch(subscriptionsStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    dispatch(
      subscriptionsStore.actions.fetchPlans.started(
        searchParams?.get('promoCode') || subscriptionPromoCode
      )
    )
    dispatch(subscriptionsStore.actions.fetchStatus.started())
    dispatch(subscriptionsStore.actions.fetchSubscriptions.started())
  }, [])

  return (
    <div className='app'>
      <RootStyle>
        <Container>
          <ActiveSubscriptions />
          <Pricing />
          <PromoCode />
        </Container>
      </RootStyle>
    </div>
  )
}

export default App
