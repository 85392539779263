import React, { useCallback, useRef, useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import reverseGeocode from './google/reverseGeocode'
import { PlaceInfo } from './google/types'
import { LatLng } from 'src/models/providerLocations'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useTranslation } from 'react-i18next'
import customStyles from './customStyles'
import { Marker } from './Marker'

const googleApiKey = 'AIzaSyBGpszcAEwUDscvGnsipW2hW38JFF_0tKI'

export interface Position {
  lat: number
  lng: number
}

interface IProps {
  address: string | undefined
  position?: LatLng | undefined
  changeLocation: (place: PlaceInfo) => void
}

type MapState = {
  mapApiLoaded: boolean
  mapInstance: any
  mapApi: any
  geoCoder: any
  places: any
  center: any
  zoom: number
  address: string
  draggable: boolean
  lat: number | null
  lng: number | null
  active: boolean
}

const Map: React.FC<IProps> = ({ position, changeLocation, address }: IProps) => {
  const placeInput = useRef<HTMLInputElement>(null)
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })
  const [mapState, setMapState] = useState<MapState>({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [position?.latitude || 48, position?.longitude || 7],
    zoom: position?.latitude ? 16 : 3,
    address: address || '',
    draggable: false,
    lat: position?.latitude || null,
    lng: position?.longitude || null,
    active: true
  })
  const [onMapChangeLoading, setOnMapChangeLoading] = useState<'loading' | 'loaded' | 'error'>(
    'loaded'
  )

  const [initialPosition, setInitialPosition] = useState<Position>({
    lat: 36,
    lng: 49
  })

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(location => {
      const { latitude, longitude } = location.coords
      if (!position) {
        setMapState({
          ...mapState,
          center: {
            lat: latitude || null,
            lng: longitude || null
          },
          zoom: 12
        })
      }
    })
  }, [])

  /*
  useEffect(() => {
    setMapState({
      ...mapState,
      lat: position?.latitude || null,
      lng: position?.longitude || null,
    })
  }, [position])
  */

  const onChildClick = useCallback(({ lat, lng }: Position) => {
    reverseGeocode(googleApiKey, lat, lng)
      .then((result: PlaceInfo | null) => {
        changeLocation(result!)
        //@ts-ignore
        const { longitude, latitude } = result?.location
        //@ts-ignore
        setMapState({
          ...mapState,
          center: [latitude, longitude],
          lat: latitude! || null,
          lng: longitude! || null,
          address: result?.fullAddress!
        })
        if (placeInput.current) {
          placeInput.current.value = result?.fullAddress || ''
        }
      })
      .catch(searchByTextError => {
        console.log(searchByTextError)
      })
  }, [])

  const onChangeMap = async (e: any) => {
    if (onMapChangeLoading !== 'loading') {
      setOnMapChangeLoading('loading')
      setTimeout(async () => {
        try {
          const geocoder = new mapState.mapApi.Geocoder()
          const { results } = await geocoder.geocode({
            location: { lat: e.center.lat, lng: e.center.lng }
          })
          setMapState({
            ...mapState,
            center: e.center,
            lat: e.center.lat,
            lng: e.center.lng,
            address: results[0].formatted_address
          })
          changeLocation({
            location: {
              latitude: e.center.lat,
              longitude: e.center.lng
            },
            fullAddress: results[0].formatted_address
          })
          setOnMapChangeLoading('loaded')
        } catch (err) {
          console.log('error', err)
          setOnMapChangeLoading('error')
        }
      }, 3000)
    }
  }

  const onGoogleApiLoaded = ({ map, maps }: { map: any; maps: any; ref: Element | null }) => {
    const LatLngFrom = new maps.LatLng(45.3326, 148.4508)
    const LatLngTo = new maps.LatLng(24.2658, 122.5601)
    const bounds = new maps.LatLngBounds(LatLngTo, LatLngFrom)
    setMapState({
      ...mapState,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps
    })
    const autocomplete = new maps.places.Autocomplete(placeInput.current, {
      bounds,
      strictBounds: false,
      fields: ['geometry', 'place_id', 'name', 'address_components', 'vicinity'],
      types: ['establishment']
    })

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()

      if (onMapChangeLoading === 'loading') {
        return
      }

      let address = ' '
      if (place.address_components) {
        address = [
          (place.address_components[1] && place.address_components[1].short_name) || '',
          (place.address_components[0] && place.address_components[0].short_name) || '',
          (place.address_components[2] && place.address_components[2].short_name) || '',
          (place.address_components[3] && place.address_components[3].short_name) || ''
        ].join(', ')
      }

      if (place.geometry !== undefined) {
        setMapState({
          ...mapState,
          center: [place.geometry.location.lat(), place.geometry.location.lng()],
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
          address: place.formatted_address || address || place.vicinity,
          zoom: 12
        })

        changeLocation({
          location: {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          },
          // Add name before full address
          // e.g. KFC, Derybasivska Street, Odesa, Odessa Oblast, Ukraine
          fullAddress:
            (place.name ? place.name + ', ' : '') +
            (place.formatted_address || address || place.vicinity),
          placeId: place.place_id
        })

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport)
          map.setZoom(14)
        } else {
          map.setCenter(place.geometry.location)
          map.setZoom(15)
        }
      }
    })
  }

  return (
    <>
      <OutlinedInput
        sx={{ mb: 4 }}
        defaultValue={mapState.address || ''}
        placeholder={t('input_location')}
        inputRef={placeInput}
        fullWidth
      />

      <div style={{ height: '400px', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyBABOnefpq2-1RrMIM_CX7BpMZ0xu6tyHY',
            libraries: ['places', 'geometry']
          }}
          center={mapState.center}
          zoom={mapState.zoom}
          //onChange={onChangeMap}
          onClick={onChildClick}
          defaultZoom={10}
          options={{
            styles: customStyles,
            clickableIcons: false,
            draggableCursor: 'default',
            draggingCursor: 'grabbing'
          }}
          onGoogleApiLoaded={onGoogleApiLoaded}
        >
          {mapState.lat ? <Marker lat={mapState.lat!} lng={mapState.lng!} /> : null}
        </GoogleMapReact>
      </div>
    </>
  )
}

export default Map
