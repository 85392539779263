import React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

// Define the types for your view options
type GraphViewType = 'all' | 'single'

// Define the types for the props
interface GraphViewToggleProps {
  currentView: GraphViewType
  onChange: (event: React.MouseEvent<HTMLElement>, newView: GraphViewType) => void
}

// Define the GraphViewToggle component
const GraphViewToggle: React.FC<GraphViewToggleProps> = ({ currentView, onChange }) => {
  return (
    <ToggleButtonGroup
      value={currentView}
      exclusive
      sx={{ mt: 2 }}
      onChange={onChange}
      aria-label='Graph View Type'
    >
      <ToggleButton value='all' aria-label='ALL'>
        ALL
      </ToggleButton>
      <ToggleButton value='single' aria-label='SINGLE'>
        SINGLE
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

export default GraphViewToggle
