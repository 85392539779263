import React, { useEffect, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import FilesDropzone from 'src/components/FilesDropzone'
import TextLabel from 'src/components/TextLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import Scheduler from './Scheduler'
import providerLocationsStore from 'src/store/providerLocations'
import promotionsStore from 'src/store/promotions'
import createPromotionStore from 'src/store/createPromotion'
import { useTranslation } from 'react-i18next'
import PromotionStatus from 'src/components/Statuses/PromotionStatus'
import { PromotionType, Promotion } from 'src/models/promotions'
import { FormControlLabel, InputAdornment, Switch, TextField, Typography } from '@mui/material'
import CurrencyPicker from './CurrencyPicker'
import countryInfo from './CurrencyPicker/countryInfo.json'

import authStore from 'src/store/auth'

const PROMOTION_DESCRIPTION_CHAR_LIMIT = 2000

interface Props {
  promotion: Promotion
  promotionId?: string
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 250
    }
  }
}

export default function PromotionForm({ promotion, promotionId }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'create_promotion' })

  const typesTitle = {
    percentage_discount: t('input_discount_percentage'),
    fixed_discount: t('input_fixed_discount')
  }

  const draft = useSelector((state: any) =>
    createPromotionStore.selectors.currentDraft(state, promotionId || 'new')
  )

  const providerLocations = useSelector(providerLocationsStore.selectors.list)
  const userPhoneNumber = useSelector(authStore.selectors.user)?.phoneNumber

  const selectedLocation = useSelector(promotionsStore.selectors.selectedLocation)
  const selectedLocationId = useSelector(promotionsStore.selectors.selectedLocationId)
  const isFoodicsConnectedToCurrentProvider = useSelector(
    authStore.selectors.isFoodicsConnectedToCurrentProvider
  )
  const userCurrency = countryInfo.find(item =>
    `+${userPhoneNumber}`.startsWith(item.dial_code)
  )?.currency

  useEffect(() => {
    !promotionId && !draft?.locationId && updateDraft('locationId', selectedLocation?.id)
    !promotionId && !draft?.type && updateDraft('type', PromotionType.percentageDiscount)
    !promotionId && !draft?.currency && updateDraft('currency', userCurrency)
    promotionId && !draft?.currency && updateDraft('currency', userCurrency)
  }, [])

  const handleTextFields =
    (fieldName: string) => (value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
      updateDraft(fieldName, value.currentTarget.value)

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      createPromotionStore.actions.updateDraft({
        id: promotionId,
        [fieldName]: value
      })
    )

  const updateImage = (files: File[]) => updateDraft('image', files[0])

  const selectLocation = (e: any) => updateDraft('locationId', e.target.value)
  const selectType = (e: any) => updateDraft('type', e.target.value)

  const handleFoodicsRewardIsEnabled = (e: any) => {
    updateDraft('foodicsRewardIsEnabled', e.target.checked)
  }

  const renderDiscountInput = () => {
    switch (draft?.type) {
      case PromotionType.fixedDiscount:
        return (
          <OutlinedInput
            key={PromotionType.fixedDiscount}
            fullWidth
            type='number'
            defaultValue={draft?.fixedDiscount}
            sx={{ height: '40px' }}
            endAdornment={<InputAdornment position='end'>{draft.currency}</InputAdornment>}
            onChange={handleTextFields('fixedDiscount')}
          />
        )
      case PromotionType.percentageDiscount:
        return (
          <OutlinedInput
            key={PromotionType.percentageDiscount}
            fullWidth
            value={draft?.discountPercentage}
            type='number'
            sx={{ height: '40px' }}
            onChange={handlePercentageDiscount}
            endAdornment={<InputAdornment position='end'>%</InputAdornment>}
            onWheel={(e: any) => e.target.blur()}
          />
        )
    }
  }

  const handlePercentageDiscount = (e: any) => {
    const percent = e.target.value

    if ((percent + '')?.split('.')?.[1]?.length > 2) {
      return
    }

    let value = percent

    if (+percent > 100) {
      value = '100'
    } else if (+percent < 0) {
      value = '0'
    }

    updateDraft('discountPercentage', value.toString())
  }

  const onRemove = () => {
    if (promotionId) {
      dispatch(createPromotionStore.actions.edit(promotion))
    } else {
      updateImage([])
    }
  }

  useEffect(() => {
    if (draft?.text && draft.text?.length > PROMOTION_DESCRIPTION_CHAR_LIMIT) {
      updateDraft('text', draft.text?.slice(0, PROMOTION_DESCRIPTION_CHAR_LIMIT))
    }
  }, [draft])

  const updateCurrency = (value: string) => updateDraft('currency', value)

  const handleMaxDiscountAmount = (e: any) => updateDraft('maxDiscountAmount', e.target.value)

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {promotionId && promotion ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextLabel title={t('input_current_status')} />
              <PromotionStatus promotion={promotion} type={'form'} />
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextLabel title={t('input_name_title') + ' *'} />
          <OutlinedInput
            placeholder={t('input_name_title_placeholder')}
            fullWidth
            defaultValue={draft?.title}
            sx={{ height: '40px' }}
            onChange={handleTextFields('title')}
          />
        </Grid>

        {selectedLocation || selectedLocationId === 'all' ? (
          <Grid item xs={12}>
            <TextLabel title={t('input_select_location') + ' *'} />
            <Select
              value={
                draft?.locationId ||
                draft?.location?.id ||
                (selectedLocationId !== 'all' ? selectedLocationId : '')
              }
              onChange={selectLocation}
              input={<OutlinedInput sx={{ backgroundColor: 'white', height: '40px' }} fullWidth />}
              MenuProps={MenuProps}
            >
              {providerLocations?.map(value => (
                //@ts-ignore
                <MenuItem key={value.id} value={value.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      maxWidth: '100%',
                      overflow: 'hidden',
                      justifyContent: 'space-between',
                      width: '100%'
                    }}
                  >
                    <Box>{value.name}</Box>
                    <Box sx={{ ml: 1, mr: 2, whiteSpace: 'normal' }}>
                      <Typography sx={{ fontSize: 10, textAlign: 'right', maxHeight: 28 }}>
                        {value.address}
                      </Typography>
                    </Box>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextLabel title={t('input_select_type') + ' *'} />
          <Select
            defaultValue={draft?.type || ''}
            input={<OutlinedInput sx={{ backgroundColor: 'white', height: '40px' }} fullWidth />}
            MenuProps={MenuProps}
            onChange={selectType}
          >
            {Object.values(PromotionType)?.map(item => {
              return (
                <MenuItem value={item} key={item}>
                  {
                    /*// @ts-ignore */
                    typesTitle[item]
                  }
                </MenuItem>
              )
            })}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <TextLabel title={typesTitle[draft?.type!] + ' *'} />
          {renderDiscountInput()}
        </Grid>

        {draft?.type === 'percentage_discount' ? (
          <Grid item xs={12}>
            <TextLabel title={'Maximum discount amount' + ' *'} />
            <OutlinedInput
              fullWidth
              value={draft?.maxDiscountAmount}
              type='number'
              sx={{ height: '40px' }}
              endAdornment={<InputAdornment position='end'>{draft.currency}</InputAdornment>}
              onChange={handleMaxDiscountAmount}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextLabel title={'Currency' + ' *'} />
          <CurrencyPicker onChange={updateCurrency} currency={draft?.currency || userCurrency} />
        </Grid>

        <Grid item xs={12}>
          <TextLabel title={t('scheduler_title')} />
          <Scheduler promotionId={promotionId!} />
        </Grid>

        <Grid item xs={12}>
          <TextLabel title={t('input_desc') + ' *'} />
          <TextField
            minRows={5}
            multiline
            fullWidth
            value={draft?.text || ''}
            onChange={handleTextFields('text')}
            placeholder={t('input_desc_placeholder')}
            helperText={`${draft?.text?.length || 0}/${PROMOTION_DESCRIPTION_CHAR_LIMIT} ${t(
              'input_desc_characters'
            )}`}
          />
        </Grid>

        {isFoodicsConnectedToCurrentProvider && (
          <Grid item xs={12}>
            <TextLabel title={t('foodics_reward')} />
            <FormControlLabel
              control={
                <Switch
                  checked={draft?.foodicsRewardIsEnabled}
                  onChange={handleFoodicsRewardIsEnabled}
                />
              }
              label={t('enable_foodics_reward')}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FilesDropzone uploadType={'promotion-image'} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}
