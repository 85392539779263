import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { SxProps, Theme } from '@mui/material'

interface Props {
  title: string
  children?: React.ReactNode
  sx?: SxProps<Theme>
}
const TextLabel = ({ title, children, sx }: Props) => {
  const [t, i18n] = useTranslation()
  const isRtl = i18n.dir(i18n.language) === 'rtl'

  return (
    <Typography
      component='h6'
      align={'left'}
      color={'rgba(41, 56, 69, 0.7)'}
      sx={{
        fontWeight: 500,
        mb: 1,
        ...sx
      }}
    >
      {title ? title : children}
    </Typography>
  )
}

export default TextLabel
