import { call, put, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { API, mainAPI } from 'src/data'
import { fetchFoodicsUserInfo, fetchFoodicsAuthCallback } from 'src/store/foodics/actions'
import { Action } from 'typescript-fsa'
import { FoodicsAuthCallbackPayload } from 'src/models/foodics'

export default function createRootSaga() {
  function* getFoodicsUser(): SagaIterator {
    try {
      const result = yield call([API, API.getFoodicsUser])
      yield put(fetchFoodicsUserInfo.done({ result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchFoodicsUserInfo.failed({ error }))
    }
  }

  function* sendFoodicsAuthCallback({ payload }: Action<FoodicsAuthCallbackPayload>): SagaIterator {
    try {
      yield call([mainAPI, mainAPI.sendFoodicsAuthCallback], payload.code, payload.state)
      yield put(fetchFoodicsAuthCallback.done({ params: payload }))
    } catch (error) {
      if (error instanceof Error)
        yield put(fetchFoodicsAuthCallback.failed({ params: payload, error }))
    }
  }

  return [
    takeLatest(fetchFoodicsUserInfo.started, getFoodicsUser),
    takeLatest(fetchFoodicsAuthCallback.started, sendFoodicsAuthCallback)
  ]
}
