import React from 'react'
import Slider from 'react-slick'
import { Card, CardMedia, Container, Typography } from '@mui/material'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { File } from 'src/models/uploads'

interface Props {
  images: File[] | null
}

const ImageSlider = ({ images }: Props) => {
  if (!images?.length) {
    return null
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: true,
    slidesToScroll: 1,
    customPaging: (i: number) => (
      <div
        style={{
          marginTop: '24px',
          marginBottom: '24px',
          width: '12px', // Adjust the width of the dots
          height: '12px', // Adjust the height of the dots
          backgroundColor: i === 0 ? '#39a1ab' : '#888', // Change the active and inactive dot color
          borderRadius: '8px' // Adjust the border radius of the dots
        }}
      />
    )
  }

  return (
    <Container maxWidth='md' sx={{ mt: 2, mb: 3, backgroundColor: '#f6f6f6' }}>
      <Slider {...settings}>
        {images.map((item, index) => (
          <Item key={index} item={item} />
        ))}
      </Slider>
    </Container>
  )
}

const Item = ({ item }: { item: File }) => {
  return (
    <Card>
      <CardMedia component='img' height='300' image={item.url} alt={item.id} />
    </Card>
  )
}

export default ImageSlider
