import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { updateDraft, create, edit, dismissError } from './actions'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

// Types imports
import { StateCreatePromotion } from './types'

const initialState: StateCreatePromotion = {
  drafts: {}
}

export function createPromotion(
  state: StateCreatePromotion = initialState,
  action: Action
): StateCreatePromotion {
  if (isType(action, create.started)) {
    return {
      ...state,
      isCreating: true,
      error: undefined
    }
  }

  if (isType(action, create.done)) {
    return {
      ...state,
      drafts: {},
      isCreating: false
    }
  }

  if (isType(action, create.failed)) {
    return {
      ...state,
      isCreating: false,
      error: action.payload.error
    }
  }

  if (isType(action, updateDraft)) {
    const id = action.payload?.id || 'new'

    return {
      ...state,
      drafts: {
        ...state.drafts,
        [id]: {
          ...(state.drafts[id] || {}),
          ...action.payload
        }
      }
    }
  }
  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, edit)) {
    const promotion = action.payload

    return {
      ...state,
      //@ts-ignore
      drafts: {
        ...state.drafts,
        [promotion.id!]: {
          ...promotion,
          locationId: promotion.location!.id!,
          discountSchedule: mapValues(keyBy(promotion?.discountSchedule, 'dayOfTheWeek'), item => {
            const endValues = item.endTime.toString().split('.')
            const startValues = item.startTime.toString().split('.')
            return {
              ...item,
              isSelected: true,
              endTime: `${endValues[0].length === 1 ? '0' + endValues[0] : endValues[0]}:${
                endValues?.[1] !== undefined
                  ? endValues[1].length === 1
                    ? '0' + endValues?.[1]
                    : endValues?.[1]
                  : '00'
              }`,
              startTime: `${startValues[0].length === 1 ? '0' + startValues[0] : startValues[0]}:${
                startValues?.[1] !== undefined
                  ? startValues[1].length === 1
                    ? '0' + startValues?.[1]
                    : startValues?.[1]
                  : '00'
              }`
            }
          })
        }
      }
    }
  }

  return state
}
