import { State } from 'src/models'

export const subscriptions = (state: State) => state.subscriptions.subscriptions.list
export const subscriptionPlans = (state: State) => state.subscriptions.subscriptionPlans
export const subscriptionStatus = (state: State) => state.subscriptions.subscriptionStatus
export const error = (state: State) => state.subscriptions.error
export const isCreating = (state: State) => state.subscriptions.activeOperation === 'creating'
export const isUpdating = (state: State) => state.subscriptions.activeOperation === 'updating'
export const subscriptionPromoCode = (state: State) => state.subscriptions.promoCode

