import i18next, { ResourceLanguage, LanguageDetectorModule } from 'i18next'
import { useTranslation, initReactI18next } from 'react-i18next'

import * as Locale from './locale'

import en from './en.json'
import translations from './translations.json'
import ar from './ar.json'

const SUPPORTED_LOCALES: Array<Locale.Locale> = ['en', 'ar']

type Resource = Record<Locale.Locale, ResourceLanguage>

const DEFAULT_RESOURCES: Resource = {
  en: { label: en._display_name, translation: translations.en },
  ar: { label: ar._display_name, translation: translations.ar }
}

const getResources = (translations: any): Resource => ({
  en: { label: en._display_name, translation: translations.en },
  ar: { label: ar._display_name, translation: translations.ar }
})

const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: supportedDeviceLanguageOrEnglish,
  init: () => {
    console.log('Initing language detector')
  },
  cacheUserLanguage: (lng: string) => {
    console.log(`Skipping user language cache ${lng}`)
  }
}

export const init18next = (resources: string): void => {
  const config = {
    interpolation: {
      escapeValue: false
    },
    lng: 'en',
    fallbackLng: 'en',
    keySeparator: '.',
    returnEmptyString: false,
    resources: resources ? getResources(resources) : DEFAULT_RESOURCES
  }

  i18next
    .use(languageDetector)
    .use(initReactI18next)
    .init(config)
    .catch((reason: any) => console.warn('Failed init i18n', reason))
}

export const loadUserLocale = async (): Promise<void> => {
  const userLocale = await localStorage.getItem('locale')
  if (userLocale) {
    const locale = Locale.fromString(userLocale)
    setLocale(locale)
  } else {
    const deviceLocale = supportedDeviceLanguageOrEnglish()
    setLocale(deviceLocale)
  }
}

export async function setUserLocaleOverride(locale: Locale.Locale): Promise<void> {
  await setLocale(locale)
  await localStorage.setItem('locale', locale)
}

export const resetUserLocale = async (): Promise<void> => {
  await setLocale('en')
}

async function setLocale(locale: Locale.Locale) {
  return await i18next.changeLanguage(locale)
}

export function supportedDeviceLanguageOrEnglish(): Locale.Locale {
  const l = deviceLocale()
  const languageCode = l.replace('-', '_')
  const locale = Locale.fromString(languageCode)
  const result = SUPPORTED_LOCALES.includes(locale) ? locale : 'en'
  return result
}

const deviceLocale = (): string => {
  return navigator.language
}

export const enabledLocales = (): Array<{
  value: Locale.Locale
  label: string
}> => {
  return SUPPORTED_LOCALES.map(locale => {
    return {
      value: locale,
      label: DEFAULT_RESOURCES[locale].label as string
    }
  })
}

interface LocaleInfo {
  localeCode: string
  languageName: string
}

export const useLocaleInfo = (): LocaleInfo => {
  const {
    i18n: { language: localeCode }
  } = useTranslation()
  const locale = Locale.fromString(localeCode)
  const languageName = DEFAULT_RESOURCES[locale].label as string
  return { localeCode, languageName }
}

export default i18next
