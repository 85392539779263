import React, { FC } from 'react'
import { Divider, Drawer, IconButton, List } from '@mui/material'
import { Box, styled, useTheme } from '@mui/material'
import { SideBarNavItem } from 'src/components/Header/SideBarNavItem'
import LanguagePicker from 'src/components/LanguagePicker'
import { useTranslation } from 'react-i18next'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { ClassNameMap } from '@mui/styles'
import subscriptionsStore from 'src/store/subscriptions'
import { useSelector } from 'react-redux'

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start'
}))

interface SideDrawerMenuProps {
  handleCloseMenu: () => void
  anchorEl: null | HTMLElement
  classes: ClassNameMap<'sideNavContainer'>
}

export const SideBarNav: FC<SideDrawerMenuProps> = ({ handleCloseMenu, anchorEl, classes }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'header' })
  const theme = useTheme()
  const subscriptionStatus = useSelector(subscriptionsStore.selectors.subscriptionStatus)

  return (
    <Drawer anchor={'left'} open={!!anchorEl} onClose={handleCloseMenu}>
      <DrawerHeader>
        <IconButton sx={{ ml: { xs: 0, sm: 1 } }} onClick={handleCloseMenu}>
          {theme?.direction === 'ltr' ? (
            <ChevronLeftIcon fontSize='large' />
          ) : (
            <ChevronRightIcon fontSize='large' />
          )}
        </IconButton>
      </DrawerHeader>

      <Divider />

      <Box className={classes.sideNavContainer} role='presentation' onKeyDown={handleCloseMenu}>
        <List onClick={handleCloseMenu}>
          <SideBarNavItem link={'/profile'} Icon={AccountCircleIcon} label={t('tab_profile')} />

          <SideBarNavItem link={'/locations'} Icon={LocationOnIcon} label={t('tab_locations')} />

          {subscriptionStatus?.locationCreationIsFree ? null : (
            <SideBarNavItem
              link={'/subscriptions'}
              Icon={LocationOnIcon}
              label={t('tab_subscriptions')}
            />
          )}

          <SideBarNavItem link={'/promotions'} Icon={LocalOfferIcon} label={t('tab_promotions')} />
        </List>

        <Box>
          <Divider />
          <Box sx={{ p: 2, display: 'flex' }}>
            <LanguagePicker sx={{ width: '100%' }} />
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
