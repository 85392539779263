import React, { useEffect, ChangeEvent, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import AdminsList from 'src/components/AdminsList'

import { useNavigate } from 'react-router-dom'
import { Spinner } from 'src/components'
import { useSnackbar } from 'notistack'
import LogoutIcon from '@mui/icons-material/Logout'
import authStore from 'src/store/auth'
import RoundedButton from 'src/components/RoundedButton'
import { useTranslation } from 'react-i18next'
import usePrevious from 'src/hooks/usePrevious'

export default function MyProfile() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'my_profile' })

  const { enqueueSnackbar } = useSnackbar()

  const isCreating = useSelector(authStore.selectors.isSavingProfile)
  const user = useSelector(authStore.selectors.user)
  const editProfileData = useSelector(authStore.selectors.editProfileData)

  const isSavingProfile = useSelector(authStore.selectors.isSavingProfile)
  const error = useSelector(authStore.selectors.error)

  const prevIsSaving = usePrevious(isSavingProfile)

  const [isSaveDisabled, setIsSaveDisabled] = useState(false)

  useEffect(() => {
    if (prevIsSaving && !isSavingProfile) {
      enqueueSnackbar(error?.message || t('action_saved'), {
        variant: error ? 'error' : 'success',
        onExited: () => error && dispatch(authStore.actions.dismissError())
      })
    }
  }, [isSavingProfile])

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  const renderTitle = (title: string) => (
    <Typography
      component='h6'
      align='left'
      color={'text.secondary'}
      sx={{
        fontWeight: 400,
        mb: 1
      }}
    >
      {title}
    </Typography>
  )

  const handleBack = () => {
    navigate(-1)
  }

  const logout = () => dispatch(authStore.actions.logout({}))

  const firstName = user?.profile.fullName?.split(' ')?.[0] || ''
  const lastName = user?.profile.fullName?.split(' ')?.[1] || ''

  useEffect(() => {
    if (editProfileData.firstName && editProfileData.lastName) {
      setIsSaveDisabled(false)
    } else {
      setIsSaveDisabled(true)
    }
  }, [editProfileData.firstName, editProfileData.lastName])

  useEffect(() => {
    dispatch(
      authStore.actions.updateEditProfileData({
        firstName,
        lastName
      })
    )
  }, [])

  const handleTextFields = (fieldName: string) => (value: ChangeEvent<HTMLInputElement>) =>
    updateDraft(fieldName, value.currentTarget.value)

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      authStore.actions.updateEditProfileData({
        [fieldName]: value
      })
    )

  const save = () => {
    dispatch(authStore.actions.saveProfile.started())
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth={false}
        sx={{ mt: 0, pt: { xs: 0, sm: 4 }, pb: 4, backgroundColor: '#f2f2f2', maxWidth: 700 }}
      >
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography
            color={'text.secondary'}
            fontWeight={'500'}
            component='h3'
            variant='h4'
            align='left'
          >
            {t('title')}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                {renderTitle(t('input_first_name') + ' *')}
                <OutlinedInput
                  autoComplete='given-name'
                  name='firstName'
                  required
                  defaultValue={firstName}
                  fullWidth
                  onChange={handleTextFields('firstName')}
                  id='firstName'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderTitle(t('input_last_name') + ' *')}
                <OutlinedInput
                  required
                  fullWidth
                  id='lastName'
                  defaultValue={lastName}
                  name='lastName'
                  onChange={handleTextFields('lastName')}
                  autoComplete='family-name'
                />
              </Grid>
              <Grid item xs={12}>
                {renderTitle(t('input_email'))}
                <OutlinedInput
                  required
                  fullWidth
                  defaultValue={user?.email}
                  id='email'
                  name='email'
                  autoComplete='email'
                  onChange={handleTextFields('email')}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={save}
                label={t('btn_save')}
                disabled={isSaveDisabled}
              />
              <RoundedButton
                type='submit'
                fullWidth
                variant='outlined'
                sx={{ mb: 2 }}
                onClick={handleBack}
                label={t('btn_cancel')}
              />
              <Box
                display={'flex'}
                sx={{ cursor: 'pointer' }}
                alignSelf={'flex-end'}
                alignItems={'center'}
                onClick={logout}
              >
                <Typography component='h6' mr={2} color={'text.secondary'}>
                  {t('btn_logout')}
                </Typography>
                <IconButton>
                  <LogoutIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Spinner loading={isCreating} />
    </div>
  )
}
