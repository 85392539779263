import { ProviderLocationId, ProviderLocation } from './../../models/providerLocations'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action } from 'typescript-fsa'
import { enqueueSnackbar } from 'notistack'

import {
  fetchPlans,
  createSubscription,
  fetchSubscriptions,
  updateSubscription,
  fetchStatus
} from './actions'

import { subscriptionPromoCode } from './selectors'

import { API } from 'src/data'
import {
  SubscriptionPlansResponse,
  SubscriptionPlan,
  SubscriptionCreate,
  ProviderSubscriptionResponse,
  ProviderSubscription,
  ProviderSubscriptionStatus
} from 'src/models/subscriptions'
import auth from '../auth'
import { AuthChangePayload } from '../auth/types'
import { SubscriptionUpdateParams } from './types'

export default function createRootSaga() {
  function* fetchSubscriptionPlans({ payload }: Action<string | undefined>): SagaIterator {
    try {
      const { id } = yield select(auth.selectors.currentProvider)
      const { data }: SubscriptionPlansResponse = yield call(
        [API, API.readSubscriptionPlans],
        id,
        payload
      )
      if (payload) {
        enqueueSnackbar(
          'The promo code is successfully applied, the discount is being calculated',
          {
            variant: 'success'
          }
        )
      }
      yield put(fetchPlans.done({ result: data }))
    } catch (error) {
      if (error instanceof Error) {
        //@ts-ignore
        if (error.errorCode == 'auth/invalidSession') {
          yield put(auth.actions.logout({}))
        }
        enqueueSnackbar?.(error.message, {
          variant: 'error'
        })
        yield put(fetchPlans.failed({ error }))
      }
    }
  }

  function* fetchSubscriptionStatus(): SagaIterator {
    try {
      const { id } = yield select(auth.selectors.currentProvider)
      const data: ProviderSubscriptionStatus = yield call([API, API.readSubscriptionStatus], id)
      yield put(fetchStatus.done({ result: data }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchStatus.failed({ error }))
    }
  }

  function* createSubscriptionSaga({ payload }: Action<SubscriptionCreate>): SagaIterator {
    try {
      const { id } = yield select(auth.selectors.currentProvider)
      const promoCode: string | undefined = yield select(subscriptionPromoCode)

      yield call([API, API.createSubscription], id, {
        ...payload,
        promoCode: promoCode ? promoCode : undefined
      })
      yield put(createSubscription.done({ params: payload }))
    } catch (error) {
      if (error instanceof Error) yield put(createSubscription.failed({ params: payload, error }))
    }
  }

  function* fetchSubscriptionsSaga(): SagaIterator {
    try {
      const { id } = yield select(auth.selectors.currentProvider)
      const response: ProviderSubscriptionResponse = yield call(
        [API, API.readProviderSubscriptions],
        id
      )
      yield put(fetchSubscriptions.done({ result: response }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchSubscriptions.failed({ error }))
    }
  }

  function* updateSubscriptionSaga({ payload }: Action<SubscriptionUpdateParams>): SagaIterator {
    try {
      const { id } = yield select(auth.selectors.currentProvider)
      const result: ProviderSubscription = yield call(
        [API, API.updateProviderSubscription],
        id,
        payload.id,
        payload.params
      )
      yield call(fetchSubscriptionsSaga)
      yield put(updateSubscription.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(updateSubscription.failed({ params: payload, error }))
    }
  }

  function* authChangeSaga(): SagaIterator {
    try {
      const promoCode: string | undefined = yield select(subscriptionPromoCode)
      const currentProvider = yield select(auth.selectors.currentProvider)
      if (currentProvider?.role === 'owner') {
        yield put(fetchPlans.started(promoCode))
        yield put(fetchStatus.started())
        yield put(fetchSubscriptions.started())
      }
    } catch (error) {}
  }

  return [
    takeLatest(auth.actions.authChange, authChangeSaga),
    takeLatest(fetchStatus.started, fetchSubscriptionStatus),
    takeLatest(fetchPlans.started, fetchSubscriptionPlans),
    takeLatest(fetchSubscriptions.started, fetchSubscriptionsSaga),
    takeLatest(createSubscription.started, createSubscriptionSaga),
    takeLatest(updateSubscription.started, updateSubscriptionSaga)
  ]
}
