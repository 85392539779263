import React, { useEffect, useState, ChangeEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import RoundedButton from 'src/components/RoundedButton'

import adminsStore from 'src/store/admins'
import { useTranslation } from 'react-i18next'

import usePrevious from 'src/hooks/usePrevious'

export default function AddAdmin() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'add_admin' })

  const location = useLocation()
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isCreating = useSelector(adminsStore.selectors.isCreating)
  const createError = useSelector(adminsStore.selectors.createError)
  const prevIsCreating = usePrevious(isCreating)

  useEffect(() => {
    if (createError) {
      enqueueSnackbar(createError?.message, {
        variant: 'error',
        onExited: () => dispatch(adminsStore.actions.dismissError())
      })
    }
  }, [createError])

  const handleBack = () => {
    navigate(-1)
  }

  const handleNameInput = (value: ChangeEvent<HTMLInputElement>) =>
    setName(value.currentTarget.value)

  useEffect(() => {
    if (!!isCreating !== !!prevIsCreating && !isCreating && !createError) {
      handleBack()
      enqueueSnackbar('Saved!', {
        variant: 'success'
      })
    }
  }, [prevIsCreating])

  const save = () => {
    dispatch(
      adminsStore.actions.add.started({
        phoneNumber,
        name
      })
    )
  }

  const renderTitle = (title: string) => (
    <Typography
      component='h6'
      align='left'
      color={'text.secondary'}
      sx={{
        fontWeight: 400,
        mb: 1
      }}
    >
      {title}
    </Typography>
  )

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth='sm'
        sx={{ mt: 0, pt: 4, pb: 4, backgroundColor: '#f2f2f2' }}
      >
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography
            color={'text.secondary'}
            fontWeight={'500'}
            component='h3'
            variant='h4'
            align='left'
          >
            {t('title')}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {renderTitle(t('input_admin_name'))}
                <OutlinedInput
                  autoComplete='given-name'
                  required
                  value={name}
                  fullWidth
                  onChange={handleNameInput}
                  autoFocus
                />
              </Grid>

              <Grid item xs={12}>
                {renderTitle(t('input_phone_number'))}
                <PhoneNumberInput
                  defaultValue={phoneNumber}
                  onChangePhone={setPhoneNumber}
                  onEnterKeyPress={save}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                type='submit'
                fullWidth
                disabled={!phoneNumber || !name}
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={save}
                label={t('btn_save')}
              />

              <RoundedButton
                type='submit'
                fullWidth
                variant='outlined'
                sx={{ mb: 2 }}
                onClick={handleBack}
                label={t('btn_cancel')}
              />
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}
