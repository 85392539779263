import React, { useEffect, useState, ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import createLocation from 'src/store/createLocation'
import Grid from '@mui/material/Grid'
import { MapPicker } from 'src/components'
import FilesDropzone from 'src/components/FilesDropzone'
import OutlinedInput from '@mui/material/OutlinedInput'
import PhoneNumberInput from 'src/components/PhoneNumberInput'
import { PlaceInfo } from 'src/components/MapPicker/google/types'
import { ProviderLocation, ProviderLocationId } from 'src/models/providerLocations'
import { isEmpty } from 'lodash'
import AdminsList from './LocationAdminsList'
import TextLabel from 'src/components/TextLabel'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import { Box } from '@mui/material'

import ProviderLocationStatus from 'src/components/Statuses/ProviderLocationStatus'
import locationAdminsStore from 'src/store/locationAdmins'
import { useSnackbar } from 'notistack'

interface Props {
  locationId: ProviderLocationId
  providerLocation: ProviderLocation
}

const urlRegex = RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i
)

export default function LocationForm({ providerLocation, locationId }: Props) {
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'create_location' })
  const { enqueueSnackbar } = useSnackbar()
  const adminError = useSelector(locationAdminsStore.selectors.error)
  const [hasAccessToAdminsSection, setHasAccessToAdminsSection] = useState(true)

  const [isFieldValid, setIsFieldValid] = useState({
    websiteUrl: true,
    socialMediaUrl: true
  })

  useEffect(() => {
    if (adminError) {
      if ((adminError as any).errorCode === 'auth/notAuthorized') {
        setHasAccessToAdminsSection(false)

        dispatch(locationAdminsStore.actions.dismissError())
      } else {
        enqueueSnackbar(adminError?.message, {
          variant: 'error',
          onExited: () => dispatch(locationAdminsStore.actions.dismissError())
        })
      }
    }
  }, [adminError])

  const draft = useSelector((state: any) =>
    createLocation.selectors.currentDraft(state, locationId || 'new')
  )

  const handleChangeLocation = (placeInfo: PlaceInfo) => {
    dispatch(
      createLocation.actions.updateDraft({
        id: locationId,
        location: placeInfo.location,
        address: placeInfo.fullAddress,
        googlePlaceId: placeInfo.placeId
      })
    )
  }

  const handleTextFields = (fieldName: string) => (value: ChangeEvent<HTMLInputElement>) =>
    updateDraft(fieldName, value.currentTarget.value)

  const handlePhoneNumber = (value: string) => updateDraft('contactPhoneNumber', value)

  const updateDraft = (fieldName: string, value: any) =>
    dispatch(
      createLocation.actions.updateDraft({
        id: locationId,
        [fieldName]: value
      })
    )

  useMemo(() => {
    if (draft?.websiteUrl && !urlRegex.test(draft.websiteUrl)) {
      setIsFieldValid(prev => ({ ...prev, websiteUrl: false }))
    } else {
      setIsFieldValid(prev => ({ ...prev, websiteUrl: true }))
    }
  }, [draft?.websiteUrl])

  useMemo(() => {
    if (draft?.socialMediaUrl && !urlRegex.test(draft.socialMediaUrl)) {
      setIsFieldValid(prev => ({ ...prev, socialMediaUrl: false }))
    } else {
      setIsFieldValid(prev => ({ ...prev, socialMediaUrl: true }))
    }
  }, [draft?.socialMediaUrl])

  if (locationId && isEmpty(draft)) {
    return null
  }

  const onRemove = () => {
    if (locationId) {
      dispatch(createLocation.actions.edit(providerLocation))
    } else {
      updateDraft('image', undefined)
    }
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        {locationId && providerLocation ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <TextLabel title={t('input_current_status')} />
              <ProviderLocationStatus location={providerLocation} type={'form'} />
            </Box>
          </Grid>
        ) : null}

        <Grid item xs={12}>
          <TextLabel title={t('input_name_title') + ' *'} />
          <OutlinedInput
            required
            defaultValue={draft?.name}
            id='name'
            name='name'
            placeholder={t('input_name_placeholder')}
            fullWidth
            sx={{ mb: 2 }}
            onChange={handleTextFields('name')}
          />
          <FilesDropzone uploadType={'location-image'} />
        </Grid>

        <Grid item xs={12}>
          <TextLabel title={t('form_contacts_label') + ' *'} />
          <PhoneNumberInput
            defaultValue={draft?.contactPhoneNumber!}
            onChangePhone={handlePhoneNumber}
          />
          <TextLabel sx={{ mt: 2 }} title={t('input_phone_email') + ' *'} />
          <OutlinedInput
            id='email'
            name='email'
            defaultValue={draft?.contactEmail}
            placeholder={t('input_phone_email') + ' *'}
            fullWidth
            onChange={handleTextFields('contactEmail')}
          />
          <TextLabel sx={{ mt: 2 }} title={t('input_website_url')} />
          <OutlinedInput
            id='websiteUrl'
            name='websiteUrl'
            defaultValue={draft?.websiteUrl}
            placeholder={t('input_website_url')}
            color={!isFieldValid.websiteUrl ? 'error' : 'primary'}
            fullWidth
            onChange={handleTextFields('websiteUrl')}
          />
          {!isFieldValid.websiteUrl ? (
            <Typography sx={{ color: red[500], fontWeight: 500, fontSize: 14 }}>
              {t('input_website_url_incorrect')}
            </Typography>
          ) : (
            <></>
          )}
          <TextLabel sx={{ mt: 2 }} title={t('input_social_media_url')} />
          <OutlinedInput
            id='socialMediaUrl'
            name='socialMediaUrl'
            defaultValue={draft?.socialMediaUrl}
            placeholder={t('input_social_media_url')}
            color={!isFieldValid.websiteUrl ? 'error' : 'primary'}
            fullWidth
            onChange={handleTextFields('socialMediaUrl')}
          />
          {!isFieldValid.socialMediaUrl ? (
            <Typography sx={{ color: red[500], fontWeight: 500, fontSize: 14 }}>
              {t('input_social_media_url_incorrect')}
            </Typography>
          ) : (
            <></>
          )}
        </Grid>

        <Grid item xs={12}>
          <TextLabel title={t('form_label_location') + ' *'} />
          <MapPicker
            changeLocation={handleChangeLocation}
            location={draft?.location}
            address={draft?.address}
          />
        </Grid>

        {hasAccessToAdminsSection && <AdminsList locationId={locationId} />}
      </Grid>
    </React.Fragment>
  )
}
