import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface ChartData {
  dates: string[]
  quantities: number[]
  color?: string
}

const LineChart: React.FC<ChartData> = ({ dates, quantities, color }) => {
  const data = {
    labels: dates,
    datasets: [
      {
        label: 'Quantity',
        data: quantities,
        fill: false,
        yValue: 0,
        backgroundColor: color || '#293845',
        borderColor: color || 'rgba(75, 192, 192, 0.7)',
        borderWidth: 3,
        pointRadius: 6
      }
    ]
  }

  return <Line data={data} />
}

export default LineChart
