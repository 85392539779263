import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Icon from '@mui/material/Icon'
import EditIcon from 'src/assets/edit.svg'
import Fab from '@mui/material/Fab'

interface Props {
  text: string
  onClick: (e: any) => void
}

export default function ButtonEdit({ text, onClick }: Props) {
  return (
    <Box
      onClick={onClick}
      display='flex'
      flexDirection='row'
      mt={2}
      alignItems='center'
      sx={{
        cursor: 'pointer'
      }}
    >
      <Fab
        size='small'
        sx={{
          backgroundColor: 'white',
          border: '1px solid #DFDFDF',
          elevation: 0,
          boxShadow: 'none'
        }}
      >
        <Icon>
          <img src={EditIcon} height={25} width={25} />
        </Icon>
      </Fab>
      <Typography ml={2} color='text.secondary' fontStyle='normal' fontWeight='400' fontSize='14px'>
        {text}
      </Typography>
    </Box>
  )
}
