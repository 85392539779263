import React, { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Card,
  Grid,
  CardContent,
  Box,
  CircularProgress
} from '@mui/material'
import { useParams } from 'react-router-dom'
import store from 'src/store/adminPreview'
import { useDispatch, useSelector } from 'react-redux'
import SliderContainer from './components/ImagesSlider'
import DiscountLabel from 'src/components/Promotion/DiscountLabel'
import Status from './components/Status'
import WeekHours from 'src/pages/PromotionPreview/WeekHours'
import InfoItem from './components/InfoItem'
import Receipt from '@mui/icons-material/Receipt'
import ProviderLocationView from './ProviderLocation/ProviderLocationView'

const App: React.FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const data = useSelector(store.selectors.promotion)!
  useEffect(() => {
    dispatch(store.actions.fetchPromotion.started(id!))
  }, [])

  if (!data) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        minHeight='100vh' // Adjust the height as needed
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Grid container spacing={1}>
        <Container sx={{ mt: 3 }} component='main' maxWidth='sm'>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5'>Promotion Details</Typography>
                </Grid>

                <Grid item xs={12}>
                  <SliderContainer images={data.images} />
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight='bold' variant='h4'>
                    {data.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} direction='row'>
                  <Status status={data.status} />
                </Grid>
                <Grid item xs={12} direction='row'>
                  <InfoItem
                    icon={<Receipt />}
                    label='Foodics'
                    value={data.foodicsRewardIsEnabled ? 'Enabled' : 'Disabled'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DiscountLabel data={data} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    textAlign={'center'}
                    fontFamily={'Share'}
                    letterSpacing={'-0.025em'}
                    fontWeight={'400'}
                    sx={{ color: '#293845', opacity: 0.7 }}
                  >
                    {data.text}
                  </Typography>
                </Grid>
                <Grid container xs={12} alignItems={'center'} justifyContent={'center'}>
                  <WeekHours
                    discountSchedule={data.discountSchedule}
                    discountAvailableAtAnyTime={data.discountAvailableAtAnyTime}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <ProviderLocationView data={data.location!} />
      </Grid>
    </div>
  )
}

export default App
