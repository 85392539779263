import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { setStatus, fetchTranslations } from './actions'
import { StateRemoteConfig } from 'src/store/remoteConfig/types';

const initialState: StateRemoteConfig = {
 
}

export function remoteConfig(
  state: StateRemoteConfig = initialState,
  action: Action
): StateRemoteConfig {
  if (isType(action, setStatus)) {
    return {
      ...state,
      isReady: true
    }
  }

  if (isType(action, fetchTranslations)) {
    return {
      ...state,
      prevTranslationsUrl: action.payload
    }
  }

  return state
}
