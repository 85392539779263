import { ProviderLocationId } from 'src/models/providerLocations';
import actionCreatorFactory from 'typescript-fsa'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { CreateLocationAdminParams, RemoveLocationAdminParams } from './types'


// Actions
const actionCreator = actionCreatorFactory('locationAdmins')

export const fetch = actionCreator.async<ProviderLocationId, Admin[], Error>('FETCH')
export const add = actionCreator.async<CreateLocationAdminParams, Admin, Error>('ADD')
export const addToDraft = actionCreator<ProviderAdminCreate>('ADD_TO_DRAFT')
export const remove = actionCreator.async<RemoveLocationAdminParams, void, Error>('REMOVE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
export const clearDraft = actionCreator<void>('CLEAR_DRAFT')
