import { Promotion, PromotionCreate, PromotionType } from 'src/models/promotions'
import { call, put, take, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import { Action } from 'typescript-fsa'

import { State } from 'src/models'
import { create } from './actions'
import { PromotionDraftUpdate } from './types'
import i18n from 'i18next'
import { API } from 'src/data'
import mediaUploads from 'src/store/mediaUploads'
import { MediaUpload, MediaUploadList } from '../mediaUploads/types'
import { MediaFile } from 'src/models/uploads'

export default function createRootSaga() {
  function* createPromotionSaga({ payload }: Action<string | undefined>): SagaIterator {
    try {
      let mediaUploadsInProgress: boolean = yield select(
        mediaUploads.selectors.mediaUploadsInProgress
      )
      while (mediaUploadsInProgress) {
        const update: Action<MediaUpload> = yield take(mediaUploads.actions.mediaUploadUpdate)
        mediaUploadsInProgress = yield select(mediaUploads.selectors.mediaUploadsInProgress)
      }

      const uploadedMedia: MediaUploadList = yield select(mediaUploads.selectors.mediaUploads)

      const data: PromotionDraftUpdate = yield select(
        (state: State) => state.createPromotion.drafts[payload || 'new']
      )
      const draft = {
        ...data
      }

      // if (!draft?.image) {
      //   throw Error(i18n.t('errors.empty_image'))
      // }

      if (draft?.type === PromotionType.percentageDiscount) {
        if (!draft?.discountPercentage) {
          throw Error(i18n.t('errors.empty_discount_percentage'))
        }

        if (+draft?.discountPercentage <= 0) {
          throw Error(i18n.t('errors.discount_percentage_greater_than_zero'))
        }

        if (+draft?.discountPercentage > 100) {
          throw Error(i18n.t('errors.discount_percentage_greater'))
        }
      } else if (draft?.type === PromotionType.fixedDiscount) {
        if (!draft?.fixedDiscount) {
          throw Error(i18n.t('errors.empty_fixed_discount'))
        }
      }

      if (!draft?.title) {
        throw Error(i18n.t('errors.empty_promotion_title'))
      } else {
        // If title exists, then we are updating promotion (not just it's status)
        // so, we delete status from this request, because it's updated separately
        delete draft.status
      }

      if (draft?.title?.length < 4) {
        throw Error(i18n.t('errors.promotion_title_length'))
      }

      if (!draft?.type) {
        throw Error(i18n.t('errors.empty_promotion_type'))
      }

      if (!draft?.locationId) {
        throw Error(i18n.t('errors.promotion_empty_location'))
      }

      if (!draft?.text) {
        throw Error(i18n.t('errors.empty_description'))
      }

      if (draft.type === 'percentage_discount' && !draft?.maxDiscountAmount) {
        throw Error(i18n.t('Enter the maximum discount amount in currency'))
      }

      if (draft?.image && draft?.image instanceof File) {
        //   const { uploadHandle } = yield call(uploadMediaSaga, draft.image, 'promotion-image')
        //  draft.image = uploadHandle
      } else if (draft.image) {
        draft.image = null
      }

      const images = uploadedMedia.map((item, index) =>
        (item as MediaFile).url
          ? { _type: 'PromotionImageReorder', fileId: (item as MediaFile).id, order: index }
          : {
              _type: 'PromotionImageCreate',
              uploadHandle: (item as MediaUpload).uploadHandle,
              order: index
            }
      )

      const body = {
        ...draft,
        discountPercentage:
          draft.type === 'percentage_discount' ? +draft.discountPercentage! : undefined,
        fixedDiscount: draft.type === 'fixed_discount' ? +draft.fixedDiscount! : undefined,
        startDate: draft.startDate,
        images,
        discountSchedule: !draft.discountAvailableAtAnyTime
          ? Object.values(draft?.discountSchedule || {})
              .filter(item => item.isSelected)
              .map(item => {
                const startTime = item?.startTime?.split(':')
                const endTime = item?.endTime?.split(':')
                return {
                  ...item,
                  startTime: +`${startTime?.[0]}.${startTime?.[1]}`,
                  endTime: +`${endTime?.[0]}.${endTime?.[1]}`
                }
              })
          : undefined,
        foodicsRewardIsEnabled: draft.foodicsRewardIsEnabled ? true : false,
        maxDiscountAmount:
          draft.type === 'percentage_discount' ? +draft.maxDiscountAmount! : undefined
      } as PromotionCreate
      /*
      Object.entries(promotionsTypes)
        .filter(([key, value]) => value !== body.type)
        .map(([key, value]) => {
          ///@ts-ignore
          body[`${key}`] = null
        })
        */
      let result: Promotion
      if (payload) {
        result = yield call([API, API.updatePromotion], body, draft?.locationId!, payload)
      } else {
        result = yield call([API, API.createPromotion], body, draft?.locationId!)
      }

      yield put(
        create.done({
          params: payload,
          result: {
            result,
            locationId: draft?.locationId!
          }
        })
      )
    } catch (error) {
      if (error instanceof Error) yield put(create.failed({ error }))
    }
  }

  return [takeLatest(create.started, createPromotionSaga)]
}
