// ProviderComponent.tsx
import React, { useState } from 'react'
import { AdminProviderInfo } from 'src/models/providerInfo'
import LinkIcon from '@mui/icons-material/Link'
import LinkOffIcon from '@mui/icons-material/LinkOff'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import Link from '@mui/material/Link'

import { Card, CardContent, Paper, Typography, Grid, Box } from '@mui/material'
import { enqueueSnackbar } from 'notistack'

interface ProviderProps {
  data: AdminProviderInfo
  onPress: () => void
}

const FoodicsItem = ({ title, value }: { title: string; value: boolean }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='subtitle1'>{title} </Typography>
      <Typography
        variant='subtitle1'
        component='span'
        color={value ? '#39a1ab' : 'warning'}
        style={{ fontWeight: 'bold', marginLeft: 4 }}
      >
        {value ? 'Enabled' : 'Disabled'}
      </Typography>
      {value ? (
        <LinkIcon style={{ marginLeft: 8 }} color='primary' />
      ) : (
        <LinkOffIcon style={{ marginLeft: 8 }} color='warning' />
      )}
    </Box>
  )
}

const BaseItem = ({ title, value }: { title: string; value: string | number }) => {
  const isBlue = value === 'Yes' || value === 'Enabled' || Number(value) > 0

  return (
    <Typography variant='subtitle1'>
      {title}{' '}
      <Typography
        variant='h6'
        component='span'
        color={isBlue ? '#39a1ab' : 'inherit'}
        style={{ fontWeight: 'bold' }}
      >
        {value}
      </Typography>
    </Typography>
  )
}

const handleLinkClick = (value: string) => (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault()
  e.stopPropagation()
  enqueueSnackbar('Copied!', {
    variant: 'success'
  })
  navigator.clipboard.writeText(value)
}

const ContactItem = ({
  title,
  value,
  href,
  onPress
}: {
  title: string
  value: string
  href: string
  onPress: (e: React.MouseEvent<HTMLElement>) => void
}) => {
  return (
    <Box display='flex' alignItems='center'>
      <Typography variant='subtitle1'>{title} </Typography>
      <Link
        href={href}
        variant='subtitle1'
        component='span'
        onClick={onPress}
        color={value ? '#39a1ab' : 'warning'}
        style={{ fontWeight: 'bold', marginLeft: 4 }}
      >
        {' '}
        {value}
      </Link>
      <LocalPhoneIcon style={{ marginLeft: 8 }} color='primary' />
    </Box>
  )
}

const ProviderComponent: React.FC<ProviderProps> = ({ data, onPress }) => {
  const { provider, subscriptionStatus, locations, promotions } = data
  const owner = provider.owners?.[0]
  const { phoneNumber, email } = owner || {}

  const [isSelecting, setIsSelecting] = useState(false)

  const handleMouseDown = () => {
    setIsSelecting(true)
  }

  const handleMouseUp = () => {
    setIsSelecting(false)
  }

  const handleClick = () => {
    if (!isSelecting) {
      onPress()
    }
  }
  return (
    <Card
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      sx={{ mb: 2 }}
      raised
    >
      <CardContent>
        <Typography variant='h6'>
          Provider Name: <strong>{provider.name}</strong>
        </Typography>
        <hr />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant='subtitle1'>
              Provider ID: <strong>{provider.id}</strong>
            </Typography>

            <Typography variant='subtitle1'>
              Subscription Country:{' '}
              <span style={{ fontSize: 20 }}>
                {provider.subscriptionCountry === 'US'
                  ? '\uD83C\uDDFA\uD83C\uDDF8'
                  : '\uD83C\uDF0D'}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <FoodicsItem title='Foodics:' value={!!provider.integrations?.foodics} />
            <Typography variant='subtitle1'>
              Total Locations: <strong>{data.locations.totalCount}</strong>
            </Typography>
            <Typography variant='subtitle1'>
              Total Promotions: <strong>{data.promotions.totalCount}</strong>
            </Typography>
          </Grid>
        </Grid>
        {phoneNumber ? (
          <ContactItem
            href={`tel:${phoneNumber}`}
            title={'Owner phone number: '}
            value={phoneNumber}
            onPress={handleLinkClick(phoneNumber)}
          />
        ) : null}
        {email ? (
          <ContactItem
            href={`mailto:${email}`}
            title={'Owner contact email: '}
            value={email}
            onPress={handleLinkClick(email)}
          />
        ) : null}

        <hr />
        <Typography gutterBottom variant='h6' component='div' style={{ marginTop: '16px' }}>
          Subscription Status:
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <BaseItem
              title='Location Creation is Free:'
              value={subscriptionStatus.locationCreationIsFree ? 'Yes' : 'No'}
            />

            <BaseItem
              title='Location Creation Allowed:'
              value={subscriptionStatus.locationCreationAllowed ? 'Yes' : 'No'}
            />

            <BaseItem
              title='Locations Count Available:'
              value={subscriptionStatus.locationsCountAvailable}
            />

            <BaseItem
              title='Paid Invoices Total:'
              value={subscriptionStatus.paidInvoicesTotal || 0}
            />
          </Grid>
          <Grid item xs={6}>
            <BaseItem title='Locations Count Used:' value={subscriptionStatus.locationsCountUsed} />
            <BaseItem title='Locations Count Left:' value={subscriptionStatus.locationsCountLeft} />
            <BaseItem
              title='Trialing Subscriptions Count:'
              value={subscriptionStatus.trialingSubscriptionsCount}
            />
            <BaseItem
              title='Active Subscriptions Count:'
              value={data.subscriptionStatus.activeSubscriptionsCount}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default ProviderComponent
