import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  fetchPlans,
  fetchSubscriptions,
  dismissError,
  createSubscription,
  fetchStatus,
  updateSubscription
} from './actions'
import { logout } from '../auth/actions'

// Types imports
import { StateSubscriptions } from './types'

const initialState: StateSubscriptions = {
  subscriptionPlans: [],
  subscriptions: {
    list: []
  }
}

export function subscriptions(
  state: StateSubscriptions = initialState,
  action: Action
): StateSubscriptions {
  if (isType(action, fetchPlans.started)) {
    return {
      ...state,
      promoCode: action.payload ? action.payload : state.promoCode
    }
  }

  if (isType(action, fetchPlans.done)) {
    const subscriptionPlans = action.payload.result
    return {
      ...state,
      subscriptionPlans
    }
  }

  if (isType(action, fetchSubscriptions.done)) {
    const data = action.payload.result
    return {
      ...state,
      activeOperation: undefined,
      subscriptions: {
        ...state.subscriptions,
        list: data.data
      }
    }
  }

  if (isType(action, updateSubscription.started)) {
    return {
      ...state,
      activeOperation: 'updating'
    }
  }

  if (isType(action, createSubscription.started)) {
    return {
      ...state,
      activeOperation: 'creating'
    }
  }

  if (isType(action, createSubscription.done)) {
    return {
      ...state,
      activeOperation: undefined
    }
  }

  if (isType(action, updateSubscription.done)) {
    return {
      ...state,
      activeOperation: undefined
    }
  }

  if (isType(action, updateSubscription.failed) || isType(action, createSubscription.failed)) {
    return {
      ...state,
      activeOperation: undefined,
      error: action.payload.error
    }
  }
  if (isType(action, dismissError)) {
    return {
      ...state,
      activeOperation: undefined,
      error: undefined
    }
  }

  if (isType(action, fetchStatus.done)) {
    return {
      ...state,
      subscriptionStatus: action.payload.result
    }
  }

  if (isType(action, logout)) {
    return initialState
  }

  return state
}
