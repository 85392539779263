import { ProviderLocationId } from './../../models/providerLocations'
import { call, put, select, take, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'

import { Action } from 'typescript-fsa'

import { State } from 'src/models'
import { createLocation } from './actions'
import { ProviderLocation, LocationCreate } from 'src/models/providerLocations'
import { UploadType, Upload, File, MediaFile } from 'src/models/uploads'
import { API, fileUploader } from 'src/data'
import { createAdminsSaga } from 'src/store/locationAdmins/sagas'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import i18n from 'i18next'
import mediaUploads from 'src/store/mediaUploads'
import { MediaUpload, MediaUploadList } from '../mediaUploads/types'

export default function createRootSaga() {
  function* createOrUpdateLocationSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      let mediaUploadsInProgress: boolean = yield select(
        mediaUploads.selectors.mediaUploadsInProgress
      )
      while (mediaUploadsInProgress) {
        const update: Action<MediaUpload> = yield take(mediaUploads.actions.mediaUploadUpdate)
        mediaUploadsInProgress = yield select(mediaUploads.selectors.mediaUploadsInProgress)
      }

      const uploadedMedia: MediaUploadList = yield select(mediaUploads.selectors.mediaUploads)

      const data: LocationCreate = yield select(
        (state: State) => state.createLocation.drafts[payload || 'new']
      )
      const draft = {
        ...data,
        contactEmail: data.contactEmail && data.contactEmail.trim()
      }

      if (!uploadedMedia.length) {
        throw Error(i18n.t('errors.empty_image'))
      }

      if (!draft?.location) {
        throw Error(i18n.t('errors.empty_location'))
      }

      if (!draft?.name) {
        throw Error(i18n.t('errors.empty_name'))
      } else {
        // If name exists, then we are updating location (not just it's status)
        // so, we delete status from this request, because it's updated separately
        delete draft.status
      }

      if (!draft?.contactEmail) {
        throw Error(i18n.t('errors.empty_email'))
      }

      if (draft?.contactPhoneNumber && draft?.contactPhoneNumber?.length < 4) {
        throw Error(i18n.t('errors.empty_phone_number'))
      }

      if (!parsePhoneNumberFromString('+' + draft!.contactPhoneNumber!)?.isValid()) {
        throw Error(i18n.t('errors.incorrect_phone_number'))
      }

      if (draft.image) {
        draft.image = null // TO DO
      }

      if (draft.googlePlaceId === null) {
        draft.googlePlaceId = undefined
      }

      const images = uploadedMedia.map((item, index) =>
        (item as MediaFile).url
          ? { _type: 'ProviderLocationImageReorder', fileId: (item as MediaFile).id, order: index }
          : {
              _type: 'ProviderLocationImageCreate',
              uploadHandle: (item as MediaUpload).uploadHandle,
              order: index
            }
      )
      const body = {
        ...draft,
        websiteUrl: draft?.websiteUrl ? draft.websiteUrl.trim() : null,
        socialMediaUrl: draft?.socialMediaUrl ? draft.socialMediaUrl.trim() : null,
        images
      } as LocationCreate

      let result: ProviderLocation
      if (payload) {
        result = yield call([API, API.updateLocation], body, payload)
      } else {
        result = yield call([API, API.createLocation], body)
      }
      yield call(createAdminsSaga, result.id!)

      yield put(
        createLocation.done({
          params: payload,
          result
        })
      )
    } catch (error) {
      console.log((error as any).response)
      if (error instanceof Error) yield put(createLocation.failed({ params: payload, error }))
    }
  }

  return [takeLatest(createLocation.started, createOrUpdateLocationSaga)]
}
