import React, { ReactElement, useEffect, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js'
import useCountryCode from 'src/hooks/useCountryCode'
import { useTranslation } from 'react-i18next'
import ar from 'react-phone-input-2/lang/ar.json'

function isPhoneValid(number: string, country: CountryCode) {
  try {
    return parsePhoneNumber(`+${number}`, country).isValid()
  } catch (e) {
    return false
  }
}

interface Props {
  defaultValue?: string
  onChangePhone: (phone: string) => void
  onEnterKeyPress?: () => void
  disableDefaultCountryCode?: boolean
}

export default function PhoneNumberInput({
  onChangePhone,
  onEnterKeyPress,
  disableDefaultCountryCode,
  defaultValue
}: Props): ReactElement {
  const [, i18n] = useTranslation()

  const [value, setValue] = useState(defaultValue || '')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (isValid) onChangePhone(value)
  }, [isValid, value])

  const handleChange = (phone: string) => {
    if (phone) {
      setValue(phone)
    }
  }

  const code = useCountryCode()?.toLowerCase()

  return (
    <PhoneInput
      country={disableDefaultCountryCode ? undefined : value ? undefined : code}
      value={value}
      containerStyle={{
        direction: 'ltr',
        width: '100%'
      }}
      buttonStyle={{
        direction: 'ltr'
      }}
      inputStyle={{
        direction: 'ltr',
        width: '100%'
      }}
      inputProps={{
        inputMode: 'tel'
      }}
      placeholder=''
      localization={i18n.language === 'en' ? undefined : ar}
      countryCodeEditable
      onChange={handleChange}
      onEnterKeyPress={onEnterKeyPress}
      specialLabel=''
      isValid={(inputNumber, country: any) => {
        const res = isPhoneValid(inputNumber, country?.iso2?.toUpperCase() as CountryCode)
        setIsValid(res)
        return inputNumber.length > 4 ? res : true
      }}
    />
  )
}
