import React, { useState } from 'react'
import { TextField, Grid } from '@mui/material'

const DateRangePicker: React.FC = () => {
  const [dateStart, setDateStart] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')

  const handleDateStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateStart(event.target.value)
  }

  const handleDateEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateEnd(event.target.value)
  }

  return (
    <Grid container spacing={2} justifyContent='flex-end'>
      <Grid item>
        <TextField
          label='Start Date'
          type='date'
          value={dateStart}
          onChange={handleDateStartChange}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          label='End Date'
          type='date'
          value={dateEnd}
          onChange={handleDateEndChange}
          InputLabelProps={{
            shrink: true
          }}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangePicker
