import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { add, remove, fetch, dismissError, addToDraft, clearDraft } from './actions'
import omitBy from 'lodash/omitBy'
import keyBy from 'lodash/keyBy'
import { Admin } from 'src/models/admins'

// Types imports
import { StateLocationAdmins } from './types'

const initialState: StateLocationAdmins = {
  list: {},
  draft: {},
  isCreating: false,
  isRemoving: false,
  isFetching: false,
  error: undefined
}

export function locationAdmins(
  state: StateLocationAdmins = initialState,
  action: Action
): StateLocationAdmins {
  if (isType(action, add.started)) {
    return {
      ...state,
      isCreating: true,
      error: undefined
    }
  }

  if (isType(action, add.done)) {
    const data = action.payload.result
    return {
      ...state,
      list: {
        ...state.list,
        [data.providerId]: data
      },
      isCreating: false,
      error: undefined
    }
  }

  if (isType(action, add.failed)) {
    return {
      ...state,
      error: action.payload.error
    }
  }

  if (isType(action, addToDraft)) {
    const data = action.payload
    return {
      ...state,
      draft: {
        ...state.draft,
        [data.phoneNumber]: data
      }
    }
  }

  if (isType(action, fetch.started)) {
    return {
      ...state,
      isFetching: true
    }
  }

  if (isType(action, fetch.done)) {
    const locationId = action.payload.params
    const data = keyBy(action.payload.result, 'id')
    return {
      ...state,
      isFetching: false,
      list: { ...state.list, [locationId]: data }
    }
  }

  if (isType(action, fetch.failed)) {
    return {
      ...state,
      isFetching: false,
      error: action.payload.error
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      isRemoving: true
    }
  }

  if (isType(action, remove.done)) {
    const { locationId, admin } = action.payload.params
    if (locationId) {
      return {
        ...state,
        isRemoving: false,
        list: {
          ...state.list,
          [locationId]: omitBy(state.list[locationId], ({ id }) => id === (admin as Admin)?.id)
        }
      }
    } else {
      return {
        ...state,
        isRemoving: false,
        draft: omitBy(state.draft, ({ phoneNumber }) => phoneNumber === admin.phoneNumber)
      }
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,
      isRemoving: false,
      error: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }
  if (isType(action, clearDraft)) {
    return {
      ...state,
      error: undefined,
      draft: {}
    }
  }

  return state
}
