import Grid from '@mui/material/Grid'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authStore from 'src/store/auth'
import { SectionTitle } from 'src/components/SectionTitle'
import { useTranslation } from 'react-i18next'
import foodicsStore from 'src/store/foodics'
import { FoodicsNotConnected } from 'src/components/Foodics/FoodicsNotConnected'
import { FoodicsUserCard } from 'src/components/Foodics/FoodicsUserCard'
import Skeleton from '@mui/material/Skeleton'

export const FoodicsSettings = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'foodics_settings' })

  const dispatch = useDispatch()

  const currentProvider = useSelector(authStore.selectors.currentProvider)
  const foodicsUserInfo = useSelector(foodicsStore.selectors.foodicsUserInfo)
  const isFetching = useSelector(foodicsStore.selectors.isFetching)

  useEffect(() => {
    dispatch(foodicsStore.actions.fetchFoodicsUserInfo.started(undefined))
  }, [])

  return (
    <Grid item xs={12} sm={12}>
      <SectionTitle>{t('section_title')}</SectionTitle>

      {isFetching ? (
        <Skeleton sx={{ borderRadius: 1 }} variant='rectangular' height={82} />
      ) : foodicsUserInfo ? (
        <FoodicsUserCard foodicsUserInfo={foodicsUserInfo} />
      ) : (
        <FoodicsNotConnected currentProvider={currentProvider} />
      )}
    </Grid>
  )
}
