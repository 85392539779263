import { PromotionsResponse, Promotion, PromotionId } from 'src/models/promotions'
import { ProviderLocationId } from 'src/models/providerLocations'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action } from 'typescript-fsa'
import { UpdateStatusPayload } from './types'
import { fetch, fetchAll, remove, updateStatus } from './actions'
import { API } from 'src/data'
import { selectProvider } from '../auth/actions'

export default function createRootSaga() {
  function* fetchPromotionsSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      if (payload !== 'all') {
        const result: PromotionsResponse = yield call([API, API.readPromotions], payload)
        yield put(fetch.done({ params: payload, result }))
      } else {
        const result: PromotionsResponse = yield call([API, API.readAllPromotions])
        yield put(fetchAll(result))
      }
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ params: payload, error }))
    }
  }

  function* removeLocationSaga({
    payload
  }: Action<{ locationId: ProviderLocationId; promotionId: PromotionId }>): SagaIterator {
    try {
      // const locationId = yield select(selectedLocationId)
      yield call([API, API.deletePromotion], payload.locationId, payload.promotionId)
      yield put(remove.done({ params: payload }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ error, params: payload }))
    }
  }

  function* updateStatusSaga({ payload }: Action<UpdateStatusPayload>): SagaIterator {
    try {
      const { locationId, id, status } = payload

      const result: Promotion = yield call(
        //@ts-ignore
        [API, API.updatePromotion],
        {
          status
        },
        locationId,
        id
      )
      yield put(updateStatus.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(updateStatus.failed({ error, params: payload }))
    }
  }

  return [
    takeLatest(fetch.started, fetchPromotionsSaga),
    takeLatest(selectProvider, action =>
      fetchPromotionsSaga({ payload: 'all', type: action.type })
    ),
    takeLatest(remove.started, removeLocationSaga),
    takeLatest(updateStatus.started, updateStatusSaga)
  ]
}
