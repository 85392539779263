import React, { useEffect, useState } from 'react'
import { Container, Box, Card, CircularProgress, CardMedia, Link } from '@mui/material'
import { useParams } from 'react-router-dom'
import store from 'src/store/adminPreview'
import { useDispatch, useSelector } from 'react-redux'
import LocationOnProviderLocationViewIcon from './ProviderLocationView'

const App: React.FC = () => {
  const { id } = useParams()
  const dispatch = useDispatch()

  const data = useSelector(store.selectors.location)!
  useEffect(() => {
    dispatch(store.actions.fetchLocation.started(id!))
  }, [])

  if (!data) {
    return (
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        minHeight='100vh' // Adjust the height as needed
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <LocationOnProviderLocationViewIcon data={data} />
    </div>
  )
}

export default App
