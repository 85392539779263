import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { PromotionStatus } from 'src/models/promotions'
import Dialog from './SelectStatusDialog'
import providerLocations from 'src/store/providerLocations'
import { StatusInline, StatusGroup } from './StatusView'
import { ProviderLocation } from 'src/models/providerLocations'
import { LocationDraftUpdate } from 'src/store/createLocation/types'
import { useTranslation } from 'react-i18next'

type Type = 'list' | 'form'

interface Props {
  location: ProviderLocation | LocationDraftUpdate
  type?: Type
}

export default function ProviderLocationStatus({ location, type }: Props) {
  const { id, status } = location

  const { t } = useTranslation('translation', { keyPrefix: 'statuses' })

  const [modalOpen, setModalOpen] = useState(false)

  const dispatch = useDispatch()

  const onClick = () => setModalOpen(true)
  const onClose = () => setModalOpen(false)
  const onConfirm = (status: PromotionStatus) => {
    setModalOpen(false)
    dispatch(providerLocations.actions.updateStatus.started({ id: id!, status }))
  }

  return (
    <>
      <Dialog currentStatus={status!} open={modalOpen} onConfirm={onConfirm} onClose={onClose} />
      {type === 'list' ? (
        <StatusInline status={status!} onClick={onClick} />
      ) : (
        <StatusGroup
          status={status!}
          onClick={onClick}
          description={
            status === 'live' || status === 'declined'
              ? t('warning_for_live_or_declined_statuses_for_location')
              : ''
          }
        />
      )}
    </>
  )
}
