import React from 'react'
import { Promotion, PromotionType } from 'src/models/promotions'
import { Typography, Card, Grid } from '@mui/material'
import { PromotionDraftUpdate } from 'src/store/createPromotion/types'

const DiscountLabel = ({ data }: { data: Promotion | PromotionDraftUpdate }) => {
  const { discountPercentage, fixedDiscount, currency, type } = data

  const discount =
    type === PromotionType.percentageDiscount
      ? `${discountPercentage}% Discount`
      : `${fixedDiscount} ${currency || ''}`

  return (
    <Typography variant='h1' fontWeight={700} color='#EA4235'>
      {discount}
    </Typography>
  )
}

export default React.memo(DiscountLabel)
