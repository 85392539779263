import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import {
  updateSignInData,
  authChange,
  signIn,
  sendPhoneVerification,
  sessionRefresh,
  updateEditProfileData,
  saveProfile,
  dismissError,
  resetLimits,
  getCountriesOTPRequestMethodsList,
  allowedOTPMethodsChange,
  updateCurrentProvider,
  selectProvider,
  createProvider,
  setRedirectURL
} from './actions'

// Types imports
import { StateAuth, ActiveOperation } from './types'

const initialState: StateAuth = {
  session: null,
  user: null,
  providerAdmins: null,
  currentProvider: null,
  countriesOTPRequestMethodsList: null,
  allowedOTPMethods: null,
  editProfileData: {},
  signInData: {},
  error: undefined,
  activeOperation: undefined
}

export function auth(state: StateAuth = initialState, action: Action): StateAuth {
  if (isType(action, updateSignInData)) {
    return {
      ...state,
      signInData: {
        ...state.signInData,
        ...action.payload
      }
    }
  }

  if (isType(action, getCountriesOTPRequestMethodsList.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.getCountryOTPRequestMethodsList
    }
  }

  if (isType(action, getCountriesOTPRequestMethodsList.done)) {
    const { data } = action.payload.result

    return {
      ...state,
      activeOperation: undefined,
      countriesOTPRequestMethodsList: data
    }
  }

  if (isType(action, getCountriesOTPRequestMethodsList.failed)) {
    return {
      ...state,
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, sendPhoneVerification.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.sendPhoneVerification
    }
  }

  if (isType(action, sendPhoneVerification.done)) {
    const { id, nextPhoneVerificationRequestAvailableAt, sendLimitReached } = action.payload.result

    return {
      ...state,
      activeOperation: undefined,
      signInData:
        action.payload.params.operation === 'signIn'
          ? {
              ...state.signInData,
              phoneVerificationId: id,
              nextPhoneVerificationRequestAvailableAt,
              sendLimitReached
            }
          : state.signInData
    }
  }

  if (isType(action, sendPhoneVerification.failed)) {
    return {
      ...state,
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, signIn.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.signIn
    }
  }

  if (isType(action, signIn.done)) {
    return {
      ...state,
      signInData: {},
      activeOperation: undefined
    }
  }

  if (isType(action, signIn.failed)) {
    return {
      ...state,
      signInData: {
        ...state.signInData,
        otp: undefined
      },
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, allowedOTPMethodsChange)) {
    const methods = action.payload
    return {
      ...state,
      allowedOTPMethods: methods
    }
  }

  if (isType(action, authChange)) {
    const { session, user, providerAdmins, currentProvider } = action.payload
    return {
      ...state,
      session,
      user,
      providerAdmins,
      currentProvider
    }
  }

  if (isType(action, sessionRefresh)) {
    const { session, user, providerAdmins, currentProvider } = action.payload
    return {
      ...state,
      session,
      user,
      providerAdmins,
      currentProvider: currentProvider ? currentProvider : state.currentProvider
    }
  }

  if (isType(action, updateEditProfileData)) {
    return {
      ...state,
      editProfileData: {
        ...state.editProfileData,
        ...action.payload
      }
    }
  }

  if (isType(action, saveProfile.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.saveProfile
    }
  }

  if (isType(action, saveProfile.done)) {
    return {
      ...state,
      user: action.payload.result,
      activeOperation: undefined
    }
  }

  if (isType(action, saveProfile.failed)) {
    return {
      ...state,
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, resetLimits)) {
    return {
      ...state,
      error: undefined,
      signInData: {
        ...state.signInData,
        sendLimitReached: undefined,
        nextPhoneVerificationRequestAvailableAt: undefined
      }
    }
  }

  if (isType(action, updateCurrentProvider.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.updateCurrentProvider
    }
  }

  if (isType(action, updateCurrentProvider.done)) {
    return {
      ...state,
      currentProvider: action.payload.result.currentProvider,
      providerAdmins: action.payload.result.providerAdmins,
      activeOperation: undefined
    }
  }

  if (isType(action, updateCurrentProvider.failed)) {
    return {
      ...state,
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, createProvider.started)) {
    return {
      ...state,
      activeOperation: ActiveOperation.createProvider
    }
  }

  if (isType(action, createProvider.done)) {
    return {
      ...state,
      currentProvider: action.payload.result.currentProvider,
      activeOperation: undefined
    }
  }

  if (isType(action, createProvider.failed)) {
    return {
      ...state,
      error: action.payload.error,
      activeOperation: undefined
    }
  }

  if (isType(action, selectProvider)) {
    return {
      ...state,
      currentProvider: action.payload.provider
    }
  }

  if (isType(action, setRedirectURL)) {
    return {
      ...state,
      redirectURL: action.payload
    }
  }

  return state
}
