import React from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { SubscriptionCountry } from 'src/models/auth'

interface Props {
  value: SubscriptionCountry | undefined
  onChange: (value: SubscriptionCountry) => void
}

export default function ProviderCountry({ value, onChange }: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value as SubscriptionCountry)
  }

  return (
    <FormControl component='fieldset'>
      <Typography
        component='h6'
        align='left'
        color={'text.secondary'}
        sx={{
          fontWeight: 400,
          mb: 1
        }}
      >
        {'Select Country'}
      </Typography>
      <RadioGroup row aria-label='country' name='country' value={value} onChange={handleChange}>
        <FormControlLabel value='US' control={<Radio />} label='USA' />
        <FormControlLabel value='other' control={<Radio />} label='Other countries' />
      </RadioGroup>
    </FormControl>
  )
}
