import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import RoundedButton from '../../components/RoundedButton'
import LanguagePicker from 'src/components/LanguagePicker'
import authStore from 'src/store/auth'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Spinner from 'src/components/Spinner'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { ResendOTP, ResendOTPLink } from './ResendOTPLink'
import moment from 'moment'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { OTPMethods } from 'src/models/auth'

const OneTimePassword = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'otp' })

  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const [activeMessenger, setActiveMessenger] = useState<OTPMethods | undefined>()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const error = useSelector(authStore.selectors.error)
  const isSigningIn = useSelector(authStore.selectors.isSigningIn)
  const signInData = useSelector(authStore.selectors.signInData)
  const isSendingPhoneVerification = useSelector(authStore.selectors.isSendingPhoneVerification)
  const nextOTPRequestAvailableAt = useSelector(
    authStore.selectors.nextPhoneVerificationRequestAvailableAt
  )
  const isSendLimitReached = useSelector(authStore.selectors.isSendLimitReached)
  const allowedOTPMethods = useSelector(authStore.selectors.allowedOTPMethods)

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Recaptcha in not yet available')
      return
    }

    const token = await executeRecaptcha('resendOTP')

    setCaptchaToken(token)
  }, [executeRecaptcha])

  // When got captcha token request OTP
  useEffect(() => {
    if (!captchaToken) {
      return
    }

    resendOTP('sms_with_captcha')
  }, [captchaToken])

  useEffect(() => {
    if (
      !isSendingPhoneVerification &&
      !isSendLimitReached &&
      !isSigningIn &&
      !error &&
      !!signInData.phoneNumber
    ) {
      enqueueSnackbar(t('message_sent'), {
        variant: 'success'
      })
    }
  }, [isSendingPhoneVerification, isSigningIn, error])

  useEffect(() => {
    if (isSendLimitReached) {
      const date = moment(nextOTPRequestAvailableAt)
      const difference = moment(date).diff(moment())
      const duration = moment.duration(difference)

      // Show warning that we cannot send message, as we hit long time limit,
      // that's why we display hours and minutes left until next sms, instead of minutes and seconds
      enqueueSnackbar(
        t('otp_limit_reached') +
          (difference > 0
            ? t('try_again_in_long')
                .replace('%hours%', duration.hours().toString())
                .replace('%minutes%', duration.minutes().toString())
            : ''),
        {
          variant: 'warning'
        }
      )
    }
  }, [isSendLimitReached])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    closeSnackbar()
    dispatch(authStore.actions.signIn.started())
  }

  const handleResendOTP: ResendOTP = (event, messenger) => {
    if ((isSendingPhoneVerification || isSendLimitReached) && messenger !== 'whatsapp') {
      event.preventDefault()
      return
    }

    if (messenger === 'sms_with_captcha') {
      handleReCaptchaVerify()
      return
    }

    resendOTP(messenger)
  }

  const resendOTP = (messenger: OTPMethods) => {
    if (messenger !== 'whatsapp') {
      const timeToNextMessageAvailable = moment.duration(
        moment(nextOTPRequestAvailableAt).diff(moment())
      )

      // Show warning that we cannot send one more message now, as we hit time limit
      if (timeToNextMessageAvailable.as('seconds') > 0) {
        enqueueSnackbar(
          `${t('otp_limit_reached')}${t('try_again_in_short')
            .replace('%minutes%', timeToNextMessageAvailable.minutes().toString())
            .replace('%seconds%', timeToNextMessageAvailable.seconds().toString())}`,
          {
            variant: 'warning'
          }
        )

        return
      }
    }

    setActiveMessenger(messenger)

    dispatch(
      authStore.actions.sendPhoneVerification.started({
        operation: 'signIn',
        messenger,
        recaptcha: captchaToken
      })
    )
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(
      authStore.actions.updateSignInData({
        otp: e.currentTarget.value
      })
    )
  }

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error])

  useEffect(() => {
    if (!signInData.phoneNumber) {
      navigateBack()
    }
  }, [])

  const navigateBack = () => {
    navigate(-1)
  }

  return (
    <form onSubmit={onSubmit}>
      <div className='full-page full-page-gray-bg full-page-center'>
        <div style={{ position: 'absolute', right: 50, top: 50 }}>
          <LanguagePicker />
        </div>
        <Container
          component='main'
          maxWidth='xs'
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            flexDirection: 'column',
            padding: '32px',
            borderRadius: '16px',
            boxShadow: 3
          }}
        >
          <img src={logo} alt='React Logo' />
          <Grid container justifyContent='flex-start'>
            <Typography
              component='h6'
              align='left'
              color={'text.secondary'}
              sx={{
                fontWeight: 500,
                mt: 4,
                mb: 1
              }}
            >
              {t('otp_input_label')}
            </Typography>
            <OutlinedInput
              id='outlined-required'
              fullWidth
              autoFocus
              placeholder={t('otp_input_placeholder')}
              onChange={handleChange}
            />
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'column',
                mt: 3
              }}
            >
              {(allowedOTPMethods?.includes('sms') ||
                allowedOTPMethods?.includes('sms_with_captcha')) && (
                <ResendOTPLink
                  resendOTPHandler={handleResendOTP}
                  isSendingPhoneVerification={isSendingPhoneVerification}
                  activeMessenger={activeMessenger}
                  isSendLimitReached={isSendLimitReached}
                  linkText={t('btn_resend_sms')}
                  messenger={allowedOTPMethods?.includes('sms') ? 'sms' : 'sms_with_captcha'}
                />
              )}
              {allowedOTPMethods?.includes('whatsapp') && (
                <ResendOTPLink
                  resendOTPHandler={handleResendOTP}
                  isSendingPhoneVerification={isSendingPhoneVerification}
                  activeMessenger={activeMessenger}
                  linkText={t('btn_resend_whatsapp')}
                  messenger={'whatsapp'}
                />
              )}
            </Grid>
            <RoundedButton
              fullWidth
              sx={{ mt: 3, mb: 2 }}
              label={t('btn_confirm')}
              type='submit'
              disabled={!signInData.otp?.length}
            />
            <RoundedButton
              fullWidth
              label={t('btn_back')}
              variant='outlined'
              type='button'
              onClick={navigateBack}
            />
            <Spinner loading={isSigningIn} />
          </Grid>
        </Container>
      </div>
    </form>
  )
}
export default OneTimePassword
