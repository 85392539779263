import { CreateLocationAdminParams, RemoveLocationAdminParams } from './types'
import { ProviderLocationId } from 'src/models/providerLocations'
import { Action } from 'typescript-fsa'
import { SagaIterator } from 'redux-saga'

import { put, call, takeLatest, select, all } from 'redux-saga/effects'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { add, fetch, remove } from './actions'
import { drafts } from './selectors'
import { API } from 'src/data'

export function* createAdminsSaga(locationId: string): SagaIterator {
  const list: ProviderAdminCreate[] = yield select(drafts)
  yield all(list.map(admin => call(createAdminSaga, locationId, admin)))
}

function* createAdminSaga(locationId: string, data: ProviderAdminCreate): SagaIterator {
  try {
    const result = yield call([API, API.createLocationAdmin], locationId, data)
    return result
  } catch (error) {}
}

export default function createRootSaga() {
  function* fetchAdminsSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      const result = yield call([API, API.readLocationAdmins], payload)
      yield put(fetch.done({ params: payload, result: result.data }))
    } catch (error) {
      if (error instanceof Error) yield put(fetch.failed({ params: payload, error }))
    }
  }

  function* removeSaga({ payload }: Action<RemoveLocationAdminParams>): SagaIterator {
    try {
      const { locationId, admin } = payload
      if (!(admin as Admin).id && locationId) {
        return
      }
      const result = yield call([API, API.deleteLocationAdmin], locationId!, (admin as Admin).id)
      yield put(remove.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(remove.failed({ params: payload, error }))
    }
  }

  function* createAdminForExistingLocationSaga({
    payload
  }: Action<CreateLocationAdminParams>): SagaIterator {
    try {
      const { locationId, data } = payload
      const result = yield call([API, API.createLocationAdmin], locationId, data)
      yield put(add.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(add.failed({ params: payload, error }))
    }
  }

  return [
    takeLatest(fetch.started, fetchAdminsSaga),
    takeLatest(remove.started, removeSaga),
    takeLatest(add.started, createAdminForExistingLocationSaga)
  ]
}
