import './styles.css'
import { MediaUpload } from 'src/store/mediaUploads/types'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { File } from 'src/models/uploads'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'

interface Props {
  item: MediaUpload | File
  index: number
  onDelete: (item: MediaUpload | File) => void
  isOdd: boolean
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'inline-block'
  },
  overlay: {},
  iconContainer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  },
  iconButton: {},
  progress: {
    color: '#ffffff'
  },
  cancelIcon: {
    color: '#39a1ab'
  },
  image: {
    width: '100%',
    height: 200,
    objectFit: 'cover',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)'
    },
    filter: 'brightness(90%)' // Apply brightness filter to enhance visibility
  }
})

const Image = (props: Props) => {
  const { item, isOdd } = props
  const classes = useStyles()

  const fileURL = (item as File).url || URL.createObjectURL((item as MediaUpload).file)

  const onDelete = () => {
    props.onDelete(item)
  }

  return (
    <div className={`grid-item ${isOdd ? 'odd' : ''}`}>
      <div className={classes.container}>
        <img src={fileURL} alt={fileURL} className={classes.image} />
        {(item as MediaUpload).state === 'uploading' ? (
          <Box sx={{ width: '100%', padding: 0,  }}>
            <LinearProgress />
          </Box>
        ) : null}
        <div className={classes.iconContainer}>
          <IconButton
            sx={{
              backgroundColor: 'white',
              border: '1px solid #39a1ab',
              height: 28,
              width: 28,
              borderRadius: '50%'
            }}
            onClick={onDelete}
          >
            <CloseIcon className={classes.cancelIcon} fontSize={'small'} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

export default Image
