// Import statements
import { Box } from '@mui/material'
import GoogleMapReact from 'google-map-react'
import { LatLng } from 'src/models/providerLocations'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import React from 'react'

interface MapContainerProps {
  location: LatLng
}

// MapContainer component
const MapContainer: React.FC<MapContainerProps> = ({ location }) => {
  const defaultProps = {
    center: {
      lat: location.latitude,
      lng: location.longitude
    },
    zoom: 15
  }

  return (
    <Box sx={{ height: '300px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyBABOnefpq2-1RrMIM_CX7BpMZ0xu6tyHY',
          libraries: ['places', 'geometry']
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={location.latitude} lng={location.longitude} />
      </GoogleMapReact>
    </Box>
  )
}

// Marker component
const Marker: React.FC<{ lat: number; lng: number }> = () => (
  <LocationOnIcon color='primary' fontSize='large' />
)

export default React.memo(MapContainer)
