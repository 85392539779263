import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { ProvidersStats } from 'src/models/providerStats'
import formatDate from 'date-fns/format'
import { chartColors, chartData } from './constants'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface ChartData {
  data: ProvidersStats[]
}

const LineChart: React.FC<ChartData> = ({ data }) => {
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  }

  const datas = {
    labels: data.map(item => formatDate(new Date(item.date), 'dd MMM yy')),
    datasets: chartData.map(item => ({
      label: item.label,
      //@ts-ignore
      data: data.map(dataItem => dataItem[item.field]),
      fill: false,
      //@ts-ignore
      backgroundColor: chartColors[item.field],
      yValue: 0,
      //@ts-ignore
      borderColor: chartColors[item.field]
    }))
  }

  //@ts-ignore
  return <Line data={datas} options={options} />
}

export default LineChart
