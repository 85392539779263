import React from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import RoundedButton from 'src/components/RoundedButton'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import LanguagePicker from 'src/components/LanguagePicker'

function Welcome() {
  let navigate = useNavigate()
  const { t } = useTranslation('translation', { keyPrefix: 'welcome_page' })

  const navigateToLogin = () => {
    navigate('login')
  }

  return (
    <div className='full-page  full-page-center'>
      <div style={{ position: 'absolute', right: 50, top: 50 }}>
        <LanguagePicker />
      </div>

      <Container
        component='main'
        maxWidth='sm'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <img src={logo} alt='React Logo' />

        <Typography
          sx={{
            mt: 2,
            fontWeight: 700,
            fontSize: '32px',
            textAlign: 'center',
            textColor: '#293845'
          }}
        >
          {t('title')}
        </Typography>
        <Typography
          sx={{ mt: 2, fontWeight: 600, fontSize: '20px', textAlign: 'center' }}
          color={'primary'}
        >
          {t('subtitle')}
        </Typography>
        <RoundedButton
          sx={{ mt: 4, mb: 2, width: '60%', borderRadius: '9px' }}
          label={t('btn_register')}
          onClick={navigateToLogin}
        />
        <RoundedButton
          sx={{ mt: 3, mb: 2, width: '60%', borderRadius: '9px' }}
          label={t('btn_login')}
          variant='outlined'
          onClick={navigateToLogin}
        />
      </Container>
    </div>
  )
}
export default React.memo(Welcome)
