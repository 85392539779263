import React, { FC } from 'react'
import Grid from '@mui/material/Grid'
import CardActionArea from '@mui/material/CardActionArea'
import ButtonEdit from 'src/components/ButtonEdit'
import { useTranslation } from 'react-i18next'
import { Promotion, PromotionId } from 'src/models/promotions'
import { Link } from 'react-router-dom'
import { PromotionCard } from 'src/components/Promotion/PromotionCard'

interface PromotionItemProps {
  onEdit?: (id: PromotionId) => void
  link?: string
  promotion: Promotion
}

export const PromotionItem: FC<PromotionItemProps> = ({ onEdit, link, promotion }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'promotions_page' })

  const { id } = promotion

  const handleClickEdit = () => onEdit?.(id!)

  return (
    <Grid
      item
      xs={12}
      sm={10}
      md={5}
      lg={4}
      xl={3}
      sx={{
        minWidth: { xs: '200px', sm: '200px', md: '500px', lg: '500px', xl: '500px' }
      }}
    >
      <CardActionArea component={Link} to={link || `/promotions/preview/${id}`}>
        <PromotionCard promotion={promotion} displayStatus />
      </CardActionArea>
      {onEdit ? <ButtonEdit onClick={handleClickEdit} text={t('promotion_item_edit')} /> : null}
    </Grid>
  )
}
