import React from 'react'
// @mui
import { Box, Grid, Typography } from '@mui/material'
// components
import { SubscriptionPlan } from 'src/models/subscriptions'
import { useTranslation } from 'react-i18next'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'

type Props = {
  data: SubscriptionPlan
}

const Discount = ({ data }: Props) => {
  const { t } = useTranslation('translation')

  if (!data.discountPrice) {
    return null
  }
  return (
    <Box
      sx={{
        mt: 1,
        mb: 1,
        border: '2px dashed #D3D3D3', // Adjust the border width and color as needed
        p: 2,
        borderRadius: 4,
        width: '90%',
        backgroundColor: '#f2f0f0'
      }}
    >
      <Box display='flex' flexDirection='row' justifyContent={'space-between'}>
        <LocalOfferIcon fontSize='large' color='primary' />
        <Typography variant='h5'>- ${data.discountAmount}</Typography>
      </Box>
      <Typography mt={1} color={'text.secondary'}>
        {t('subscriptions.discount_label', { discount: data.percentOff })}
      </Typography>
    </Box>
  )
}
export default Discount
