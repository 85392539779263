import actionCreatorFactory from 'typescript-fsa'
import { ProviderAdminCreate, Admin } from 'src/models/admins'

// Actions
const actionCreator = actionCreatorFactory('admins')

export const fetch = actionCreator.async<void, Admin[], Error>('FETCH')
export const add = actionCreator.async<ProviderAdminCreate, Admin, Error>('ADD')
export const remove = actionCreator.async<Admin, void, Error>('REMOVE')
export const dismissError = actionCreator<void>('DISMISS_ERROR')
