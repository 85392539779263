import { Action } from 'redux'
import { isType } from 'typescript-fsa'
import { fetch, remove, dismissError, updateStatus } from './actions'
import { createLocation } from '../createLocation/actions'
import { logout, selectProvider } from '../auth/actions'

import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'

// Types imports
import { StateProviderLocations } from './types'

const initialState: StateProviderLocations = {
  cache: {}
}

export function providerLocations(
  state: StateProviderLocations = initialState,
  action: Action
): StateProviderLocations {
  if (isType(action, createLocation.done) || isType(action, updateStatus.done)) {
    const result = action.payload.result
    return {
      ...state,
      isUpdatingStatus: false,
      cache: {
        ...state.cache,
        [result.id!]: result
      }
    }
  }
  if (isType(action, fetch.started) || isType(action, updateStatus.done)) {
    return {
      ...state,
      isUpdatingStatus: true,
      isFetching: true,
      error: undefined
    }
  }
  if (isType(action, fetch.done)) {
    const data = keyBy(action.payload.result.data, 'id')
    return {
      ...state,
      cache: data,
      isFetching: false
    }
  }
  if (isType(action, fetch.failed) || isType(action, updateStatus.failed)) {
    return {
      ...state,
      isFetching: false,
      error: action.payload.error
    }
  }

  if (isType(action, remove.started)) {
    return {
      ...state,
      isRemoving: true,
      error: undefined
    }
  }
  if (isType(action, remove.done)) {
    return {
      ...state,
      isRemoving: false,
      cache: omitBy(state.cache, ({ id }) => id === action.payload.params)
    }
  }

  if (isType(action, remove.failed)) {
    return {
      ...state,
      isRemoving: false,
      error: action.payload.error
    }
  }

  if (isType(action, dismissError)) {
    return {
      ...state,
      error: undefined
    }
  }

  if (isType(action, logout) || isType(action, selectProvider)) {
    return initialState
  }

  return state
}
