import { AxiosInstance, AxiosResponse } from 'axios'
import AxiosClient from './AxiosClient'
import {
  PhoneVerification,
  PhoneVerificationUpdate,
  ProfileCreate,
  UserWithSession,
  SessionWithUser,
  UserUpdate,
  User,
  CountryOTPRequestMethodsList,
  OTPMethods,
  ProviderId,
  Provider
} from 'src/models/auth'
import {
  LocationCreate,
  ProviderLocationResponse,
  ProviderLocation,
  ProviderLocationId
} from '../../models/providerLocations'
import {
  AdminProviderInfo,
  ProviderInfosList,
  ProviderInfosListFilters,
  ProviderStatsFilters
} from 'src/models/providerInfo'
import { PromotionsResponse, Promotion, PromotionCreate, PromotionId } from 'src/models/promotions'
import { ProfileId } from './../../models/profile'
import { UploadType, Upload } from 'src/models/uploads'
import { ProviderAdminCreate, Admin } from 'src/models/admins'
import { IProviderLocationAPI } from './IAPI'
import userAgentParser from 'ua-parser-js'
import { ProviderCreate, ProviderUpdate } from 'src/store/auth/types'
import {
  SubscriptionCreate,
  ProviderSubscriptionUpdate,
  SubscriptionPlansResponse,
  ProviderSubscription,
  ProviderSubscriptionStatus
} from 'src/models/subscriptions'
import { PaginationParams } from 'src/models/common'
import { isEmpty } from 'lodash'
import { ProvidersStatsList } from 'src/models/providerStats'

export type SessionAuthentication = {
  token?: string
}
export default class APIClient implements IProviderLocationAPI {
  private axios: AxiosInstance
  private apiClient: AxiosClient
  private profileId: ProfileId | undefined
  private providerId: ProviderId | undefined

  constructor(baseUrl: string) {
    this.apiClient = new AxiosClient(baseUrl, { disableLogging: true })
    this.axios = this.apiClient.axios
  }

  setAuthToken(
    data: SessionAuthentication | undefined,
    profileId: ProfileId | undefined,
    providerId: ProviderId | undefined | null = null
  ) {
    this.apiClient.authentication = data
    this.profileId = profileId
    if (providerId !== null) {
      this.providerId = providerId
    }
  }

  _objectToQuery(obj: any) {
    // Start with an empty array to hold query parts
    let queryParts = []

    // Iterate over each key-value pair in the object
    for (const [key, value] of Object.entries(obj)) {
      value && queryParts.push(`${key}=${value}`)
    }

    // Join the parts with '&' to form the final query string
    return queryParts.join('&')
  }

  private _getInfoForSession() {
    return {
      deviceName: '', // DeviceInfo.getDeviceName(),
      deviceOS:
        userAgentParser(window.navigator.userAgent).os.name +
        '/' +
        userAgentParser(window.navigator.userAgent).os.version,
      deviceType: userAgentParser(window.navigator.userAgent).device.model,
      application: 'The Hub Promotions',
      applicationVersion: require('../../../package.json').version,
      authMethod: 'token'
    }
  }

  private _expectResponseType(response: AxiosResponse<any>, type: string) {
    const { data } = response
    if (data && data['_type'] && data['_type'] !== type) {
      console.warn(
        `Invalid response type "${data['_type']}", expected "${type}" (${response.config.method} ${response.config.url})`
      )
    }

    return data
  }

  async getCountryOTPRequestMethods(): Promise<CountryOTPRequestMethodsList> {
    const response = await this.axios.get(`/api/auth/country-otp-request-methods`)

    return this._expectResponseType(response, 'CountryOTPRequestMethodsList')
  }

  async sendPhoneVerification(
    phoneNumber: string,
    smsSignature?: string,
    language?: string,
    messenger?: OTPMethods,
    eventId?: string,
    recaptcha?: string
  ): Promise<PhoneVerification> {
    const response = await this.axios.post('/api/auth/phone-verification', {
      _type: 'PhoneVerificationCreate',
      phoneNumber,
      smsSignature,
      language,
      recaptcha,
      payload: {
        eventId,
        messenger
      }
    })

    return this._expectResponseType(response, 'PhoneVerification')
  }

  async register(
    phoneVerification: PhoneVerificationUpdate,
    profile: ProfileCreate,
    referralCode?: string
  ): Promise<UserWithSession> {
    const response = await this.axios.post('/api/auth/users', {
      _type: 'UserCreate',
      session: { ...this._getInfoForSession() },
      phoneVerification,
      profile,
      referralCode
    })

    return this._expectResponseType(response, 'UserWithSession')
  }

  async logIn(phoneVerification: PhoneVerificationUpdate): Promise<SessionWithUser> {
    const response = await this.axios.post('/api/auth/sessions', {
      _type: 'SessionCreate',
      ...this._getInfoForSession(),
      phoneVerification
    })

    return this._expectResponseType(response, 'Session')
  }

  async refreshSession(sessionId: string): Promise<SessionWithUser> {
    const response = await this.axios.patch(`/api/auth/sessions/${sessionId}`, {
      applicationVersion: require('../../../package.json').version
    })
    return this._expectResponseType(response, 'Session')
  }

  async createUpload(
    uploadType: UploadType,
    contentType: string,
    fileExtension: string
  ): Promise<Upload> {
    const response = await this.axios.post(`/api/uploads/${uploadType}`, {
      _type: 'UploadCreate',
      contentType,
      fileExtension
    })

    return this._expectResponseType(response, 'Upload')
  }

  async createLocation(body: LocationCreate): Promise<ProviderLocation> {
    const response = await this.axios.post(`/api/profiles/${this.profileId}/provider-locations`, {
      _type: 'ProviderLocationCreate',
      ...body,
      ownerId: this.providerId
    })
    return this._expectResponseType(response, 'ProviderLocation')
  }

  async readLocations(): Promise<ProviderLocationResponse> {
    const response = await this.axios.get(`/api/profiles/${this.profileId}/provider-locations`)

    return this._expectResponseType(response, 'ProviderLocationsList')
  }

  // Only for root user
  async getLocationById(id: ProviderLocationId): Promise<ProviderLocation> {
    const response = await this.axios.get(`/api/provider-locations/${id}`)
    return this._expectResponseType(response, 'ProviderLocation')
  }

  async updateLocation(body: LocationCreate, id: ProviderLocationId): Promise<ProviderLocation> {
    const response = await this.axios.patch(
      `/api/profiles/${this.profileId}/provider-locations/${id}`,
      {
        ...body,
        _type: 'ProviderLocationUpdate'
      }
    )
    return this._expectResponseType(response, 'ProviderLocation')
  }

  async deleteLocation(id: ProviderLocationId): Promise<void> {
    await this.axios.delete(`/api/profiles/${this.profileId}/provider-locations/${id}`)
  }

  async readPromotions(locationId: ProviderLocationId): Promise<PromotionsResponse> {
    const response = await this.axios.get(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/promotions`
    )

    return this._expectResponseType(response, 'PromotionsList')
  }

  async readAllPromotions(): Promise<PromotionsResponse> {
    const response = await this.axios.get(`/api/profiles/${this.profileId}/promotions`)

    return this._expectResponseType(response, 'PromotionsList')
  }

  // Only for root user
  async getPromotionById(id: PromotionId): Promise<Promotion> {
    const response = await this.axios.get(`/api/provider-promotions/${id}`)

    return this._expectResponseType(response, 'ProviderPromotion')
  }

  async createPromotion(body: PromotionCreate, locationId: ProviderLocationId): Promise<Promotion> {
    const response = await this.axios.post(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/promotions`,
      {
        _type: 'PromotionCreate',
        ...body
      }
    )

    return this._expectResponseType(response, 'Promotion')
  }

  async updatePromotion(
    body: PromotionCreate,
    locationId: ProviderLocationId,
    promotionId: PromotionId
  ): Promise<Promotion> {
    const response = await this.axios.patch(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/promotions/${promotionId}`,
      {
        ...body,
        _type: 'PromotionUpdate'
      }
    )

    return this._expectResponseType(response, 'Promotion')
  }

  async deletePromotion(locationId: string, promotionId: string): Promise<void> {
    await this.axios.delete(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/promotions/${promotionId}`
    )
  }

  async updateUser(userId: string, params: UserUpdate): Promise<User> {
    const response = await this.axios.patch(`/api/auth/users/${userId}`, {
      ...params,
      _type: 'UserUpdate'
    })
    return this._expectResponseType(response, 'User')
  }

  async createAdmin(body: ProviderAdminCreate): Promise<Admin> {
    const response = await this.axios.post(`/api/providers/${this.providerId}/admins`, {
      ...body,
      _type: 'ProviderAdminCreate'
    })
    return this._expectResponseType(response, 'Admin')
  }

  async readAdmins(): Promise<Admin[]> {
    const response = await this.axios.get(`/api/providers/${this.providerId}/admins`)
    return this._expectResponseType(response, 'ProviderAdminsList')
  }

  async deleteAdmin(id: string): Promise<void> {
    await this.axios.delete(`/api/providers/${this.providerId}/admins/${id}`)
  }

  async updateAdmin(id: string, body: ProviderAdminCreate): Promise<Admin> {
    const response = await this.axios.post(`/api/providers/${this.providerId}/admins/${id}`, {
      ...body,
      _type: 'ProviderAdminUpdate'
    })
    return this._expectResponseType(response, 'Admin')
  }

  async createLocationAdmin(
    locationId: ProviderLocationId,
    body: ProviderAdminCreate
  ): Promise<Admin> {
    const response = await this.axios.post(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/admins`,
      {
        ...body,
        _type: 'ProviderLocationAdminCreate'
      }
    )
    return this._expectResponseType(response, 'Admin')
  }

  async readLocationAdmins(locationId: ProviderLocationId): Promise<Admin[]> {
    const response = await this.axios.get(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/admins`
    )
    return this._expectResponseType(response, 'ProviderAdminsList')
  }

  async deleteLocationAdmin(locationId: ProviderLocationId, id: string): Promise<void> {
    if (!locationId) return

    await this.axios.delete(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/admins/${id}`
    )
  }

  async updateLocationAdmin(
    locationId: ProviderLocationId,
    id: string,
    body: ProviderAdminCreate
  ): Promise<Admin> {
    const response = await this.axios.patch(
      `/api/profiles/${this.profileId}/provider-locations/${locationId}/admins/${id}`,
      {
        ...body,
        _type: 'ProviderAdminUpdate'
      }
    )
    return this._expectResponseType(response, 'Admin')
  }

  async updateProvider(id: string, body: ProviderUpdate): Promise<Provider> {
    const response = await this.axios.patch(`/api/providers/${id}`, {
      ...body,
      _type: 'ProviderUpdate'
    })
    return this._expectResponseType(response, 'Provider')
  }

  async createProvider(body: ProviderCreate): Promise<Provider> {
    const response = await this.axios.post(`/api/providers`, {
      ...body,
      _type: 'ProviderCreate',
      ownerId: this.profileId
    })
    return this._expectResponseType(response, 'Provider')
  }

  async sendFoodicsAuthCallback(code: string, state: string) {
    const response = await this.axios.post(`/api/integrations/foodics/auth/callback`, {
      code,
      state
    })
    return response
  }

  // TODO: Add response type
  async getFoodicsUser(): Promise<any> {
    const response = await this.axios.get(`/api/providers/${this.providerId}/foodics`)
    return this._expectResponseType(response, 'FDUser')
  }

  async readSubscriptionPlans(
    providerId: ProviderId,
    promoCode?: string
  ): Promise<SubscriptionPlansResponse> {
    const query = promoCode ? `?promoCode=${promoCode}` : ''
    const response = await this.axios.get(`/api/providers/${providerId}/subscriptionPlans${query}`)
    return this._expectResponseType(response, 'ProviderSubscriptionPlansList')
  }

  async readSubscriptionStatus(providerId: ProviderId): Promise<ProviderSubscriptionStatus> {
    const response = await this.axios.get(`api/providers/${providerId}/subscriptionStatus`)
    return this._expectResponseType(response, 'ProviderSubscriptionStatus')
  }

  async createSubscription(providerId: ProviderId, body: SubscriptionCreate): Promise<void> {
    const response = await this.axios.post(`/api/providers/${providerId}/subscriptions`, {
      _type: 'SubscriptionCreate',
      ...body
    })
    window.location.href = response.data.redirectUrl
  }

  async readProviderSubscriptions(providerId: ProviderId): Promise<void> {
    const response = await this.axios.get(
      `/api/providers/${providerId}/subscriptions?showOnlyActive=true`
    )
    return this._expectResponseType(response, 'ProviderSubscriptionsList')
  }

  async updateProviderSubscription(
    providerId: ProviderId,
    subscriptionId: string,
    body: ProviderSubscriptionUpdate
  ): Promise<ProviderSubscription> {
    const response = await this.axios.patch(
      `/api/providers/${providerId}/subscriptions/${subscriptionId}`,
      {
        _type: 'ProviderSubscriptionUpdate',
        ...body
      }
    )
    return this._expectResponseType(response, 'ProviderSubscription')
  }

  async adminReadLocation(locationId: ProviderLocationId): Promise<ProviderLocation> {
    const response = await this.axios.get(`/api/provider-locations/${locationId}`)
    return this._expectResponseType(response, 'ProviderLocation')
  }

  async adminReadPromotion(promotionId: PromotionId): Promise<Promotion> {
    const response = await this.axios.get(`/api/provider-promotions/${promotionId}`)
    return this._expectResponseType(response, 'Promotion')
  }

  async adminReadProviderInfo(providerId: ProviderId): Promise<AdminProviderInfo> {
    const response = await this.axios.get(`/api/providers/${providerId}/stats`)
    return this._expectResponseType(response, 'ProviderInfo')
  }

  async adminReadProvidersInfo(
    params: PaginationParams,
    filters: ProviderInfosListFilters
  ): Promise<ProviderInfosList> {
    let query = `?include=totalCount&offset=${params.offset}&limit=${params.pageSize}`
    if (params.filter) {
      query += `&filter=${params.filter}`
    }

    if (!isEmpty(filters)) {
      query += `&${this._objectToQuery(filters)}`
    }

    const response = await this.axios.get(`/api/providers/info${query}`)
    return this._expectResponseType(response, 'ProviderInfosList')
  }

  async adminReadProvidersStats(
    params: PaginationParams,
    filters: ProviderStatsFilters
  ): Promise<ProvidersStatsList> {
    let query = ''
    if (filters.type) {
      query += `&type=${filters.type}`
    }
    const response = await this.axios.get(`/api/providers/stats/?include=totalCount${query}`)
    return this._expectResponseType(response, 'ProvidersStatsList')
  }
}
