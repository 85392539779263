import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import authStore from 'src/store/auth'
import RoundedButton from 'src/components/RoundedButton'
import { useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'
import { ProviderCountryPicker } from 'src/components'
import { SubscriptionCountry } from 'src/models/auth'

export default function CreateProvider() {
  const { t } = useTranslation('translation', { keyPrefix: 'provider_create' })

  const [providerName, setProviderName] = useState('')
  // const [providerCountry, setProviderCountry] = React.useState<SubscriptionCountry>('other')

  const [isCreateDisabled, setIsCreateDisabled] = useState(false)
  const [createProviderButtonClicked, setCreateProviderButtonClicked] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const currentProvider = useSelector(authStore.selectors.currentProvider)
  const providerAdmins = useSelector(authStore.selectors.providerAdmins)
  const isCreatingProvider = useSelector(authStore.selectors.isCreatingProvider)
  const error = useSelector(authStore.selectors.error)

  useEffect(() => {
    if (currentProvider && providerAdmins?.length) {
      navigate(`/providers/${currentProvider.id}?provider-created=true`)
    }
  }, [currentProvider, providerAdmins])

  useEffect(() => {
    if (error) {
      console.error(error)

      enqueueSnackbar(t('provider_create_error'), {
        variant: 'error',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createProviderButtonClicked && !isCreatingProvider && !error) {
      enqueueSnackbar(t('provider_created'), {
        variant: 'success',
        onExited: () => dispatch(authStore.actions.dismissError())
      })
    }
  }, [createProviderButtonClicked, isCreatingProvider, error])

  const handleChangeProviderName = (event: ChangeEvent<HTMLInputElement>) => {
    setProviderName(event.target.value)
  }

  useEffect(() => {
    if (providerName.length >= 3) {
      setIsCreateDisabled(false)
    } else {
      setIsCreateDisabled(true)
    }
  }, [providerName])

  const handleCreateProvider = () => {
    dispatch(
      authStore.actions.createProvider.started({
        provider: { name: providerName }
      })
    )

    setCreateProviderButtonClicked(true)
  }

  const renderTitle = (title: string) => (
    <Typography
      component='h6'
      align='left'
      color={'text.secondary'}
      sx={{
        fontWeight: 400,
        mb: 1
      }}
    >
      {title}
    </Typography>
  )

  const handleBack = () => {
    navigate(-1)
  }

  return (
    <div className='full-page full-page-gray-bg'>
      <Container
        component='main'
        maxWidth={false}
        sx={{ mt: 0, pt: { xs: 0, sm: 4 }, pb: 4, backgroundColor: '#f2f2f2', maxWidth: 700 }}
      >
        {!currentProvider && (
          <Alert severity='error' variant='outlined' sx={{ mb: -2 }}>
            {t('no_provider_alert')}
          </Alert>
        )}
        <Paper variant='outlined' sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
          <Typography
            color={'text.secondary'}
            fontWeight={'500'}
            component='h3'
            variant='h4'
            align='left'
          >
            {t('title')}
          </Typography>
          <Divider sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                {renderTitle(t('input_provider_name') + ' *')}
                <OutlinedInput
                  name='providerName'
                  required
                  value={providerName}
                  fullWidth
                  onChange={handleChangeProviderName}
                  id='providerName'
                />
              </Grid>
            </Grid>
            {/*
            <Grid item xs={12} sm={12}>
              <ProviderCountryPicker value={providerCountry} onChange={setProviderCountry} />
            </Grid>
              */}

            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
              <RoundedButton
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
                onClick={handleCreateProvider}
                label={t('btn_create')}
                disabled={isCreateDisabled || isCreatingProvider}
              />
              {currentProvider && (
                <RoundedButton
                  type='submit'
                  fullWidth
                  variant='outlined'
                  sx={{ mb: 2 }}
                  onClick={handleBack}
                  label={t('btn_cancel')}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}
