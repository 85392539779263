import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Container,
  Pagination,
  CircularProgress,
  Card,
  Grid,
  CardContent,
  Box,
  IconButton,
  List,
  Typography
} from '@mui/material'
import store from 'src/store/adminPreview'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ListItem from './ListItem'
import Filter from './Filter'
import { TotalCountDisplay, ListEmpty } from './UIComponents'

import SearchBar from './SearchBar'
import { ProviderId } from 'src/models/auth'
import { debounce } from 'lodash'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const ItemPerPage = 10

const App: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)

  const providerInfosList = useSelector(store.selectors.providerInfosList)!
  useEffect(() => {
    dispatch(store.actions.fetchProvidersInfo.started())
  }, [])

  const pageCount = Math.ceil(providerInfosList?.totalCount / ItemPerPage)

  const handleChangePage = (event: React.ChangeEvent<any>, value: number): void => {
    setPage(value)

    dispatch(
      store.actions.fetchProvidersInfo.started({
        pageSize: ItemPerPage,
        offset: (value - 1) * ItemPerPage
      })
    )
  }

  const onItemPress = (id: ProviderId) => () => navigate(`/admin/provider-stats/${id}`)

  const handleSearchChange = (value: string) => setDebouncedSearch(value)

  const debounceSearch = debounce((value: string) => {
    setPage(1)
    dispatch(
      store.actions.fetchProvidersInfo.started({
        pageSize: ItemPerPage,
        offset: 0,
        filter: value
      })
    )
  }, 500) // 500 ms debounce time

  const setDebouncedSearch = (value: string) => {
    debounceSearch(value)
  }

  // Clean up the debounce function
  useEffect(() => {
    return () => {
      debounceSearch.cancel()
    }
  }, [])

  const renderList = () => {
    return providerInfosList.data.length > 0 ? (
      <List>
        {providerInfosList.data.map(provider => (
          <ListItem
            key={provider.provider.id}
            data={provider}
            onPress={onItemPress(provider.provider.id)}
          />
        ))}
      </List>
    ) : (
      <ListEmpty />
    )
  }

  const handleIconClick = () => {
    navigate(`/admin/providers-stats`)
  }

  const onBackClick = () => navigate(-1)

  return (
    <div className='full-page full-page-gray-bg'>
      <Grid container spacing={1}>
        <Container sx={{ mt: 3, mb: 5 }} component='main' maxWidth='lg'>
          <Card>
            <CardContent>
              <Box display='flex' mt={2} mb={3} justifyContent='space-between'>
                <IconButton onClick={onBackClick} aria-label='back'>
                  <ArrowBackIcon />
                </IconButton>

                <Typography ml={5} variant='h5' sx={{ fontWeight: '500' }}>
                  Providers list
                </Typography>
                <IconButton onClick={handleIconClick}>
                  <QueryStatsIcon color='primary' fontSize='large' />
                </IconButton>
              </Box>

              <SearchBar onChange={handleSearchChange} />
              <Box marginTop={2} marginBottom={2} display='flex' justifyContent='space-between'>
                <TotalCountDisplay totalCount={providerInfosList?.totalCount} />
                <Filter />
              </Box>

              {!providerInfosList ? (
                <Box
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  minHeight='100vh' // Adjust the height as needed
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>{renderList()}</>
              )}
            </CardContent>
            <Box display='flex' justifyContent='center' my={2}>
              <Pagination count={pageCount} page={page} onChange={handleChangePage} />
            </Box>
          </Card>
        </Container>
      </Grid>
    </div>
  )
}

export default App
