import { ProviderLocationId, ProviderLocation } from './../../models/providerLocations'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { Action } from 'typescript-fsa'
import { enqueueSnackbar } from 'notistack'

import {
  fetchLocation,
  fetchPromotion,
  fetchProvidersInfo,
  fetchProviderInfo,
  fetchProvidersStats
} from './actions'
import { providerInfosListFilter, providerStatsFilters } from './selectors'
import { API } from 'src/data'
import { Promotion, PromotionId } from 'src/models/promotions'
import { AdminProviderInfo, ProviderInfosList } from 'src/models/providerInfo'
import { PaginationParams } from 'src/models/common'
import { ProvidersStatsList } from 'src/models/providerStats'

export default function createRootSaga() {
  function* fetchLocationSaga({ payload }: Action<ProviderLocationId>): SagaIterator {
    try {
      const result: ProviderLocation = yield call([API, API.adminReadLocation], payload)
      yield put(fetchLocation.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchLocation.failed({ params: payload, error }))
      enqueueSnackbar?.((error as Error).message, {
        variant: 'error'
      })
    }
  }

  function* fetchPromotionSaga({ payload }: Action<PromotionId>): SagaIterator {
    try {
      const result: Promotion = yield call([API, API.adminReadPromotion], payload)
      yield put(fetchPromotion.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchPromotion.failed({ params: payload, error }))
      enqueueSnackbar?.((error as Error).message, {
        variant: 'error'
      })
    }
  }

  function* fetchProviderInfoSaga({ payload }: Action<PromotionId>): SagaIterator {
    try {
      const result: AdminProviderInfo = yield call([API, API.adminReadProviderInfo], payload)
      yield put(fetchProviderInfo.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchProviderInfo.failed({ params: payload, error }))
      enqueueSnackbar?.((error as Error).message, {
        variant: 'error'
      })
    }
  }

  function* fetchProvidersInfoSaga({ payload }: Action<PaginationParams>): SagaIterator {
    try {
      const filters = yield select(providerInfosListFilter)
      const result: ProviderInfosList = yield call(
        [API, API.adminReadProvidersInfo],
        {
          offset: payload?.offset || 0,
          pageSize: payload?.pageSize || 10,
          filter: payload?.filter
        },
        filters
      )
      yield put(fetchProvidersInfo.done({ result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchProvidersInfo.failed({ error }))
      enqueueSnackbar?.((error as Error).message, {
        variant: 'error'
      })
    }
  }

  function* fetchProvidersStatsSaga({ payload }: Action<PaginationParams>): SagaIterator {
    try {
      const filters = yield select(providerStatsFilters)

      const result: ProvidersStatsList = yield call(
        [API, API.adminReadProvidersStats],
        {
          offset: payload?.offset || 0,
          pageSize: payload?.pageSize || 10,
          filter: payload?.filter
        },
        filters
      )
      yield put(fetchProvidersStats.done({ params: payload, result }))
    } catch (error) {
      if (error instanceof Error) yield put(fetchProvidersStats.failed({ error }))
      enqueueSnackbar?.((error as Error).message, {
        variant: 'error'
      })
    }
  }

  return [
    takeLatest(fetchLocation.started, fetchLocationSaga),
    takeLatest(fetchPromotion.started, fetchPromotionSaga),
    takeLatest(fetchProviderInfo.started, fetchProviderInfoSaga),
    takeLatest(fetchProvidersInfo.started, fetchProvidersInfoSaga),
    takeLatest(fetchProvidersStats.started, fetchProvidersStatsSaga)
  ]
}
