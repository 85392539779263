import React, { useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import ListItemButton from '@mui/material/ListItemButton'
import Typography from '@mui/material/Typography'
import { createStyles, makeStyles } from '@mui/styles'
import { OutlinedInput } from '@mui/material'
import currencies from './currencies.json'
import countryInfo from './countryInfo.json'

const useStyles = makeStyles((theme: any) =>
  createStyles({
    optionContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },
    optionDetails: {
      marginLeft: theme.spacing(2)
    }
  })
)

type Currency = {
  symbol: string
  name: string
  symbol_native: string
  decimal_digits: 2
  rounding: 0
  code: string
  name_plural: string
}

interface Props {
  currency: any
  onChange: (value: string) => void
}
const CurrencyPicker: React.FC<Props> = ({ currency, onChange }: Props) => {
  const classes = useStyles()
  //@ts-ignore
  const defaultCurrency = currencies[currency] || currencies['USD']
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(defaultCurrency)

  const filterOptions = (options: Currency[], { inputValue }: { inputValue: string }) => {
    return options.filter(
      currency =>
        currency.name?.toLowerCase?.()?.includes(inputValue?.toLowerCase()) ||
        currency.code?.toLowerCase?.()?.includes(inputValue?.toLowerCase())
    )
  }

  const onChangeCurrency = (e: any, newValue: Currency, huy: any) => {
    onChange(newValue.code)
  }

  const renderOption = (props: any, option: Currency) => {
    return (
      <ListItemButton
        key={option?.code}
        onClick={e => {
          props.onClick(e)
          onChange(option.code)
          setSelectedCurrency(option)
        }}
      >
        <div className={classes.optionContainer}>
          <Typography sx={{ fontWeight: 'bold' }} variant='body1'>{`${option?.code || ''} - ${
            option?.symbol || ''
          }`}</Typography>
          <div className={classes.optionDetails}>
            <Typography variant='body2'>{option?.name}</Typography>
          </div>
        </div>
      </ListItemButton>
    )
  }

  const renderInput = (params: any) => {
    return <TextField {...params} placeholder={'Select currency'} fullWidth type='outlite' />
  }

  return (
    <Autocomplete
      options={Object.values(currencies) as Currency[]}
      getOptionLabel={option => option.name}
      value={selectedCurrency!}
      disableClearable
      filterOptions={filterOptions}
      onChange={onChangeCurrency}
      renderInput={renderInput}
      renderOption={renderOption}
    />
  )
}

export default CurrencyPicker
