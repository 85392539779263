import actionCreatorFactory from 'typescript-fsa'
import { ProviderLocation, ProviderLocationId } from 'src/models/providerLocations'
import { Promotion, PromotionId } from 'src/models/promotions'
import { ProviderId } from 'src/models/auth'
import {
  AdminProviderInfo,
  ProviderInfosListFilters,
  ProviderInfosList,
  ProviderStatsFilters
} from 'src/models/providerInfo'
import { PaginationParams } from 'src/models/common'
import { ProvidersStats, ProvidersStatsList } from 'src/models/providerStats'

// Actions
const actionCreator = actionCreatorFactory('adminPreview')

export const fetchLocation = actionCreator.async<ProviderLocationId, ProviderLocation, Error>(
  'FETCH_LOCATION'
)
export const fetchPromotion = actionCreator.async<PromotionId, Promotion, Error>('FETCH_PROMOTION')

export const fetchProviderInfo = actionCreator.async<ProviderId, AdminProviderInfo, Error>(
  'FETCH_PROVIDER_INFO'
)

export const setProviderInfoListFilters = actionCreator<ProviderInfosListFilters>(
  'SET_PROVIDER_LIST_FITERS'
)

export const fetchProvidersInfo = actionCreator.async<
  PaginationParams | void,
  ProviderInfosList,
  Error
>('FETCH_PROVIDERS_INFO')

export const fetchProvidersStats = actionCreator.async<
  PaginationParams | void,
  ProvidersStatsList,
  Error
>('FETCH_PROVIDERS_STATS')

export const setProviderStatsFilter = actionCreator<ProviderStatsFilters>(
  'SET_PROVIDER_STATS_FITERS'
)

export const dismissError = actionCreator<void>('DISMISS_ERROR')
