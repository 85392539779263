import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'

import { PromotionItem } from './PromotionItem'
import Fab from '@mui/material/Fab'
import Button from '@mui/material/Button'

import AddIcon from '@mui/icons-material/Add'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import promotionsStore from 'src/store/promotions'
import providerLocationsStore from 'src/store/providerLocations'
import Grid from '@mui/material/Grid'
import { ProviderLocationId } from 'src/models/providerLocations'
import { Promotion } from 'src/models/promotions'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import OutlinedInput from '@mui/material/OutlinedInput'
import LinearProgress from 'src/components/LinearProgress'
import { useTranslation } from 'react-i18next'

const PromotionsPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation('translation', { keyPrefix: 'promotions_page' })

  const navigateToCreate = (id?: ProviderLocationId) =>
    navigate('/promotions/create', { state: { id } })

  const promotions = useSelector(promotionsStore.selectors.list)
  const selectedLocationId = useSelector(promotionsStore.selectors.selectedLocationId)

  const isFetching = useSelector(promotionsStore.selectors.isFetching)

  const providerLocations = useSelector(providerLocationsStore.selectors.list)

  useEffect(() => {
    if (providerLocations.length) {
      dispatch(promotionsStore.actions.fetch.started(selectedLocationId || 'all'))
    }
  }, [])

  const selectLocation = (e: any) => {
    dispatch(promotionsStore.actions.fetch.started(e.target.value))
  }
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 300,
        width: 250
      }
    }
  }

  const create = () => navigateToCreate()

  const navigateToCreateLocation = () => navigate('/locations/create')

  const allLocations = { id: 'all', name: t('select_all_locations') }

  const selectLocationList = providerLocations.length
    ? [allLocations, ...providerLocations.sort((a, b) => a.name!.localeCompare(b.name!))]
    : providerLocations

  const renderPlaceholder = (title: string, btnText: string, onPress: () => void) => (
    <Box mt={4} textAlign='center'>
      <Typography
        component='h6'
        align='center'
        mt={2}
        mb={2}
        color={'text.secondary'}
        sx={{
          fontWeight: 400
        }}
      >
        {title}
      </Typography>

      <Button
        type='submit'
        variant='contained'
        sx={{ mt: 3, mb: 2, alignContent: 'center' }}
        onClick={onPress}
      >
        {btnText}
      </Button>
    </Box>
  )

  return (
    <div className='full-page full-page-gray-bg'>
      <Box>
        {isFetching ? <LinearProgress /> : null}
        <Box
          sx={{
            pt: { xs: 1, sm: 2 },
            ml: { xs: 1, sm: 3, md: 5 },
            mr: { xs: 1, sm: 3, md: 5 },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box>
            {selectLocationList.length && selectedLocationId ? (
              <>
                <Typography
                  component='h6'
                  align='left'
                  mb={1}
                  color={'text.secondary'}
                  sx={{
                    fontWeight: 400
                  }}
                >
                  {t('select_location')}
                </Typography>
                <Select
                  defaultValue={selectedLocationId}
                  onChange={selectLocation}
                  input={<OutlinedInput sx={{ backgroundColor: 'white' }} fullWidth />}
                  MenuProps={MenuProps}
                >
                  {selectLocationList?.map(value => (
                    //@ts-ignore
                    <MenuItem key={value.id} value={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : null}
          </Box>

          {promotions?.length ? (
            <Box display={'flex'} alignItems={'center'} sx={{ cursor: 'pointer' }} onClick={create}>
              <Typography
                component='h6'
                mr={2}
                sx={{ display: { xs: 'none', sm: 'block' } }}
                color={'text.secondary'}
              >
                {t('btn_create')}
              </Typography>
              <Fab
                size='medium'
                disabled={!providerLocations?.length}
                onClick={create}
                color='primary'
                aria-label='add'
              >
                <AddIcon />
              </Fab>
            </Box>
          ) : null}
        </Box>

        <Grid
          justifyContent={'center'}
          container
          wrap='wrap'
          spacing={3}
          sx={{ p: { xs: 1, sm: 3 } }}
        >
          {promotions?.length
            ? promotions.map((item: Promotion) => (
                <PromotionItem key={item.id} promotion={item} onEdit={navigateToCreate} />
              ))
            : providerLocations?.length
            ? renderPlaceholder(t('list_placeholder'), t('list_placeholder_btn'), create)
            : renderPlaceholder(
                t('locations_empty'),
                t('locations_empty_btn'),
                navigateToCreateLocation
              )}
        </Grid>
      </Box>
    </div>
  )
}

export default PromotionsPage
